import { useCallback } from 'react'
import memoizeWeak, { Options } from 'memoizee/weak'
import { emptyObject } from 'utils/emptyObject'

export const useWeakMapCurriedCallback = <
  Fn extends (...args: unknown[]) => unknown,
>(
  callback: Fn,
  deps: unknown[],
  options?: Options<Fn>,
) => {
  return useCallback(
    memoizeWeak(
      (...curryArgs) => {
        return (...args) => {
          callback(...curryArgs, ...args)
        }
      },
      { length: false, ...(options || emptyObject) },
    ),
    deps,
  )
}
