import deepEqual from 'react-fast-compare'
import * as Sentry from '@sentry/browser'
import { produce } from 'immer'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/user'
import { GalaxyUserModel, InternalGalaxyUserData, Strategy } from 'redux/models'
import { selectGalaxyUser } from 'redux/selectors/selectGalaxyUser'
import { registerSaver } from 'redux/store/utils'
import { dispatch } from 'utils/dispatch'
import { getState } from 'utils/getState'
import { isClientPortal } from 'utils/isClientPortal'
import { REDUX_INITIAL_SET } from '../store/localStorage'
import { RootState } from './state'

const initialState: GalaxyUserModel = {
  data: undefined,
  localStorageLoaded: false,
}

if (!isClientPortal) {
  registerSaver(state => {
    const internalUser = selectGalaxyUser('internal')(state)

    return {
      GalaxyUser: {
        data: {
          selectedStrategy: internalUser?.selectedStrategy ?? null,
          strategies: internalUser?.strategies ?? null,
        },
      },
    }
  })
}

function isInternalUser(data: unknown): data is InternalGalaxyUserData {
  return !isClientPortal
}

export const GalaxyUserReducer = handleActions<
  GalaxyUserModel,
  GalaxyUserModel['data']
>(
  {
    [REDUX_INITIAL_SET]: (state: GalaxyUserModel, { payload }) => {
      return produce(state, draft => {
        draft.data ??= {} as GalaxyUserModel['data']
        if (!isInternalUser(draft.data) || !isInternalUser(state.data)) {
          return
        }
        const data = (payload as unknown as RootState)?.GalaxyUser
          ?.data as InternalGalaxyUserData
        if (data) {
          draft.data.selectedStrategy = data.selectedStrategy
          draft.data.strategies = data.strategies
        }
        draft.localStorageLoaded = true
      })
    },
    [Type.GALAXY_USER_CHANGED]: (state: GalaxyUserModel, { payload }) => {
      return produce(state, draft => {
        draft.data = payload
        Sentry.configureScope(scope => {
          scope.setUser({
            ...payload,
            id: ('id' in payload ? payload.id : payload.userId)?.toString(),
          })
        })

        if (
          !isInternalUser(draft.data) ||
          !isInternalUser(state.data) ||
          !isInternalUser(payload)
        ) {
          return
        }

        if (state.data) {
          draft.data.selectedStrategy = state.data.selectedStrategy
        }

        const currentStrategies = draft.data.strategies
        const newStrategies = payload.strategies
        draft.data.strategies = parseStrategyPayload(payload.strategies)

        if (!deepEqual(currentStrategies, draft.data.strategies)) {
          if (draft.data.selectedStrategy) {
            const selectedId = draft.data.selectedStrategy.id
            const newSelectedStrategy = newStrategies.find(
              ({ id }) => id === selectedId,
            )
            draft.data.selectedStrategy = newSelectedStrategy
          }

          draft.data.selectedStrategy ??= newStrategies[0]
        }

        dispatch({
          type: Type.SELECTED_STRATEGY_CHANGED_AFTER,
          payload: {},
        })
      })
    },
    [Type.SELECTED_STRATEGY_CHANGED]: (state: GalaxyUserModel, { payload }) => {
      return produce(state, draft => {
        if (
          !isInternalUser(draft.data) ||
          !isInternalUser(state.data) ||
          !isInternalUser(payload)
        ) {
          return
        }
        const prevSelectedStrategy = state.data?.selectedStrategy
        const newSelectedStrategy = payload.selectedStrategy
        // runs when user interacts with the strategy button to select one
        if (
          newSelectedStrategy !== undefined &&
          prevSelectedStrategy !== newSelectedStrategy
        ) {
          draft.data.selectedStrategy = newSelectedStrategy
        }
        dispatch({
          type: Type.SELECTED_STRATEGY_CHANGED_AFTER,
          payload: {},
        })
      })
    },
  },
  initialState,
)

/**
 * Parses the strategy payload to a tree-like structure by adding
 * - subStrategies to their parent
 * - parentId to the subStrategy
 *
 *  **NOTE**: This function mutates the content of the original array
 *
 * @param strategies original flat strategy array
 * @returns Tree-like structure of strategies
 */
function parseStrategyPayload(strategies: Strategy[]) {
  if (!strategies) {
    return []
  }

  const strategyHierarchy: Strategy[] = strategies.slice()

  for (let i = 0; i < strategyHierarchy.length; i++) {
    const strategy = strategyHierarchy[i]
    /**
     * Strategy comes with a special syntax string in `idHierarchy`.
     * The string comes in the following format: `XX.YY`.
     * Example
     * - idHierarchy: '17'    <- it's a parent strategy
     * - idHierarchy: '17.24' <- it's a subStrategy
     * The parent id is always the first one
     */
    const hierarchyIds = strategy.idHierarchy.split('.')

    if (hierarchyIds.length > 1) {
      const [parentId] = hierarchyIds.map(id => parseInt(id))
      const strategyParent = strategyHierarchy.find(({ id }) => id === parentId)

      if (strategyParent) {
        strategyParent.subStrategies ??= []
        strategyParent.subStrategies.push(
          // Extends the subStrategy with the parentId.
          // Object.assign is used to keep the original object reference.
          Object.assign(strategy, { parentId: strategyParent.id }),
        )
      }
      /**
       * remove the already added subStrategies that are in the initial array
       * to evade duplicates
       */
      strategyHierarchy.splice(i, 1)
      // decrease loop index since we removed a record from the array while looping
      i--
    }
  }
  return strategyHierarchy
}

export function isStrategySubstrategy(
  strategyId: number,
  compareId: number | null = null,
) {
  let ret = false
  if (strategyId === compareId) {
    ret = true
  } else {
    const user = selectGalaxyUser('internal')(getState())
    if (user?.strategies) {
      const strat = user.strategies.find(
        strat => strat.subStrategies?.find(strat => strat.id === strategyId),
      )
      ret = compareId === null ? !!strat : strat && strat.id === compareId
    }
  }
  return ret
}

export function getStrategyParent(strategyId: number) {
  const user = selectGalaxyUser('internal')(getState())
  if (user && user.strategies) {
    const strat = user.strategies.find(
      strat =>
        strat.id === strategyId ||
        (strat.subStrategies &&
          strat.subStrategies.find(strat => strat.id === strategyId)),
    )
    if (strat) {
      return strat
    }
  }
}
