import { Type } from 'redux/actions/monitor'
import { getKeyedDispatchQueue } from 'utils/dispatch'
import emptyFunction from 'utils/emptyFunction'
import { get } from 'utils/http'

export const monitorURL = process.env.NEXT_PUBLIC_GOTC_MONITOR_SERVICE_URL || ''
const bufferedQueue = getKeyedDispatchQueue()

let isFetching = false
export function fetchMonitorStatus(
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  if (isFetching) {
    return
  }
  isFetching = true
  return get(
    monitorURL,
    'json',
    data => {
      isFetching = false
      bufferedQueue(
        0,
        {
          type: Type.SET_DATA,
          payload: data,
        },
        onSuccess,
      )
    },
    onError,
    false,
    true,
  )
}

export function monitorPost(data) {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })
  const request = new XMLHttpRequest()
  request.open('POST', monitorURL)
  request.send(formData)
}
