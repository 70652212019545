type AccumulatorFn = (...args: unknown[]) => void | string
type ScheduleFn<Args> = (release: (arg?: unknown) => void, args: Args) => void

export const batchedFunction = <
  AccFn extends AccumulatorFn,
  Values extends Parameters<AccFn>,
>(
  accumulator: AccFn | null,
  scheduleCB: ScheduleFn<Values>,
) => {
  const scheduledDict = {}
  const promisesDict = {}
  return (...values: Values) => {
    const key = accumulator?.(...values) || '<default>'
    function release(result) {
      if (!scheduledDict[key]) {
        return
      }
      const { res } = scheduledDict[key]
      res(result)
      delete scheduledDict[key]
    }
    if (!scheduledDict[key]) {
      promisesDict[key] = new Promise((res, rej) => {
        scheduledDict[key] = { res, rej }
      })
      scheduleCB(release, values)
    }
    return promisesDict[key]
  }
}
