import { produce } from 'immer'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/admin'
import { AdminModel } from 'redux/models'

const initialState: AdminModel = {
  users: [],
  userData: {},
  counterParties: [],
  counterPartyData: {},
  accounts: [],
  contacts: {},
  contactData: {},
  accountData: {},
}

export const AdminPanelReducer = handleActions<AdminModel, any>(
  {
    [Type.UPDATE_USERS]: (state: AdminModel, { payload }) => {
      return produce(state, draft => {
        const users = []
        const data = {}
        payload.forEach(user => {
          users.push(user.id)
          data[user.id] = user
        })
        draft.users = users
        draft.userData = data
      })
    },
    [Type.UPDATE_COUNTERPARTY]: (state: AdminModel, { payload }) => {
      return produce(state, draft => {
        Object.assign(draft.counterPartyData[payload.entity], {
          counterPartyCredit: payload.counterPartyCredit,
          liquidityTierId: payload.liquidityTierId,
          liquidityTierName: payload.liquidityTierName,
        })
      })
    },
    [Type.UPDATE_COUNTERPARTIES]: (state: AdminModel, { payload }) => {
      return produce(state, draft => {
        const counterParties = []
        const data = {}
        payload.forEach(cp => {
          counterParties.push(cp.id)
          data[cp.id] = cp
        })
        draft.counterParties = counterParties
        draft.counterPartyData = data
      })
    },
    [Type.SET_ACCOUNT_INFO]: (state: AdminModel, { payload }) => {
      return produce(state, (draft: AdminModel) => {
        const { id, data } = payload
        draft.accountData[id] = {
          ...data,
          accountAttributes: data.accountAccountAttributes,
        }
      })
    },
    [Type.UPDATE_ACCOUNTS]: (state: AdminModel, { payload }) => {
      return produce(state, draft => {
        draft.accounts = payload
      })
    },
    [Type.UPDATE_ACCOUNT]: (state: AdminModel, { payload }) => {
      return produce(state, draft => {
        const index = draft.accounts.findIndex(
          account => account.id === payload.id,
        )
        draft.accounts[index] = payload
      })
    },
    [Type.SET_COUNTERPARTY_CONTACTS]: (state: AdminModel, { payload }) => {
      return produce(state, draft => {
        const { data } = payload

        draft.contacts[payload.id] = data.map(contact => contact.id)
        data.forEach(contact => {
          draft.contactData[contact.id] = contact
        })
      })
    },
  },
  initialState,
)
