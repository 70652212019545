import { FC, PropsWithChildren, createContext, useContext } from 'react'
import {
  MaintenanceIntervals,
  useMaintenanceIntervals,
} from './useMaintenanceIntervals'

const MaintenanceContext = createContext<MaintenanceIntervals>(null)

type ContainerProviderProps = PropsWithChildren

export const MaintenanceProvider: FC<ContainerProviderProps> = ({
  children,
}) => {
  const maintenanceIntervals = useMaintenanceIntervals()
  return (
    <MaintenanceContext.Provider value={maintenanceIntervals}>
      {children}
    </MaintenanceContext.Provider>
  )
}

export function useMaintenance(): MaintenanceIntervals {
  return useContext(MaintenanceContext)
}
