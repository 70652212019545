import produce from 'immer'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/dailyBlotter'
import { Type as UserType } from 'redux/actions/user'
import { DailyBlotterModel } from 'redux/models'
import getSelectedStrategy from 'redux/selectors/getSelectedStrategy'
import { registerSaver } from 'redux/store/utils'
import { getState } from 'utils/getState'
import { REDUX_INITIAL_SET } from '../store/localStorage'
import { FILTERS_BY_STRATEGY } from './historicalBlotter'

const initialState: DailyBlotterModel = {
  filter: null,
  filterOptions: [],
  complianceView: false,
  operationsView: false,
  firmSideView: false,
  showCanceledOrders: false,
  showPendingNewOrders: false,
}

registerSaver(state => {
  const s = state.DailyBlotter
  return {
    DailyBlotter: {
      filter: s.filter,
      complianceView: s.complianceView,
      operationsView: s.operationsView,
      firmSideView: s.firmSideView,
      showCanceledOrders: s.showCanceledOrders,
      showPendingNewOrders: s.showPendingNewOrders,
    },
  }
})

export const DailyBlotterReducer = handleActions<DailyBlotterModel, any>(
  {
    [REDUX_INITIAL_SET]: (state, { payload }) => {
      return produce(state, draft => {
        const persistedState = payload.DailyBlotter || {}
        delete persistedState.filterOptions
        Object.assign(draft, persistedState)
      })
    },
    [Type.UPDATE_FIELD]: (state, { payload }) => {
      return produce(state, draft => {
        const { field, val } = payload
        draft[field] = val
      })
    },
    [UserType.SELECTED_STRATEGY_CHANGED_AFTER]: state => {
      const selectedStrategy = getSelectedStrategy(getState())
      if (!selectedStrategy) {
        return state
      }
      return produce(state, draft => {
        const filters = FILTERS_BY_STRATEGY[selectedStrategy.id]
        if (!filters || !filters.length) {
          draft.filter = null
          draft.filterOptions = []
          return
        }
        draft.filterOptions = filters
        draft.filter = filters[0].value
      })
    },
  },
  initialState,
)
