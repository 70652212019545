const getOktaOrgUrl = () => process.env.NEXT_PUBLIC_OKTA_ORG_URL ?? ''

const OKTA_DOMAIN = '.com'

export const getOktaUrl = () => {
  const oktaOrgUrl = getOktaOrgUrl()

  const pos = oktaOrgUrl.indexOf(OKTA_DOMAIN)

  if (pos <= 0) {
    return oktaOrgUrl
  }

  return oktaOrgUrl.substring(0, pos + OKTA_DOMAIN.length)
}
