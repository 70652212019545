import { Type } from 'redux/actions/admin'
import { getKeyedDispatchQueue } from 'utils/dispatch'
import emptyFunction from 'utils/emptyFunction'
import { create, delete_, get, update } from 'utils/http'

const baseURL = process.env.NEXT_PUBLIC_REFERENCE_SERVICE_BASE_URL as string
const batchedDispatch = getKeyedDispatchQueue()

export function fetchUsers(onSuccess = emptyFunction, onError = emptyFunction) {
  get(
    baseURL,
    'api/users/external',
    entities => {
      batchedDispatch('users', {
        type: Type.UPDATE_USERS,
        payload: entities,
      })
      onSuccess(entities)
    },
    onError,
  ).retry()
}

export function updateUser(
  user,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  update(baseURL, `api/users/${user.id}`, user, onSuccess, onError)
}

export function createUser(
  user,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  create(baseURL, 'api/users', user, onSuccess, onError)
}

export function deleteUser(
  user,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  delete_(baseURL, `api/users/${user.id}`, onSuccess, onError)
}
