import { createContext } from 'react'
import emptyFunction from './emptyFunction'

export interface NotificationMessage {
  header: string
  content: string
  type: string
}

interface GlobalContext {
  setShowMessage(show: boolean): void
  setMessage(message: NotificationMessage): void
}

export default createContext<GlobalContext>({
  setShowMessage: emptyFunction,
  setMessage: emptyFunction,
})
