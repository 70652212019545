import { useEffect, useRef } from 'react'
import { usePrevious } from 'hooks/usePrevious'
import { shallowEqual } from 'utils/shallowEqual'

export const useEffectNow = (cb, deps) => {
  const prevDeps = usePrevious(deps).current
  const retRef = useRef(null)
  if (!shallowEqual(prevDeps, deps)) {
    if (retRef.current instanceof Function) {
      retRef.current()
    }
    retRef.current = cb()
  }
  useEffect(() => {
    return () => {
      if (retRef.current instanceof Function) {
        retRef.current()
      }
      retRef.current = null
    }
  }, [])
}
