import Head from 'next/head'
import { getEnv } from 'utils/envUtils'
import { isClientPortal } from 'utils/isClientPortal'

const pageTitle = isClientPortal
  ? `Galaxy Trading`
  : `Galaxy Trading - ${getEnv()}`

export const GalaxyAppHead: React.FC = () => (
  <Head>
    <meta charSet="utf-8" />
    <title>{pageTitle}</title>
    <meta
      httpEquiv="Cache-Control"
      content="no-cache, no-store, must-revalidate"
    />
    <meta httpEquiv="Pragma" content="no-cache" />
    <meta httpEquiv="Expires" content="0" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
  </Head>
)
