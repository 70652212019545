export const STRATEGY_ID_GDT_ARB = 11
export const STRATEGY_ID_GDLP = 14
export const STRATEGY_ID_GDT_OTC = 17
export const STRATEGY_ID_GOTC = 24
export const STRATEGY_ID_MANUAL = 25

export const ASSET_STORE_TYPE_EXCHANGE = 'Exchange'
export const ASSET_STORE_TYPE_HOT_WALLET = 'Hot Wallet'
export const ASSET_STORE_TYPE_COLD_WALLET = 'Cold Wallet'
export const ASSET_STORE_TYPE_RISK = 'risk'
export const ASSET_STORE_TYPE_SETTLEMENT_WALLET = 'settled'
export const ASSET_STORE_TYPE_FIREBLOCKS_SETTLEMENT_WALLET =
  'settled-fireblocks'
export const ASSET_STORE_TYPE_BANK = 'bank'

export const GetStrategyToId = new Map<number, string>([
  [STRATEGY_ID_GDLP, 'GDLP'],
  [STRATEGY_ID_GDT_ARB, 'GDT Arb'],
  [STRATEGY_ID_GDT_OTC, 'GDT OTC'],
])
