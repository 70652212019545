import { saveAs } from 'file-saver'
import moment from 'moment'

export async function saveAsCSV(data, name) {
  data.forEach(row => {
    row.forEach((value, colIndex) => {
      if (value && !isNaN(value) && value.toString().length > 15) {
        row[colIndex] = `="${value}"`
      }
    })
  })
  const XLSX = (await import('xlsx')).default
  const wb = XLSX.utils.book_new()
  wb.SheetNames.push('Sheet 1')
  const ws = XLSX.utils.aoa_to_sheet(data)
  wb.Sheets['Sheet 1'] = ws
  const wbOut = XLSX.write(wb, { bookType: 'csv', type: 'binary' })
  saveAs(
    new Blob([s2ab(wbOut)], { type: 'application/octet-stream' }),
    (name || '') + moment().format('YYYY-MM-DD HH:mm:ss') + '.csv',
  )
}

function s2ab(s) {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
  return buf
}
