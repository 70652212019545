import { merge } from 'lodash'
import { Store } from 'redux'

import { RootState } from 'redux/reducers/state'
import { dispatch } from 'utils/dispatch'
import { isBrowser } from 'utils/isBrowser'
import { loadState, saveState } from 'utils/localStorage'
import onBeforeUnload from 'utils/onBeforeUnload'
import requestIdleCallback from 'utils/requestIdleCallback'
import { throttle } from 'utils/throttle'
import { getSavers } from './utils'

const REDUX_LOCAL_STORAGE_DATA_KEY = 'redux-local-data'

export const REDUX_INITIAL_SET = 'LOCALSTORAGESET'

export const bindToReduxStore = (store: Store<RootState>): void => {
  const _saveState = (): void => {
    const state = store.getState()
    const toSave = getSavers().map(saver => saver(state))
    const result: RootState = merge({}, ...toSave)
    saveState(REDUX_LOCAL_STORAGE_DATA_KEY, result)
  }

  const saveUnsubscribe = store.subscribe(() => {
    if (!store.getState().GalaxyUser.localStorageLoaded) {
      return
    }
    // After REDUX_INITIAL_SET
    saveUnsubscribe()
    onBeforeUnload(_saveState)
    store.subscribe(
      throttle(() => {
        requestIdleCallback(_saveState)
      }, 60000),
    )
  })

  const persistedState = isBrowser
    ? loadState(REDUX_LOCAL_STORAGE_DATA_KEY)
    : {}
  dispatch({
    type: REDUX_INITIAL_SET,
    payload: persistedState,
  })
}
