import { useRef } from 'react'
import { usePrevious } from 'hooks/usePrevious'
import { shallowEqual } from './../utils/shallowEqual'

export const useShallowCompareDep = (dep, maxDepth = 0) => {
  const current = useRef(0)
  if (!shallowCompare(dep, usePrevious(dep).current, maxDepth)) {
    current.current += 1
  }
  return current.current
}

export const shallowCompare = (obj1, obj2, levels) => {
  return shallowCompareInner(obj1, obj2, levels, 0)
}

const shallowCompareInner = (obj1, obj2, levels, depth) => {
  return shallowEqual(obj1, obj2, (a, b) => {
    if (a !== b) {
      if (levels > depth) {
        return shallowCompareInner(a, b, levels, depth + 1)
      }
    }
    return a === b
  })
}
