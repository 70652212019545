import {
  Attributes,
  ComponentType,
  FC,
  ReactElement,
  cloneElement,
  isValidElement,
  memo,
  useEffect,
  useState,
} from 'react'
import {
  AddBannerMessage,
  MessageHandler,
  RemoveBannerMessage,
} from '@galaxy-digital/message-bus'
import LendingBanner from '_pages/client/g1/lending/LendingBanner'
import ClientGalaxyNavigation from 'components/Galaxy/ClientGalaxyNavigation'
import { TestEnvironmentNotice } from 'components/TestEnvironmentNotice'
import { messageBus } from 'utils/microfrontends'
import { G1Banner } from '../G1Banner'
import BannerManager from './BannerManager'

type HandledMessages = AddBannerMessage | RemoveBannerMessage
const handledMessagesTypes: HandledMessages['type'][] = [
  'add-banner',
  'remove-banner',
]

const coreG1Banners: Array<ReactElement | ComponentType> = [
  LendingBanner,
  ClientGalaxyNavigation,
  TestEnvironmentNotice,
  G1Banner,
]

function renderBanner<P>(Banner: ReactElement<P> | ComponentType, i: number) {
  return isValidElement(Banner) ? (
    cloneElement(Banner, { key: i } as Partial<P> & Attributes)
  ) : (
    <Banner key={i} />
  )
}

const G1BannerManager: FC = () => {
  const [banners, setBanners] = useState(coreG1Banners)

  useEffect(() => {
    const onAddTopBanner: MessageHandler<AddBannerMessage> = message => {
      setBanners(g1Banners =>
        message.metaData?.position === 'before'
          ? [message.data.banner, ...g1Banners]
          : [...g1Banners, message.data.banner],
      )
    }

    const removeTopBanner: MessageHandler<RemoveBannerMessage> = message => {
      setBanners(g1Banners =>
        g1Banners.filter(b =>
          isValidElement(b)
            ? b.type !== message.data.banner
            : b !== message.data.banner,
        ),
      )
    }

    const handler: MessageHandler<HandledMessages> = message => {
      switch (message.type) {
        case 'add-banner':
          onAddTopBanner(message)
          break
        case 'remove-banner':
          removeTopBanner(message)
          break
      }
    }

    messageBus.on(handler, ...handledMessagesTypes)

    return () => {
      messageBus.off(handler, ...handledMessagesTypes)
    }
  }, [])

  return <BannerManager>{banners.map(renderBanner)}</BannerManager>
}

export default memo(G1BannerManager)
