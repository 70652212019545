import { z } from 'zod'

export enum TransferPurpose {
  Settlement = 'SETTLEMENT',
  ExternalMovement = 'EXTERNAL_MOVEMENT',
  InternalMovement = 'INTERNAL_MOVEMENT',
  Counterparty = 'COUNTERPARTY',
  ProfitCapture = 'PROFIT_CAPTURE',
  Unknown = 'UNKNOWN',
}

export const purposeLabel = {
  [TransferPurpose.Settlement]: 'Settlement',
  [TransferPurpose.Counterparty]: 'Counterparty',
  [TransferPurpose.ExternalMovement]: 'External Movement',
  [TransferPurpose.InternalMovement]: 'Internal Movement',
  [TransferPurpose.ProfitCapture]: 'Profit Capture',
  [TransferPurpose.Unknown]: 'Unknown',
} as const

export const status = [
  'PENDING',
  'PENDING_IN_REVIEW',
  'PROCESSED',
  'COMPLETE',
  'COMPLETE_IN_REVIEW',
  'VOID',
  'FAILED',
] as const

export const transactionStatusEnumZod = z.enum(status)
export const transactionStatusEnum = transactionStatusEnumZod.Enum

export const transactionStatusEnumLabel = {
  [transactionStatusEnum.PENDING]: 'Pending',
  [transactionStatusEnum.PENDING_IN_REVIEW]: 'Pending in Review',
  [transactionStatusEnum.COMPLETE]: 'Complete',
  [transactionStatusEnum.COMPLETE_IN_REVIEW]: 'Complete in Review',
  [transactionStatusEnum.PROCESSED]: 'Processed',
}

export const noAccountId = -1
