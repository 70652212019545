import { FC } from 'react'
import { SystemError } from 'components/SystemError/SystemError'

export type OktaLoginErrorProps = {
  error?: Error
  showReachToSupport?: boolean
}

export const OktaLoginError: FC<OktaLoginErrorProps> = ({
  error,
  showReachToSupport,
}) => (
  <SystemError
    error={error}
    title="Access Restricted"
    message="You have not been granted access to this application. You can either navigate back to the Okta homepage or log out of Okta completely."
    showReachToSupport={showReachToSupport}
  />
)
