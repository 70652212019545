import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { Observable, Subscriber } from 'rxjs'
import { _getOktenToken, withOktaToken } from 'utils/getOktaToken'

type Handler = <Sub extends Subscriber<unknown>>(
  sub: Sub,
  res: AxiosResponse,
) => void

const SUCCESS_HANDLER: Handler = (sub, res) => {
  if (res.status >= 200 && res.status < 300) {
    sub.next(res.data)
  }
  sub.complete()
}

const OUTER_SUCCESS_HANDLER: Handler = (sub, data) => {
  sub.next(data)
  sub.complete()
}

function createAxiosInstance(
  baseUrl: string,
  withAuth: boolean,
  withCredentials: boolean,
) {
  const instance = axios.create({
    baseURL: baseUrl,
    responseType: 'json',
    withCredentials: withCredentials,
    headers: {
      Accept: 'application/json, text/html',
      'Content-Type': withCredentials
        ? 'x-www-form-urlencoded'
        : 'application/json',
    },
  })

  if (withAuth && withAuth === true) {
    instance.interceptors.request.use(config => {
      if (!_getOktenToken()) {
        return config
      }
      config.headers.Authorization = `Bearer ${_getOktenToken()}`
      return config
    })
  }
  return instance
}

export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).isAxiosError
}

/**
 * Basic Data Service to fetch data from REST Api.
 */
export class DataService {
  private readonly withAuthoiration: boolean
  private readonly axiosInstance: AxiosInstance
  /**
   * Parameterized constructor
   * @param withAuthorization If accessToken need to be passed to service. Default is false.
   */
  constructor(
    baseUrl: string,
    withAuthorization = false,
    withCredentials = false,
  ) {
    this.withAuthoiration = withAuthorization
    this.axiosInstance = createAxiosInstance(
      baseUrl,
      withAuthorization,
      withCredentials,
    )
  }

  /**
   * Gets the data from REST api
   * @param queryString Full Url of the endpoint
   * @returns Observable of T.
   */
  public getData<T>(queryString: string): Observable<T> {
    const obs = new Observable<T>(sub => {
      if (this.withAuthoiration) {
        withOktaToken(() => {
          this.getDataImpl(queryString).subscribe(
            OUTER_SUCCESS_HANDLER.bind(this, sub),
            ex => sub.error(ex),
            () => sub.complete(),
          )
        })
      } else {
        this.getDataImpl(queryString).subscribe(
          OUTER_SUCCESS_HANDLER.bind(this, sub),
          ex => sub.error(ex),
          () => sub.complete(),
        )
      }
    })
    return obs
  }

  private getDataImpl<T>(queryString: string): Observable<T> {
    const obs = new Observable<T>(sub => {
      this.axiosInstance
        .get<T>(queryString)
        .then(SUCCESS_HANDLER.bind(this, sub))
        .catch(ex => sub.error(ex))
        .finally(() => sub.complete())
    })
    return obs
  }

  /**
   *  Create new record using POST.
   * @param queryString Url of the Service.
   */
  public create<T, R = unknown>(queryString: string, data: T): Observable<R> {
    const obs = new Observable<R>(sub => {
      if (this.withAuthoiration) {
        withOktaToken(() => {
          this.createImpl(queryString, data).subscribe(
            OUTER_SUCCESS_HANDLER.bind(this, sub),
            ex => sub.error(ex),
            () => sub.complete(),
          )
        })
      } else {
        this.createImpl(queryString, data).subscribe(
          OUTER_SUCCESS_HANDLER.bind(this, sub),
          ex => sub.error(ex),
          () => sub.complete(),
        )
      }
    })
    return obs
  }

  private createImpl<T, R>(queryString: string, data: T): Observable<R> {
    const obs = new Observable<R>(sub => {
      this.axiosInstance
        .post(queryString, data, {
          transformRequest: function (data) {
            return JSON.stringify(data)
          },
        })
        .then(SUCCESS_HANDLER.bind(this, sub))
        .catch(ex => sub.error(ex))
        .finally(() => sub.complete())
    })
    return obs
  }

  public update<T, R = unknown>(queryString: string, data: T): Observable<R> {
    const obs = new Observable<R>(sub => {
      if (this.withAuthoiration) {
        withOktaToken(() => {
          this.updateImpl(queryString, data).subscribe(
            OUTER_SUCCESS_HANDLER.bind(this, sub),
            ex => sub.error(ex),
            () => sub.complete(),
          )
        })
      } else {
        this.updateImpl(queryString, data).subscribe(
          OUTER_SUCCESS_HANDLER.bind(this, sub),
          ex => sub.error(ex),
          () => sub.complete(),
        )
      }
    })
    return obs
  }

  private updateImpl<T, R>(queryString: string, data: T): Observable<R> {
    const obs = new Observable<R>(sub => {
      this.axiosInstance
        .put(queryString, data, {
          transformRequest: function (data) {
            return JSON.stringify(data)
          },
        })
        .then(SUCCESS_HANDLER.bind(this, sub))
        .catch(ex => sub.error(ex))
        .finally(() => sub.complete())
    })
    return obs
  }

  /**
   * Deletes the record (HTTPDelete)
   * @param queryString query string
   */
  public delete<R>(queryString: string): Observable<R> {
    const obs = new Observable<R>(sub => {
      if (this.withAuthoiration) {
        withOktaToken(() => {
          this.deleteImpl(queryString).subscribe(
            OUTER_SUCCESS_HANDLER.bind(this, sub),
            ex => sub.error(ex),
            () => sub.complete(),
          )
        })
      } else {
        this.deleteImpl(queryString).subscribe(
          OUTER_SUCCESS_HANDLER.bind(this, sub),
          ex => sub.error(ex),
          () => sub.complete(),
        )
      }
    })
    return obs
  }

  private deleteImpl<R>(queryString: string): Observable<R> {
    const obs = new Observable<R>(sub => {
      this.axiosInstance
        .delete(queryString)
        .then(SUCCESS_HANDLER.bind(this, sub))
        .catch(ex => sub.error(ex))
        .finally(() => sub.complete())
    })
    return obs
  }
}

export async function authorizationRequest(
  baseUrl: string,
  path: string,
  accessToken: string,
) {
  const instance = axios.create({
    baseURL: baseUrl,
    responseType: 'json',
  })
  instance.defaults.headers['head'] = instance.defaults.headers['get'] =
    instance.defaults.headers['delete']
  instance.defaults.headers['post'] =
    instance.defaults.headers['patch'] =
    instance.defaults.headers['put'] =
      {
        'Content-Type': 'application/json',
      }

  instance.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${accessToken}`
    return config
  })

  const result = await instance.get(path)
  return result.data
}
