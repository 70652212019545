import React, { useRef } from 'react'
import { useDraggableItem } from './useDraggableItem'

type Props = {
  children: React.ReactNode
  controller: import('./useDraggableController').IDraggableController
  id: string
} & React.HTMLAttributes<HTMLDivElement>
const DraggableImpl = (props: Props) => {
  const { children, controller, id, ...htmlProps } = props
  const ref = useRef<HTMLDivElement>(null)
  const { dragging, onMouseDown, onMouseUp } = useDraggableItem(
    controller,
    id,
    ref,
  )
  return (
    <div
      {...htmlProps}
      ref={ref}
      className={`draggable${dragging ? ' dragging' : ''}`}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
    >
      {children}
    </div>
  )
}

export const Draggable = React.memo(DraggableImpl)
