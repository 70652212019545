import React, { ReactElement, useCallback, useMemo, useRef } from 'react'
import { produce } from 'immer'
import memoizee from 'memoizee'
import { usePrevious } from 'hooks/usePrevious'
import { useState } from 'hooks/useState'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { emptyObject } from 'utils/emptyObject'
import { getUnformattedNumber } from 'utils/getNumberFromInput'
import { sortAlphabetically } from 'utils/sortAlphabetically'
import type { ColumnConfig } from './useGridTable'

const DEFAULT_SORTING = () => 0

export enum SORTDIR {
  ASC = 0,
  DESC = 1,
  NONE = 2,
}

export type SortConfig = {
  sorts: Record<
    string,
    {
      sortAsc: Function
      getSortValue?: Function
    }
  >
  activeSorts: Record<
    string,
    {
      priority: number
      direction: SORTDIR
    }
  >
}

type Props<T> = {
  id: string
  config: SortConfig
  columnConfig: ColumnConfig<T>[]
  onConfigChange: (config: SortConfig) => void
  rowData: any[]
  getCellValue: any
  getCellValueFormatted: any
}

type Return = {
  getSortComponent: (colId: string) => ReactElement
  sortedRowData: any[]
  toggleSort: (colId: string) => void
}

export function useGridTableSorter<T>({
  config,
  rowData,
  getCellValue,
  columnConfig,
  onConfigChange,
}: Props<T>): Return {
  const { activeSorts, sorts } = config
  const onConfigChangeRef = useUpdatingRef(onConfigChange)
  const configRef = useUpdatingRef(config)
  const setSortDirectionStore = useRef({})

  const getSortComponent = useCallback(
    memoizee((colId: string) => {
      const config = configRef.current
      const sort = config.activeSorts[colId]
      const direction = sort ? sort.direction : SORTDIR.NONE
      return (
        <SortComponentController
          initialDirection={direction}
          colId={colId}
          setDirectionStore={setSortDirectionStore}
        />
      )
    }),
    [],
  )

  const columns = useMemo(() => {
    const cols = {}
    columnConfig.forEach(config => {
      // @ts-expect-error obsolete code
      cols[config.id] = config
    })
    return cols
  }, [columnConfig])

  const sortingFn = useMemo(() => {
    const keys = Object.keys(activeSorts).sort(
      (key1, key2) => activeSorts[key1].priority - activeSorts[key2].priority,
    )
    const fns = []
    keys.forEach(key => {
      if (!sorts[key]) {
        return
      }
      const sortArgs = activeSorts[key]
      const sortFn = sorts[key].sortAsc
      const sortingFn = getSortingFn(sortFn, sortArgs.direction)
      const col = columns[key]
      fns.push((row1, row2) => {
        let val1 = getCellValue(col, row1)
        let val2 = getCellValue(col, row2)
        if (col.getSortValue) {
          val1 = col.getSortValue({ row: row1, value: val1 })
          val2 = col.getSortValue({ row: row2, value: val2 })
        }
        return sortingFn(val1, val2)
      })
    })
    return (val1, val2) => {
      for (const fn of fns) {
        const ret = fn(val1, val2)
        if (ret) {
          return ret
        }
      }
      return 0
    }
  }, [activeSorts, sorts, getCellValue, columns])

  const sortedRowData = useMemo(() => {
    return rowData.slice(0).sort(sortingFn)
  }, [rowData, sortingFn])

  const toggleSort = useCallback((colId: string) => {
    const { sorts } = configRef.current
    if (!sorts[colId] || !sorts[colId].sortAsc) {
      return
    }
    const nextConfig = produce(configRef.current, draft => {
      const activeSort = draft.activeSorts[colId]
      if (activeSort) {
        if (activeSort.direction === SORTDIR.DESC) {
          activeSort.direction = SORTDIR.ASC
        } else if (activeSort.direction === SORTDIR.ASC) {
          delete draft.activeSorts[colId]
        } else {
          activeSort.direction = SORTDIR.DESC
        }
      } else {
        draft.activeSorts = {
          [colId]: {
            direction: SORTDIR.DESC,
            priority: 1,
          },
        }
      }
    })
    onConfigChangeRef.current(nextConfig)
  }, [])

  const prevSorts = usePrevious(activeSorts).current || emptyObject
  if (prevSorts !== activeSorts) {
    const allKeys = Array.from(
      new Set([...Object.keys(activeSorts), ...Object.keys(prevSorts)]),
    )
    allKeys.forEach(key => {
      if (setSortDirectionStore.current[key] === undefined) {
        return
      }
      if (prevSorts[key] === activeSorts[key]) {
        return
      }
      if (activeSorts[key]) {
        setSortDirectionStore.current[key](activeSorts[key].direction)
      } else {
        setSortDirectionStore.current[key](SORTDIR.NONE)
      }
    })
  }

  return {
    getSortComponent,
    toggleSort,
    sortedRowData,
  }
}

export function dateSorter(a: any, b: any) {
  return new Date(a).getTime() - new Date(b).getTime()
}
export const stringSorter = (a, b) => {
  return sortAlphabetically(
    (a == null ? '' : a).toString().toLowerCase(),
    (b == null ? '' : b).toString().toLowerCase(),
  )
}

export function numberSorter(a, b) {
  return getUnformattedNumber(a) - getUnformattedNumber(b)
}

function getSortingFn(fn: Function, dir) {
  if (dir === SORTDIR.ASC) {
    return fn
  }

  if (dir === SORTDIR.DESC) {
    return (a, b) => -fn(a, b)
  }

  return DEFAULT_SORTING
}

type ControllerProps = {
  colId: string
  initialDirection: SORTDIR
  setDirectionStore: any
}
const SortComponentController = React.memo(
  ({ colId, initialDirection, setDirectionStore }: ControllerProps) => {
    const [direction, setDirection] = useState(initialDirection)
    setDirectionStore.current[colId] = setDirection
    let className

    if (direction === SORTDIR.ASC) {
      className = 'sort-asc'
    } else if (direction === SORTDIR.DESC) {
      className = 'sort-desc'
    }

    return <span className={className} />
  },
)
