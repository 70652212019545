import { Lato } from 'next/font/google'
import Head from 'next/head'
import appFactory from 'App'
import G1BannerManager from 'components/Banner/BannerManager/G1BannerManager'
import GetBannerManager from 'components/Banner/BannerManager/GetBannerManager'
import 'AppGlobalStyles.scss'
import 'styles/client-theme.module.scss'
import { isG1 } from 'utils/envUtils'

const AppHeader = () =>
  isG1() ? (
    <G1BannerManager />
  ) : (
    // @TODO: Remove the static font loading hacks for GET portal Lato fonts: Ticket: UI-2235
    <>
      <Head>
        <link
          href="https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic&subset=latin"
          rel="stylesheet"
        />
      </Head>
      <GetBannerManager />
    </>
  )

const latoFont = Lato({
  weight: ['400', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
})

export default appFactory(<AppHeader />, latoFont.className)
