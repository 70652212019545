import { FC, Fragment, memo } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { Dropdown } from 'semantic-ui-react'
import { get } from 'Gatekeeper'
import { Type as UserAction } from 'redux/actions/user'
import { Strategy } from 'redux/models/Strategy'

import Styles from './styles.module.scss'

type Props = {
  activePage: string
  strategies: Strategy[]
  selectedStrategy?: Strategy
}

const getHandler = (dispatch: Dispatch, selectedStrategy: Strategy) => () => {
  dispatch({
    type: UserAction.SELECTED_STRATEGY_CHANGED,
    payload: { selectedStrategy },
  })
}

const StrategiesDropdown: FC<Props> = ({
  strategies,
  selectedStrategy,
  activePage,
}) => {
  const dispatch = useDispatch()
  const selectedId = selectedStrategy?.id
  const dropdownItems = strategies
    .filter(s => s?.applications?.length > 0)
    .map((strategy, index) => (
      <Fragment key={`strategy-${strategy.id}-${index}`}>
        <Dropdown.Item
          key={index}
          active={selectedId === strategy.id}
          onClick={getHandler(dispatch, strategy)}
        >
          {strategy.name}
        </Dropdown.Item>
        {strategy.subStrategies?.map((sub, subIndex) => (
          <Dropdown.Item
            key={`sub-${sub.id}${subIndex}`}
            className={Styles.subMenu}
            onClick={getHandler(dispatch, sub)}
            active={sub.id === selectedId}
          >
            {sub.name}
          </Dropdown.Item>
        ))}
      </Fragment>
    ))

  const text =
    get('enable_trading_flow_selector') && activePage === '/trading'
      ? 'Strategies'
      : selectedStrategy?.name || 'Loading strategies...'

  return (
    <Dropdown
      item
      scrolling
      loading={Boolean(!selectedStrategy?.name)}
      text={text}
    >
      <Dropdown.Menu className={Styles.menu}>{dropdownItems}</Dropdown.Menu>
    </Dropdown>
  )
}

const StrategiesDropdownMemo = memo(StrategiesDropdown)
export { StrategiesDropdownMemo as StrategiesDropdown }
