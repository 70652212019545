import { FC, ReactNode } from 'react'
import { GalaxyOneProvider, Typography } from '@g1/components'
import Image from 'next/image'

import Styles from './styles.module.css'

type Props = {
  image: ReactNode
  title: ReactNode
  message: ReactNode
  moreContent?: ReactNode
  actions?: ReactNode
}

const AuthScreen: FC<Props> = ({
  actions,
  moreContent,
  message,
  title,
  image,
}) => {
  return (
    <GalaxyOneProvider theme="dark">
      <div className={Styles.root}>
        <nav>
          <Image
            src="/assets/galaxy-logo.white.svg"
            alt="Galaxy Logo"
            width={140}
            height={40}
          />
        </nav>
        <div className={Styles.container}>
          <div className={Styles.card}>
            <div className={Styles.content}>
              {image}
              <div className={Styles.contentText}>
                <Typography className={Styles.title} variant="subtitle2">
                  {title}
                </Typography>
                <Typography className={Styles.message} variant="body2">
                  {message}
                </Typography>
                {moreContent}
              </div>
            </div>
            {actions && <div className={Styles.actions}>{actions}</div>}
          </div>
        </div>
      </div>
    </GalaxyOneProvider>
  )
}

export default AuthScreen
