import React from 'react'
import App, { AppType } from 'next/app'
import { GalaxyApp } from '@design-library/GalaxyApp'

function getGalaxyApp(
  appHeader: React.ReactElement,
  className?: string,
): AppType {
  const GalaxyAppWithHeader: AppType = customAppProps => (
    <GalaxyApp
      {...customAppProps}
      appHeader={appHeader}
      className={className}
    />
  )

  GalaxyAppWithHeader.getInitialProps = App.getInitialProps

  return GalaxyAppWithHeader
}

export default getGalaxyApp
