import { useCallback, useLayoutEffect, useRef } from 'react'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { batchedFunction } from 'utils/batchedFunction'
import { queryThenMutateDOM } from 'utils/queryThenMutateDOM'

type Options = {
  horizontal?: boolean
  vertical?: boolean
}

export const useSynchronizeScrollPosition = (
  htmlRefs: Array<HTMLDivElement | null>,
  { horizontal, vertical }: Options,
) => {
  const divRefs = useUpdatingRef(htmlRefs)
  const callbacks = useRef([])
  const activeScroller = useRef<HTMLElement>()
  const timeoutRef = useRef<NodeJS.Timeout>()
  const clearActiveScroller = useCallback(() => {
    activeScroller.current = null
  }, [])

  const currentEvent = useRef<Event>()
  const scrollLeft = useRef(0)
  const scrollTop = useRef(0)
  const onScroll = useCallback(
    batchedFunction(
      (ev: Event) => {
        currentEvent.current = ev
      },
      release => {
        queryThenMutateDOM(
          () => {
            const target = getScrollNode(currentEvent.current.target)
            if (!target) {
              return
            }
            if (horizontal) {
              scrollLeft.current = target.scrollLeft
            }
            if (vertical) {
              scrollTop.current = target.scrollTop
            }
          },
          () => {
            release()
            const div = currentEvent.current.target as HTMLElement
            if (
              !div ||
              (activeScroller.current && activeScroller.current !== div)
            ) {
              return
            }
            clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(clearActiveScroller, 500)
            if (!activeScroller.current) {
              activeScroller.current = div
            }
            if (vertical) {
              divRefs.current.forEach(otherDiv => {
                if (otherDiv !== div) {
                  otherDiv.scrollTop = scrollTop.current
                }
              })
            }
            if (horizontal) {
              divRefs.current.forEach(otherDiv => {
                if (otherDiv !== div) {
                  otherDiv.scrollLeft = div.scrollLeft
                }
              })
            }
          },
        )
      },
    ),
    [htmlRefs],
  )

  useLayoutEffect(() => {
    htmlRefs.forEach(div => {
      if (!div) {
        return
      }

      div.addEventListener('scroll', onScroll, { passive: true })
      callbacks.current.push({ div, onScroll })
    })
    return () => {
      callbacks.current.forEach(({ div, onScroll }) => {
        div.removeEventListener('scroll', onScroll, { passive: true })
      })
      callbacks.current = []
    }
  }, [htmlRefs, horizontal, vertical])
}

function getScrollNode(node) {
  return node === document ? document.scrollingElement : node
}
