import React, { FC } from 'react'
import { Skeleton } from '@mui/material'
import { isProd } from 'utils/envUtils'
import Styles from './styles.module.scss'

export const BannerPlaceholder: FC = () => (
  <section className={Styles.loader}>
    {!isProd() && (
      <article className={Styles.notice}>
        <Skeleton className={Styles.message} variant="rounded" />
      </article>
    )}
    <article className={Styles.appHeader}>
      <Skeleton className={Styles.logo} variant="rounded" />
      <Skeleton className={Styles.navItem} variant="text" />
      <Skeleton className={Styles.navItem} variant="text" />
      <Skeleton className={Styles.navItem} variant="text" />
      <Skeleton className={Styles.avatar} variant="circular" />
      <Skeleton className={Styles.userMenu} variant="text" />
    </article>
  </section>
)
