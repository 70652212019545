import type { OktaAuth } from '@okta/okta-auth-js'
import { Type as UserActions } from 'redux/actions/user'
import { dispatch } from './dispatch'
import { signOutFromAll } from './microfrontends'

let resolveAuth: (auth: OktaAuth) => void

const authPromise = new Promise<OktaAuth>(res => {
  resolveAuth = auth => {
    res(auth)
  }
})

export function __setOktaAuthService(auth: OktaAuth): void {
  resolveAuth(auth)
}

async function getOktaAuthService() {
  return await authPromise
}

export async function logout() {
  const auth = await getOktaAuthService()
  auth.signOut({ revokeAccessToken: true, revokeRefreshToken: true })
}

export async function login() {
  const auth = await getOktaAuthService()
  return await auth.signInWithRedirect({
    originalUri: window.location.pathname,
  })
}

export const singleLogout = async () => {
  dispatch({
    type: UserActions.USER_SIGNING_OUT,
    payload: true,
  })

  await signOutFromAll([
    `${process.env.NEXT_PUBLIC_MY_SETTINGS_URL}/implicit/logout`,
  ])

  await logout()
}
