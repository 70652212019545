import { MutableRefObject as Ref, useRef } from 'react'

export const usePrevious = <T>(value: T): Ref<T | undefined> => {
  const prevRef = useRef<T>()
  const currentRef = useRef<T>()

  prevRef.current = currentRef.current
  currentRef.current = value

  return prevRef
}
