import { useCallback, useState } from 'react'

type UseFlagToggle = [boolean, VoidFunction]
type UseFlagSwitches = [boolean, VoidFunction, VoidFunction, VoidFunction]
type UseBoolFlag = UseFlagSwitches | UseFlagToggle

export function useBoolFlag(initial: boolean): UseFlagSwitches
export function useBoolFlag(initial: boolean, toggleOnly: true): UseFlagToggle
export function useBoolFlag(
  initial: boolean,
  toggleOnly?: boolean,
): UseBoolFlag {
  const useFlagHook = toggleOnly ? useFlagToggle : useFlagSwitches

  return useFlagHook(initial)
}

function useFlagToggle(initial: boolean): UseFlagToggle {
  const [flag, setFlag] = useState(initial)
  const toggle = useCallback(() => setFlag(f => !f), [])
  return [flag, toggle]
}

function useFlagSwitches(initial: boolean): UseFlagSwitches {
  const [flag, setFlag] = useState(initial)
  const toggle = useCallback(() => setFlag(f => !f), [])
  const showFlag = useCallback(() => setFlag(true), [])
  const hideFlag = useCallback(() => setFlag(false), [])
  return [flag, showFlag, hideFlag, toggle]
}
