import { Typography } from '@g1/components'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Styles from './VestingDisclaimerFooter.module.scss'

const VestingDisclaimerFooter = () => {
  const router = useRouter()
  const { index, currency } = router.query

  if (!index || !currency) {
    return null
  }

  return (
    <div className={Styles.disclaimerFooter}>
      <Typography variant="metadata">
        <Link
          href={`/g1/accounts/${index}?vestingDisclaimer=1&currency=${currency}`}
        >
          {currency} Real-Time View Disclaimer
        </Link>
      </Typography>
    </div>
  )
}

export default VestingDisclaimerFooter
