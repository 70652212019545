import { rethrowError } from 'utils/rethrowError'

let originalRequestAnimationFrame
try {
  originalRequestAnimationFrame = window.requestAnimationFrame
} catch (e) {
  originalRequestAnimationFrame = (cb: VoidFunction) => setTimeout(cb, 16)
}

let queue: VoidFunction[] = []
let startQueue: VoidFunction[] = []
let endQueue: VoidFunction[] = []
let scheduledAnimationFrame = false

function requestAnimationFrameRunner() {
  scheduledAnimationFrame = false

  const currentStartQueue = startQueue
  startQueue = []

  while (currentStartQueue.length) {
    try {
      currentStartQueue.shift()()
    } catch (e) {
      rethrowError(e)
    }
  }

  const currentQueue = queue
  queue = []

  while (currentQueue.length) {
    try {
      currentQueue.shift()()
    } catch (e) {
      rethrowError(e)
    }
  }

  const currentEndQueue = endQueue
  endQueue = []

  while (currentEndQueue.length) {
    try {
      currentEndQueue.shift()()
    } catch (e) {
      rethrowError(e)
    }
  }
}

export const requestAnimationFrameStart = callback => {
  startQueue.push(callback)
  if (!scheduledAnimationFrame) {
    scheduledAnimationFrame = true
    originalRequestAnimationFrame(requestAnimationFrameRunner)
  }
  return () => {
    startQueue = startQueue.filter(cb => cb !== callback)
  }
}

export const requestAnimationFrame = callback => {
  queue.push(callback)
  if (!scheduledAnimationFrame) {
    scheduledAnimationFrame = true
    originalRequestAnimationFrame(requestAnimationFrameRunner)
  }
  return () => {
    queue = queue.filter(cb => cb !== callback)
  }
}

export const requestAnimationFrameEnd = (callback: VoidFunction) => {
  endQueue.push(callback)
  if (!scheduledAnimationFrame) {
    scheduledAnimationFrame = true
    originalRequestAnimationFrame(requestAnimationFrameRunner)
  }
  return () => {
    endQueue = endQueue.filter(cb => cb !== callback)
  }
}
