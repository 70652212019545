import emptyFunction from 'utils/emptyFunction'
import { create } from 'utils/http'

const referenceServiceBaseUrl =
  process.env.NEXT_PUBLIC_TRADE_SERVICE_BASE_URL || ''

interface UserPreferenceData {
  id: UserPreference
  enabled: boolean
}

export enum UserPreference {
  gotc = 1,
  settlement = 2,
  requestSettlement = 3,
}

export function updateUserPreference(
  data: UserPreferenceData,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return create(
    referenceServiceBaseUrl,
    `updateUserPreference`,
    data,
    onSuccess,
    onError,
  )
}
