import { OktaAuth } from '@okta/okta-auth-js'
import { isBrowser } from 'utils/isBrowser'

export interface OktaConfig {
  clientId?: string
  issuer?: string
  redirectUri?: string
  pkce?: boolean
}

let oktaAuth: OktaAuth
if (isBrowser) {
  const { origin, hostname, protocol } = window.location
  const secure =
    protocol === 'http:' && hostname !== 'localhost'
      ? origin.replace('http', 'https')
      : origin
  oktaAuth = new OktaAuth({
    clientId: process.env.NEXT_PUBLIC_OKTA_CLIENT_ID,
    issuer: process.env.NEXT_PUBLIC_OKTA_ORG_URL,
    redirectUri: secure + '/implicit/callback',
    pkce: false,
  })
}
export default oktaAuth
