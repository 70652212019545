import { useCallback, useEffect } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document } from '@contentful/rich-text-types'
import {
  Button,
  GalaxyOneProvider,
  LoadingIndicator,
  Modal,
  NotificationProvider,
  Typography,
  useNotification,
} from '@g1/components'
import dayjs from 'dayjs'
import { createRoot } from 'react-dom/client'
import { useHasBeenDisplayed } from 'hooks/useHasBeenDisplayed'
import { singleLogout } from 'utils/auth'
import { useGetLegalTerms } from './useGetLegalTerms'
import { useUpdateUserConsent } from './useUpdateUserConsent'

import Styles from './LegalTerms.module.scss'

export const LegalTerms = () => (
  <GalaxyOneProvider theme="dark">
    <NotificationProvider>
      <LegalTermsPage />
    </NotificationProvider>
  </GalaxyOneProvider>
)

const LegalTermsPage = () => {
  const [hasBeenDisplayed, elementRef] = useHasBeenDisplayed()
  const {
    isLoading,
    data: legalTermsData,
    isLoadingError: legalTermsError,
  } = useGetLegalTerms()
  const userConsentMutation = useUpdateUserConsent()
  const { enqueNotification } = useNotification()

  useEffect(() => {
    if (legalTermsError) {
      enqueNotification({
        message: 'System error occurred. Please try again later.',
        severity: 'error',
      })
    }
  }, [enqueNotification, legalTermsError])

  const refCallback = useCallback(
    (container: HTMLDivElement) => {
      if (container && legalTermsData?.content) {
        const richText = documentToReactComponents(
          legalTermsData.content as Document,
          {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (_, children) => (
                <Typography variant="body2" className={Styles.Paragraph}>
                  {children}
                </Typography>
              ),
              [BLOCKS.HEADING_1]: (_, children) => (
                <Typography variant="h1" className={Styles.Header1}>
                  {children}
                </Typography>
              ),
              [BLOCKS.HEADING_2]: (_, children) => (
                <Typography variant="h2" className={Styles.Header2}>
                  {children}
                </Typography>
              ),
              [BLOCKS.HEADING_3]: (_, children) => (
                <Typography variant="h3" className={Styles.Header3}>
                  {children}
                </Typography>
              ),
              [BLOCKS.HEADING_4]: (_, children) => (
                <Typography variant="h4" className={Styles.Header4}>
                  {children}
                </Typography>
              ),
            },
          },
        )
        const root = createRoot(container)
        root.render(
          <GalaxyOneProvider theme="dark">{richText}</GalaxyOneProvider>,
        )
      }
    },
    [legalTermsData?.content],
  )

  const onAccept = () => {
    userConsentMutation.mutate(
      {
        docLastUpdatedAt: legalTermsData.updatedAt,
        docVersionId: legalTermsData.revision.toString(),
        consented: true,
      },
      {
        onError: () => {
          enqueNotification({
            message: 'System error occurred. Please try again later.',
            severity: 'error',
          })
        },
      },
    )
  }

  return (
    <div className={Styles.LegalTermsContainer}>
      <Modal
        open
        onClose={() => ''}
        variant="standard"
        className={Styles.Modal}
      >
        <Modal.Header>User Agreement</Modal.Header>
        <Modal.Content>
          <div className={Styles.ModalContent}>
            <div className={Styles.Header}>
              <Typography variant="h4">{legalTermsData?.title}</Typography>
              <Typography variant="body2" className={Styles.Greyed}>
                Please review the following Terms of Service, Risk, and Privacy
                Policy to continue.
              </Typography>
            </div>
            <div className={Styles.Content}>
              <div className={Styles.ScrollableContent}>
                {isLoading ? (
                  <LoadingIndicator className={Styles.LoadingIndicator} />
                ) : (
                  <>
                    <Typography variant="subtitle1">
                      TERMS OF SERVICE
                    </Typography>
                    <Typography variant="body2" className={Styles.Greyed}>
                      Last updated{' '}
                      {legalTermsData?.updatedAt
                        ? dayjs(legalTermsData.updatedAt).format('MM/DD/YYYY')
                        : '--/--/----'}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      className={Styles.LegalTermsText}
                    >
                      <div ref={refCallback}></div>
                      <div className={Styles.ReadMarker} ref={elementRef} />
                    </Typography>
                  </>
                )}
              </div>
              <Typography variant="body2" className={Styles.SupportingText}>
                By clicking “Accept” you are confirming that you understand and
                accept the terms above.
              </Typography>
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className={Styles.Buttons}>
            <Button variant="secondary" onClick={singleLogout}>
              Decline
            </Button>
            <Button
              variant="primary"
              disabled={!legalTermsData || !hasBeenDisplayed}
              loading={userConsentMutation.isLoading}
              onClick={onAccept}
            >
              Accept
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
