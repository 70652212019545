import { flatten } from 'array-flatten'
import { saveAsCSV } from 'utils/saveAsCSV'
import { getCellValueFormatted } from './helpers'
import type { BaseColumnConfig, NestableColumnConfig } from './useGridTable'

type Props = {
  name: string
  headerColumns: NestableColumnConfig<any>[][]
  columns: BaseColumnConfig<any>[]
  data: any[]
}

export const exportData = ({
  name,
  headerColumns,
  columns,
  data,
}: Props): void => {
  const headers = headerColumns.map(columns => {
    return flatten(
      columns.map(col => {
        // @ts-expect-error obsolete code
        const columns = [col.headerName || col.field || ' ']
        let span = col.span || 1
        while (span-- > 1) {
          columns.push('')
        }
        return columns
      }),
    )
  })
  const rowData: any[] = []
  data.forEach(row => {
    const columnData: any[] = []
    columns.forEach(col => {
      const val = getCellValueFormatted(col, row)
      if (col.getExportValue) {
        columnData.push(col.getExportValue({ value: val, row: row }))
      } else {
        columnData.push(val)
      }
    })
    rowData.push(columnData)
  })
  const csvData = [...headers, ...rowData]
  saveAsCSV(csvData, name)
}
