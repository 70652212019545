import { useStateRef } from 'hooks/useStateRef'
/**
 * useState
 *
 * Uses referential equality to skip renders
 *
 * Also it batches all of the setStates inside of queryThenMuateDOM for optimal performance.
 * (Everything rendering together = less Layout thrash)
 */
export const useState = <T>(
  initial?: T | (() => T),
): [T, (value: T, force?: boolean, skipQTMD?: boolean) => void] => {
  const [ref, set] = useStateRef<T>(initial)
  return [ref.current, set]
}
