import React, { FC, PropsWithChildren, memo } from 'react'
import { useSelector } from 'react-redux'
import type { CommonGalaxyUserData } from 'redux/models'
import { selectGalaxyUser } from 'redux/selectors/selectGalaxyUser'
import { isClientPortal } from 'utils/isClientPortal'
import { BannerPlaceholder } from './components/Placeholder'
import Styles from './styles.module.scss'

function shouldShowPlaceholder(user?: CommonGalaxyUserData): boolean {
  return user?.email === undefined
}

const BannerManager: FC<PropsWithChildren> = ({ children }) => {
  const userInfo = useSelector(selectGalaxyUser('common'))
  const content =
    isClientPortal && shouldShowPlaceholder(userInfo) ? (
      <BannerPlaceholder />
    ) : (
      children
    )

  return <div className={Styles.root}>{content}</div>
}

export default memo(BannerManager)
