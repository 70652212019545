export enum FILTER_OP {
  contains = 'cn',
  notContain = 'nc',
  equals = 'eq',
  notEqual = 'ne',
  startsWith = 'sw',
  endsWith = 'ew',
  inRange = 'in',
  greaterThan = 'gt',
  lessThan = 'lt',
}
