import { isBrowser } from 'utils/isBrowser'

const unloads = []
if (isBrowser) {
  const existingOnBeforeUnload = global.onbeforeunload
  const onBeforeUnload = function () {
    // eslint-disable-next-line prefer-rest-params
    existingOnBeforeUnload?.apply(this, arguments)
    unloads.forEach(fn => fn())
  }
  global.onbeforeunload = onBeforeUnload
}

export default function onBeforeUnload(fn: VoidFunction) {
  unloads.push(fn)
}
