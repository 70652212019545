import { batch } from 'react-redux'
import {
  requestAnimationFrame,
  requestAnimationFrameEnd,
} from 'utils/requestAnimationFrame'
import { rethrowError } from 'utils/rethrowError'

const queryFns: VoidFunction[] = []
let mutateFns: VoidFunction[] = []
let inQuery = false
let inMutation = false

function QTMDFrameRunner() {
  while (queryFns.length || mutateFns.length) {
    inQuery = true
    batch(() => {
      while (queryFns.length) {
        const queryFn = queryFns.shift()
        if (queryFn) {
          try {
            queryFn()
          } catch (e) {
            rethrowError(e)
          }
        }
      }
    })
    inQuery = false
    inMutation = true
    const currentMutateFns = mutateFns
    mutateFns = []
    batch(() => {
      while (currentMutateFns.length) {
        const mutateFn = currentMutateFns.shift()
        if (mutateFn) {
          try {
            mutateFn()
          } catch (e) {
            rethrowError(e)
          }
        }
      }
    })
    inMutation = false
  }
}

let frameRequested = false
function requestFrame() {
  if (!frameRequested) {
    requestAnimationFrame(() => {
      batch(() => {
        QTMDFrameRunner()
        frameRequested = false
      })
    })
    frameRequested = true
  }
}

export const queryThenMutateDOM = <T>(
  queryFn: () => T | null | undefined,
  mutateFn?: (T) => void | null | undefined,
): void => {
  let queryResult
  if (queryFn) {
    queryFns.push(() => {
      queryResult = queryFn()
    })
  }
  if (mutateFn) {
    mutateFns.push(() => {
      mutateFn(queryResult)
    })
  }
  requestFrame()
}

export const query = (fn: VoidFunction) => {
  queryFns.push(fn)
  requestFrame()
}

export const mutate = (fn: VoidFunction) => {
  mutateFns.push(fn)
  requestFrame()
}

export const queryThenMutateDOMXFramesFromNow = <T>(
  queryFn: () => T | null | undefined,
  mutateFn: (T) => void | null | undefined,
  times: number,
) => {
  let fn = () => {
    queryThenMutateDOM(queryFn, mutateFn)
  }
  while (times-- > 0) {
    fn = function (fn) {
      requestAnimationFrameEnd(fn)
    }.bind(null, fn)
  }
  fn()
}

export function isInMutation() {
  return inMutation
}

export function isInQuery() {
  return inQuery
}
