import React, {
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react'
import { Icon } from 'semantic-ui-react'
import { useEffectNow } from 'hooks/useEffectNow'
import { useInterval } from 'hooks/useInterval'
import { PASSIVE, useListener } from 'hooks/useListener'
import { useDragListener } from 'hooks/useSeperator'
import { useState } from 'hooks/useState'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { boundValue } from 'utils/boundValue'
import { queryThenMutateDOM } from 'utils/queryThenMutateDOM'

type GridTableToggleRightSideProps = {
  containerRef: MutableRefObject<HTMLElement | null>
  hideRightSide: boolean
  setHideRightSide: (hide: boolean) => void
  isLoading: boolean
  tableMidWidth: number
  setMidWidth: (width: number) => void
}

export const GridTableToggleRightSide = React.memo(
  (props: GridTableToggleRightSideProps) => {
    const { hideRightSide, tableMidWidth, setMidWidth } = props
    const propsRef = useUpdatingRef(props)
    const [position, setPosition] = useState({ left: -9999, right: 9999 })
    const positionRef = useUpdatingRef(position)
    const settingRef = useRef(false)

    const getAndSetPosition = useCallback(() => {
      if (settingRef.current) {
        return
      }
      const container = propsRef.current.containerRef.current
      if (!container) {
        return
      }
      settingRef.current = true
      queryThenMutateDOM(() => {
        settingRef.current = false
        const table = container.getElementsByClassName(
          'grid-table-root',
        )[0] as HTMLDivElement
        const header = container.getElementsByClassName(
          'grid-table-mid-viewport',
        )[0] as HTMLDivElement
        if (!table || !header) {
          return
        }
        const right = table.offsetWidth
        const left = header.offsetLeft
        setPosition({ left, right })
      }, null)
    }, [settingRef, propsRef, positionRef])

    useInterval(getAndSetPosition, 1000)
    useLayoutEffect(getAndSetPosition, [hideRightSide])
    useListener(document.body, 'scroll', getAndSetPosition, PASSIVE)

    const isDragging = useRef(false)
    const dragTimeout = useRef<NodeJS.Timeout>()

    const onClick = useCallback(() => {
      if (!isDragging.current) {
        propsRef.current.setHideRightSide(!propsRef.current.hideRightSide)
      }
    }, [propsRef])

    useEffectNow(() => {
      const boundedValue = boundValue(
        tableMidWidth,
        105,
        position.right - position.left - 105,
      )
      if (tableMidWidth !== boundedValue) {
        setMidWidth(boundedValue)
      }
    }, [tableMidWidth, position.left, position.right])

    const mousedownStartX = useRef(0)
    const startX = useRef(0)
    const { onMouseDown, onMouseUp } = useDragListener(
      ({ x }) => {
        mousedownStartX.current = x
        startX.current = tableMidWidth
      },
      ({ x }) => {
        const diff = x - mousedownStartX.current
        if (Math.abs(diff) > 1) {
          isDragging.current = true
          clearTimeout(dragTimeout.current)
          dragTimeout.current = setTimeout(() => {
            isDragging.current = false
          }, 3000)
        }
        setMidWidth(
          boundValue(
            startX.current + diff,
            105,
            position.right - 105 - position.left,
          ),
        )
      },
    )
    if (props.isLoading) {
      return null
    }

    return (
      <div
        style={{
          position: 'absolute',
          left: hideRightSide
            ? position.right + 'px'
            : position.left + tableMidWidth + 1 + 'px',
          height: '100%',
          top: 0,
        }}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onClick={onClick}
      >
        <Toggler isOpen={!hideRightSide} />
      </div>
    )
  },
)

GridTableToggleRightSide.displayName = 'GridTableToggleRightSide'

const Toggler = React.memo(({ isOpen }: { isOpen: boolean }) => (
  <div className="toggler-root">
    <div className="inner">
      {isOpen ? (
        <Icon name="caret right" size="big" />
      ) : (
        <Icon name="caret left" size="big" />
      )}
    </div>
  </div>
))

Toggler.displayName = 'Toggler'
