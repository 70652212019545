import {
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'
import {
  getRequestData,
  getURI,
} from 'components/GridTable/GridTableServerModelHelper'
import { useStateRef } from 'hooks/useStateRef'
import { get } from 'utils/http'
import { queryThenMutateDOM } from 'utils/queryThenMutateDOM'
import { throttle } from 'utils/throttle'
import { exportData } from './exportData'
import { useGridTable } from './useGridTable'

type Props = {
  endpoint: string
  strategyIdRef: MutableRefObject<number>
  withPost?: boolean
}
type LoadRequestOptions = {
  uri: string
  postData?: object
}

export const useGridTableServerModel = ({
  endpoint,
  strategyIdRef,
  withPost = false,
}: Props) => {
  const scrollContainerRef = useRef<HTMLDivElement>()

  const onScroll = useCallback(
    throttle(() => {
      let scrollContainer = scrollContainerRef.current
      if (!scrollContainer) {
        if (!gridApiRef.current) {
          return
        } else {
          scrollContainer = scrollContainerRef.current =
            gridApiRef.current.getScrollViewport()
          if (!scrollContainer) {
            return
          }
        }
      }
      let scrollTop = 0
      let scrollHeight = Infinity
      let offsetHeight = 0
      queryThenMutateDOM(
        () => {
          offsetHeight = scrollContainer.offsetHeight
          scrollHeight = scrollContainer.scrollHeight
          scrollTop = scrollContainer.scrollTop
        },
        () => {
          if (scrollHeight - scrollTop - offsetHeight < 200) {
            loadNext()
          }
        },
      )
    }, 100),
    [],
  )

  const gridApiRef = useRef<ReturnType<typeof useGridTable>['api']>()
  const [totalItemsRef, setTotalItems] = useStateRef<number>(0)
  const [isFetchingRef, setIsFetching] = useStateRef<boolean>(false)
  const [dataRef, setData] = useStateRef([])
  const subscribers = useRef<((arg: unknown) => void)[]>([])
  const unregisterRef = useRef<VoidFunction>()
  const hasFetchedRef = useRef(false)
  const isRefreshingRef = useRef(false)
  const fetchCount = useRef(0)
  const lastCancelTime = useRef(Date.now())

  const load = useCallback(
    (
      { uri, postData }: LoadRequestOptions,
      signalLoading = true,
      callback?: VoidFunction,
    ) => {
      if (signalLoading) {
        setIsFetching(true)
      }
      const startTime = Date.now()
      const ds = get(
        encodeURI(uri),
        withPost ? { url: '', body: postData } : '',
        orders => {
          if (lastCancelTime.current > startTime) {
            return
          }
          if (!hasFetchedRef.current) {
            hasFetchedRef.current = true
          }
          setData(orders.data)
          setTotalItems(orders.count)
          if (signalLoading) {
            setIsFetching(false)
          }
          subscribers.current.forEach(sub => {
            sub(orders.data)
          })
          onScroll()
          queryThenMutateDOM(null, () => {
            if (callback) {
              callback()
            }
          })
        },
        error => {
          console.error(error)
          queryThenMutateDOM(null, () => {
            if (callback) {
              callback()
            }
          })
          if (signalLoading) {
            setIsFetching(false)
          }
        },
      )
      if (signalLoading) {
        setIsFetching(true)
      }
      return ds
    },
    [],
  )

  const loadRefresh = useCallback(() => {
    if (!hasFetchedRef.current) {
      return loadNext()
    } else {
      if (isFetchingRef.current || isRefreshingRef.current) {
        return
      }
      const api = gridApiRef.current
      if (!api) {
        return
      }
      const filterConfig = api.getFilterConfig()
      const sortConfig = api.getSortConfig()

      isRefreshingRef.current = true
      const count = fetchCount.current + DefaultPageSize
      const params = {
        pageSize: count,
        strategyId: strategyIdRef.current,
        filterConfig,
        sortConfig,
      }
      const loadParams = withPost
        ? { uri: endpoint, postData: getRequestData(params) }
        : { uri: getURI({ endpoint, ...params }) }
      return load(loadParams, false, () => {
        isRefreshingRef.current = false
      })
    }
  }, [])

  const loadNext = useCallback(() => {
    if (isFetchingRef.current) {
      return
    }
    if (fetchCount.current > 0 && fetchCount.current >= totalItemsRef.current) {
      return
    }
    const api = gridApiRef.current
    if (!api) {
      return
    }
    fetchCount.current = fetchCount.current + DefaultPageSize
    const filterConfig = api.getFilterConfig()
    const sortConfig = api.getSortConfig()
    const params = {
      pageSize: fetchCount.current,
      strategyId: strategyIdRef.current,
      filterConfig,
      sortConfig,
    }
    const loadParams = withPost
      ? { uri: endpoint, postData: getRequestData(params) }
      : { uri: getURI({ endpoint, ...params }) }
    load(loadParams, true)
  }, [])

  const refresh = useCallback((clearTable = true) => {
    if (!hasFetchedRef.current && !clearTable) {
      return
    }
    if (clearTable) {
      setTotalItems(0)
      setIsFetching(false)
      setData([])
      hasFetchedRef.current = false
      isRefreshingRef.current = false
      fetchCount.current = 0
      lastCancelTime.current = Date.now()
    }
    return loadRefresh()
  }, [])

  const api = useMemo(
    () => ({
      loadNext,
      refresh,
      subscribe: (fn: (arg: unknown) => void): void => {
        subscribers.current.push(fn)
        fn(dataRef.current)
      },
      unsubscribe(fn: (arg: unknown) => void): void {
        subscribers.current = subscribers.current.filter(sub => sub !== fn)
      },
      setGridApi(gridApi: ReturnType<typeof useGridTable>['api']) {
        gridApiRef.current = gridApi
        unregisterRef.current && unregisterRef.current()
        queryThenMutateDOM(() => {
          loadNext()
          const viewport = gridApi.getScrollViewport()
          scrollContainerRef.current = viewport
          if (viewport) {
            viewport.addEventListener('scroll', onScroll)
            unregisterRef.current = () => {
              unregisterRef.current = null
              viewport.removeEventListener('scroll', onScroll)
            }
            onScroll()
          }
        })
        return () => {
          unregisterRef.current && unregisterRef.current()
        }
      },
      exportAllData: () => {
        const api = gridApiRef.current
        if (!api) {
          return
        }
        const params = {
          pageSize: 9999999,
          strategyId: strategyIdRef.current,
          filterConfig: api.getFilterConfig(),
          sortConfig: api.getSortConfig(),
        }
        const uri = withPost ? endpoint : getURI({ endpoint, ...params })
        const urlOrConfig = withPost ? { body: getRequestData(params) } : ''
        get(encodeURI(uri), urlOrConfig, ({ data }) => {
          exportData({
            ...api.getExportConfig(),
            data,
          })
        })
      },
    }),
    [],
  )

  useLayoutEffect(() => {
    return () => unregisterRef.current && unregisterRef.current()
  }, [])

  return {
    api,
    isFetching: isFetchingRef.current,
    totalItems: totalItemsRef.current,
    rowData: dataRef.current,
  }
}

const DefaultPageSize = 30
