import styled from 'styled-components'

export const FilterBaseContainer = styled.button`
  width: 17px;
  height: 17px;
  border: none;
  margin: 0 2px;
  padding: 0;
  background: none;
  border-radius: 2px;
  vertical-align: text-bottom;
  opacity: 0;
  transition: background 250ms ease-in-out, transform 150ms ease,
    opacity 100ms ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.colors.grey.dark.$01};
  }

  &:focus {
    outline: none;
    background: none;
  }

  *:hover > & {
    opacity: 1;
  }
`
