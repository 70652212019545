import { Selector } from 'react-redux'
import { RootState } from 'redux/reducers/state'
import { selectGalaxyUser } from './selectGalaxyUser'

export const selectUserFirstName: Selector<
  RootState,
  string | null
> = state => {
  const data = selectGalaxyUser('common')(state)
  return data?.firstName
}
