import cx from 'classnames'
import type { BaseColumnConfig } from 'components/GridTable'
import { weakMemo } from 'hooks/useWeakMapMemoCallback'
import { emptyObject } from 'utils/emptyObject'

export const getCellClass = weakMemo(
  <T>(row: T, col: BaseColumnConfig<T>, value: any) => {
    let className
    if (col.cellClass instanceof Function) {
      className = col.cellClass(row, col, value)
    } else if (Array.isArray(col.cellClass)) {
      className = cx(...col.cellClass)
    } else {
      className = col.cellClass
    }
    return className
  },
)

export const getCellStyle = weakMemo(
  <T>(row: T, col: BaseColumnConfig<T>, value?: any, valueFormatted?: any) => {
    if (col.cellStyle instanceof Function) {
      return col.cellStyle({
        row: row,
        colDef: col,
        col,
        data: row,
        value,
        valueFormatted,
      })
    }
    return col.cellStyle || emptyObject
  },
)
