import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useUpdatingRef } from './useUpdatingRef'

type SelectorFn<T, V> = (state: T) => V
type EqualityFn<T> = (left: T, right: T) => boolean

// Makes sure we don't trigger a re-render due to our selector function changing.
export const useSelectorPerformant = <T, V>(
  selector: SelectorFn<T, V>,
  equalityFn?: EqualityFn<V>,
): V => {
  const selectorRef = useUpdatingRef(selector)
  const ourSelector = useRef((rootState: T) => {
    return selectorRef.current(rootState)
  })
  return useSelector<T, V>(ourSelector.current, equalityFn)
}

export const useConstantSelectorPerformant = <T, V>(
  selector: (state: T) => V,
  equalityFn?: EqualityFn<V>,
): V => {
  const selectorRef = useRef(selector)
  return useSelector<T, V>(selectorRef.current, equalityFn)
}
