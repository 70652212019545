import { Type } from 'redux/actions/securities'
import { dispatch } from 'utils/dispatch'
import { get } from 'utils/http'
import { isClientPortal } from 'utils/isClientPortal'

const baseUrl = process.env.NEXT_PUBLIC_REFERENCE_SERVICE_BASE_URL || ''

export const getSecuritiesPrices = (securityIds: number[]) => {
  const path = 'prices/securities?securityIds=' + securityIds.join(',')
  return get(baseUrl, isClientPortal ? path : 'api/' + path, data => {
    dispatch({
      type: Type.SECURITIES_PRICES,
      payload: data,
    })
  })
}
