import format from 'date-fns/format'
import memoizee from 'memoizee'
import moment from 'moment'

export const currencyFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})
export const numberFormat = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 5,
})

export const formatNumber = memoizee(
  (value: number, precision = 0, maxPrecision = 0) => {
    return isNumber(value)
      ? Intl.NumberFormat('en-US', {
          minimumFractionDigits: precision,
          maximumFractionDigits: maxPrecision || precision,
        }).format(value)
      : value
  },
  { max: 100000, length: 2 },
)

const GalaxyUtils = {
  isNumber,
  formatNumber,
  toCurrency,
  currencyFormat,
  numberFormat,
  formatDate,
  formatDateMoment,
  formatUnixDateMoment,
  formatToNonZeroPrecision,
  titleCase,
  stringCompare,
}

export default GalaxyUtils

export function isNumber(value: number) {
  if (value == null) return false
  return !isNaN(value)
}

export function countDecimals(value: number) {
  if (Math.floor(value.valueOf()) === value.valueOf()) return 0
  return value.toString().split('.')[1].length || 0
}

export function toCurrency(value: number, precision = 0) {
  const formatted = formatNumber(Math.abs(value), precision)
  let sign = ''
  if (value < 0) {
    sign = '-'
  }
  return formatted !== value ? sign + '$' + formatted : value
}

export function formatDate(
  value: Date | string | number,
  sformat = 'dddd, MMMM D, YYYY h:mm A',
): string {
  return value ? format(value, sformat) : ''
}

export function formatDateMoment(
  value: Date | string | number,
  sformat = 'L',
): string {
  return value ? moment.utc(value).local().format(sformat) : ''
}

export function formatUnixDateMoment(value: number, sformat = 'L'): string {
  return value ? moment.unix(value).local().format(sformat) : ''
}

export function titleCase(str: string) {
  const title = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2')
  return title.charAt(0).toUpperCase() + title.slice(1)
}

/**
 * Compare two strings and return true if equal.
 * @param first First String to compare
 * @param second Second String to compare
 * @param caseSensitive optional flag which represent if compare need to be case sensitive. Default case insensitive.
 */
export function stringCompare(
  first: string,
  second: string,
  caseSensitive = false,
) {
  return (
    first.localeCompare(second, navigator.language, {
      sensitivity: caseSensitive === true ? 'case' : 'base',
    }) === 0
  )
}

export function formatToNonZeroPrecision(
  value: number,
  scale = 8,
): string | number {
  const formattedNumber = formatNumber(value, scale) as string
  if (formattedNumber) {
    let currentIndex = formattedNumber.length - 1
    let totalZeros = 0

    while (currentIndex > 0) {
      const currValue = formattedNumber[currentIndex]
      if (currValue === '0') totalZeros++
      else if (currValue === '.') {
        totalZeros++
        break
      } else {
        break
      }
      currentIndex--
    }
    return formattedNumber.slice(0, formattedNumber.length - totalZeros)
  }
  return formattedNumber
}
