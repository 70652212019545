export const getBeforeMaintenanceMessage = (localStartDisplayTime: string) => ({
  title: `Maintenance is scheduled for ${localStartDisplayTime} today.`,
  body: (
    <span>
      During this period, existing orders will be paused but can still be
      cancelled. New orders cannot be placed until maintenance is complete.
      Please contact{' '}
      <a href="mailto:galaxyone@galaxy.com">galaxyone@galaxy.com</a> for any
      assistance.
    </span>
  ),
})

export const maintenanceMessage = {
  title: 'Our system is currently undergoing maintenance.',
  body: 'Existing orders are paused but can still be cancelled. New orders cannot be placed until maintenance is complete.',
}
