import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import {
  TokenManagerRenewEventHandler,
  isAccessToken,
} from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import { AxiosError } from 'axios'
import { Type as UserAction } from 'redux/actions/user'
import { G1GalaxyUserData, GalaxyUserModel } from 'redux/models/GalaxyUser'
import { authorizationRequest } from 'utils/DataService'
import { dispatch } from 'utils/dispatch'
import { isClientPortal } from 'utils/isClientPortal'
import { isG1Route } from 'utils/isG1Route'
import sentry from 'utils/sentry'
import { __setOktaAuthService, login } from './auth'

const { captureException } = sentry()

const baseIdentityServiceUrl = process.env.NEXT_PUBLIC_IDENTITY_SERVICE_URL
const IDENTIFY_ME_PATH = isClientPortal ? '' : '/identify/me'

const NUMBER_OF_RETRIES = 5
type AuthorizationState =
  | 'authorized'
  | 'unauthorized'
  | 'authorizing'
  | 'error'

export type UseAuthHookResult = {
  isAuthenticated: boolean
  authorizationState: AuthorizationState
}

export type UseAuthHook = () => UseAuthHookResult

export const useAuth: UseAuthHook = () => {
  const [authorizationState, setAuthorizationState] =
    useState<AuthorizationState>('authorizing')
  const { authState, oktaAuth } = useOktaAuth()
  __setOktaAuthService(oktaAuth)

  if (authState && !authState.isAuthenticated) {
    login()
  }

  useEffect(() => {
    if (authState) {
      oktaAuth.tokenManager.setTokens(authState)
      dispatch({
        type: UserAction.OKTA_USER_CHANGED,
        payload: { accessToken: authState.accessToken?.accessToken },
      })
    }
  }, [authState, oktaAuth.tokenManager])

  useEffect(() => {
    const handler: TokenManagerRenewEventHandler = (_, newToken, oldToken) => {
      if (
        isAccessToken(newToken) &&
        isAccessToken(oldToken) &&
        newToken.accessToken !== oldToken.accessToken
      ) {
        dispatch({
          type: UserAction.OKTA_USER_CHANGED,
          payload: { accessToken: newToken.accessToken },
        })
      }
    }

    oktaAuth.tokenManager.on('renewed', handler)
    return () => oktaAuth.tokenManager.off('renewed', handler)
  }, [oktaAuth.tokenManager])

  useQuery(
    [
      baseIdentityServiceUrl,
      IDENTIFY_ME_PATH,
      authState?.accessToken?.accessToken,
    ],
    {
      enabled: !!authState?.isAuthenticated,
      refetchInterval: isClientPortal ? 1000 * 60 : false,
      refetchOnWindowFocus: false,
      retry: (failureCount: number) => failureCount < NUMBER_OF_RETRIES - 1,
      retryDelay: (failureCount: number) => failureCount * 1000,
      queryFn: ({ queryKey }) => {
        const [service, endpoint, token] = queryKey
        return authorizationRequest(service, endpoint, token)
      },
      onSuccess(user: GalaxyUserModel['data']) {
        dispatch({
          type: UserAction.GALAXY_USER_CHANGED,
          payload: user,
        })

        setAuthorizationState(
          (user as G1GalaxyUserData).counterPartyDetails?.length === 0
            ? 'unauthorized'
            : 'authorized',
        )
      },
      onError: (err: AxiosError) => {
        captureException(err, {
          url: `${baseIdentityServiceUrl}/${IDENTIFY_ME_PATH}`,
        })

        setAuthorizationState(
          err.response?.status === 401 ? 'unauthorized' : 'error',
        )

        const message = `${
          err?.response?.status === 401
            ? 'User not authorized for access'
            : 'There was unexpected error'
        }. Please contact ${
          isG1Route() ? 'cx@galaxy.com' : 'getsupport@galaxy.com'
        }`

        setTimeout(() => {
          toast.error(message, {
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
          })
        }, 1000)
      },
    },
  )

  const result = useMemo(
    () => ({
      isAuthenticated: authState?.isAuthenticated ?? false,
      authorizationState,
    }),
    [authState?.isAuthenticated, authorizationState],
  )

  return result
}
