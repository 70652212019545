import { get as getGK } from 'Gatekeeper'
import { AccountInfo } from 'redux/models'

export const platformAccessAccountAttributeTypeId = 33
export const tradingAccountAttributeTypeId = 21
export const marginTradingAccountAttributeTypeId = 32
export const avaxAccountAttributeTypeId = 37

//lending and borrowing accounts are part of the lending application
export const lendingAccountAttributeTypeId = 6
export const borrowerAccountAttributeTypeId = 7
export const g1GotcAccountAttributeTypeId = 41

const isTypeOfAccount =
  (...attributeTypeIds: number[]) =>
  (account: AccountInfo): boolean =>
    account &&
    account.accountAttributes.some(attr =>
      attributeTypeIds.includes(attr.accountAttributeTypeId),
    )

export const isLendingAccount = isTypeOfAccount(
  lendingAccountAttributeTypeId,
  borrowerAccountAttributeTypeId,
)

export const isTradingAccount = getGK('g1_lending_enable_margin_trading')
  ? isTypeOfAccount(
      tradingAccountAttributeTypeId,
      marginTradingAccountAttributeTypeId,
    )
  : isTypeOfAccount(tradingAccountAttributeTypeId)

export const isMarginTradingAccount = isTypeOfAccount(
  marginTradingAccountAttributeTypeId,
)
export const isCPOAccount = isTypeOfAccount(
  platformAccessAccountAttributeTypeId,
  avaxAccountAttributeTypeId,
)
export const isG1GotcAccount = isTypeOfAccount(g1GotcAccountAttributeTypeId)

export const getG1Accounts = (accounts: AccountInfo[]) => {
  const isMarginTradingEnabled = getGK('g1_lending_enable_margin_trading')
  const isLendingEnabled = getGK('g1_lending_enable_lending')

  return accounts?.filter(
    account =>
      account.isG1Account &&
      (isLendingEnabled || !isLendingAccount(account)) &&
      (isMarginTradingEnabled || !isMarginTradingAccount(account)),
  )
}
