import { rethrowError } from 'utils/rethrowError'
export const saveState = (name: string, state: unknown) => {
  if (!window.localStorage) {
    return
  }
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(name, serializedState)
  } catch (e) {
    // ignore write errors
    rethrowError(e)
  }
}

export const loadState = (name: string) => {
  if (!window.localStorage) {
    return {}
  }
  try {
    const serializedState = localStorage.getItem(name)
    if (serializedState === null) {
      return {}
    }
    return JSON.parse(serializedState)
  } catch (e) {
    rethrowError(e)
    return {}
  }
}
