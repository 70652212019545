export function emptyWindowSelection() {
  const selection = window.getSelection?.()

  if (selection) {
    if (selection.removeAllRanges) {
      selection.removeAllRanges()
    } else if (selection.empty) {
      selection.empty()
    }
  }
}
