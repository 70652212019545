export const sortAlphabetically = (a = '', b = '') => {
  for (let i = 0, l = a.length; i < l; i++) {
    if (b[i] == undefined) {
      return -1
    }
    if (a[i] < b[i]) {
      return -1
    }
    if (a[i] === b[i]) {
      if (i === a.length - 1) {
        if (b.length > a.length) {
          return -1
        }
        return 1
      }
      continue
    }
    return 1
  }
  return 0
}
