import React from 'react'
import { Button, Icon } from 'semantic-ui-react'

import Styles from './styles.module.scss'

type GalaxyAppErrorProps = {
  onErrorReport: VoidFunction
  onReload: VoidFunction
}

export const GalaxyAppError: React.FC<GalaxyAppErrorProps> = ({
  onErrorReport,
  onReload,
}) => (
  <dialog className={Styles.modal} open>
    <section className={Styles.heading}>
      <Icon name="times circle outline" size="huge" />
    </section>
    <section className={Styles.content}>
      <h1>A critical error has occured!</h1>
      <Button.Group size="large">
        <Button icon labelPosition="left" onClick={onErrorReport}>
          <Icon name="bullhorn" />
          Report this error
        </Button>
        <Button.Or />
        <Button icon labelPosition="right" onClick={onReload}>
          <Icon name="refresh" />
          Reload the page
        </Button>
      </Button.Group>
    </section>
  </dialog>
)
