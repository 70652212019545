import React, {
  ComponentType,
  ReactElement,
  ReactNode,
  ReactText,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'
import deepEqual from 'react-fast-compare'
import { flatten } from 'array-flatten'
import cx from 'classnames'
import copy from 'copy-to-clipboard'
import { produce } from 'immer'
import { KEY_C } from 'keycode-js'
import { Draggable } from 'components/Draggable/Draggable'
import { useDraggableController } from 'components/Draggable/useDraggableController'
import { ServerSideFilter } from 'components/GridTable/GridTableServerModelHelper'
import { PortalWrapper } from 'components/PortalWrapper'
import { useToolTipContext } from 'components/Tooltip/Tooltip'
import { useWeakMapCurriedCallback } from 'hooks/useCurriedCallback'
import { useIsFirstRender } from 'hooks/useIsFirstRender'
import { CAPTURE, useListener } from 'hooks/useListener'
import { usePrevious } from 'hooks/usePrevious'
import { useSelectorPerformant } from 'hooks/useSelectorPerformant'
import { useShallowCompareDep } from 'hooks/useShallowCompareDep'
import { useState } from 'hooks/useState'
import { useBatchedStateRef, useStateRef } from 'hooks/useStateRef'
import { useSynchronizeScrollPosition } from 'hooks/useSynchronizeScrollPosition'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { useWeakMapMemo2, weakMemo } from 'hooks/useWeakMapMemoCallback'
import { Type } from 'redux/actions/GridTables'
import { GridTableColumnState } from 'redux/models'
import { RootState } from 'redux/reducers/state'
import { boundValue } from 'utils/boundValue'
import { dispatch } from 'utils/dispatch'
import { emptyArray } from 'utils/emptyArray'
import emptyFunction from 'utils/emptyFunction'
import { emptyObject } from 'utils/emptyObject'
import { parseCSSValue } from 'utils/parseCSSValue'
import { requestAnimationFrameEnd } from 'utils/requestAnimationFrame'
import { Context } from './Context'
import { exportData } from './exportData'
import { FilterConfig, useGridTableFilter } from './GridTableFilter'
import { GridTableHeaderCell } from './GridTableHeaderCell'
import { GridTableRow } from './GridTableRow'
import { GridTableRowCell } from './GridTableRowCell'
import { getCellValue, getCellValueFormatted } from './helpers'
import { SortConfig, useGridTableSorter } from './useGridTableSorter'
import { VirtualTable } from './VirtualTable'

export type Config<T = any> = {
  id: string
  rowData: any[]
  columns: ColumnConfig<T>[]

  layout?: {
    top?: number
    left?: number
    mid?: number
    right?: number
    bottom?: number
  }
  autoresize?: boolean

  getRowHeight?: (row: T, index: number) => number
  rowHeight?: number
  rowRenderer?: (props: {
    children: any
    row: T
    index: number
    rowId: string
    key: React.Key
    hovered: boolean
    setHover: Function
    columns: ColumnConfig[]
    className: any
  }) => ReactNode
  headerRowRenderer?: (props: any) => ReactElement
  className?: string
  getRowKey: (row: any) => any
  exportName?: string
  noContent?: ReactElement | ReactText
  rowClass?: string | Function
  version?: number
  isLoading?: boolean
  onScroll?: any

  getContextMenuItems?: (
    props: {
      row: T
      col: BaseColumnConfig<T>
      getCellValue: typeof getCellValue
      getCellValueFormatted: typeof getCellValueFormatted
      value: any
      valueFormatted: any
    },
    hide: boolean,
  ) => ReactElement[]

  contextMenuHideExport?: boolean

  onCellClicked?: Function
  onCellDoubleClicked?: Function
  onRowClicked?: Function
  onRowDoubleClicked?: Function

  pinnedBottomRowData?: any[]
  pinnedTopRowData?: any[]

  onSortChanged?: (sortConfig: SortConfig) => void
  onFilterChanged?: (filterConfig: FilterConfig) => void
}

export type ColumnConfig<T = any> =
  | NestableColumnConfig<T>
  | BaseColumnConfig<T>

export type FilterComponent = {
  Klass: ComponentType
  filterFn: (filterArgs: any, value: any) => boolean
  toServerModel: (filterArgs: any) => ServerSideFilter
  allValues?: any[]
  needsAllValues?: boolean
  needsFormatted?: boolean
}
type _SharedColumnConfig<T = any> = {
  headerName?: string
  headerReact?: any
  headerClass?:
    | string
    | string[]
    | ((arg: { row; col; colDef; data }) => string)

  cellStyle?: Function | Object
  cellClass?:
    | string
    | string[]
    | ((row: T, col: BaseColumnConfig<T>, value: any) => string)
}
export type NestableColumnConfig<T> = _SharedColumnConfig<T> & {
  children: ColumnConfig<T>[]
  span: number
}

export type ReactProps<T> = {
  row: T
  col: ColumnConfig<T>
  rowIndex: number
  colIndex: number
  valueFormatted: any
  value: any
}
export type BaseColumnConfig<T = any> = _SharedColumnConfig<T> & {
  field?: string
  valueGetter?: Function
  valueFormatter?: Function
  format?: Function

  react?: (props: ReactProps<T>) => ReactElement

  width?: string | number
  minWidth?: string | number
  maxWidth?: string | number

  getExportValue?: Function
  getSortValue?: ({ row, value }: { row: T; value: any }) => any
  sortAsc?: Function
  sortDir?: any
  resizable?: boolean

  filter?: FilterComponent
  filterArgs?: any
  filterBypass?: any
  getFilterValue?: any
  draggable?: boolean

  hide?: boolean
  pinned?: 'left' | 'right' | string
  id?: string
}

export const useGridTable = function <T>(config: Config<T>) {
  config.rowData = config.rowData || emptyArray
  const configRef = useUpdatingRef(config)
  const columnState = useSelectorPerformant<
    RootState,
    GridTableColumnState[] | null
  >(rootState => rootState.GridTables[config.id] || null)
  const savedVersion = useSelectorPerformant<RootState, any>(
    rootState => rootState.GridTables.__version[config.id] || null,
  )

  const savedColumnsMap = useMemo(() => {
    const columnsMap = {}
    if (columnState) {
      columnState.forEach((def, i) => {
        if (!def) {
          return
        }
        // @ts-expect-error obsolete code
        columnsMap[def.colId] = {
          index: i,
          width: def.width,
        }
      })
    }
    return columnsMap
  }, [columnState])

  const baseColumns: BaseColumnConfig<T>[] = useMemo(() => {
    const cols = []
    const queue = config.columns.slice()
    while (queue.length) {
      let col = queue.shift()
      if (isBaseColumnConfig<T>(col)) {
        col = col as BaseColumnConfig<T>
        const id = getColId(col)
        cols.push({
          ...col,
          id,
          colId: id,
          width:
            (savedColumnsMap[id] && savedColumnsMap[id].width) ||
            col.width ||
            col.minWidth ||
            105,
          minWidth: col.minWidth || 70,
        })
      } else {
        queue.unshift(...(col as NestableColumnConfig<T>).children)
      }
    }
    return cols
  }, [config.columns, savedColumnsMap])

  const baseColumnsMap = useMemo(() => {
    const map = {}
    baseColumns.forEach(col => {
      map[col.id] = col
    })
    return map
  }, [baseColumns])

  const _headerColumns: any[] = useMemo(() => {
    return getLevels(config.columns, baseColumnsMap)
  }, [config.columns, baseColumnsMap])

  const columns = useMemo(() => {
    if (savedVersion < config.version) {
      return baseColumns
    }
    return baseColumns.slice().sort((a, b) => {
      if (savedColumnsMap[a.id] && savedColumnsMap[b.id]) {
        return savedColumnsMap[a.id].index - savedColumnsMap[b.id].index
      }
      if (savedColumnsMap[a.id]) {
        return -1
      }
      if (savedColumnsMap[b.id]) {
        return 1
      }
      return 0
    })
  }, [baseColumns, config.version, savedColumnsMap])

  const visibleColumns = useMemo(() => columns.filter(c => !c.hide), [columns])

  const headerColumns: any[] = useMemo(() => {
    return produce(_headerColumns, draft => {
      draft[_headerColumns.length - 1] = columns
    })
  }, [_headerColumns, columns])

  const columnsRef = useUpdatingRef(columns)

  const filters = {}
  let filterConfig = useMemo(() => {
    baseColumns.forEach(def => {
      filters[def.id] = {
        ...def.filter,
        bypass: def.filterBypass,
      }
    })
    return {
      filters,
      activeFilters: {}, // TODO Retrieve Active Filter from Redux
    }
  }, [baseColumns])
  const [filterConfigRef, setFilterConfig] =
    useStateRef<FilterConfig>(filterConfig)
  const prevConfig = usePrevious(filterConfig).current || {
    filters: {},
    activeFilters: {},
  }
  filterConfigRef.current = produce(filterConfigRef.current, draft => {
    const allKeys = Array.from(
      new Set([
        ...Object.keys(prevConfig.filters),
        ...Object.keys(filterConfig.filters),
      ]),
    )
    allKeys.forEach(key => {
      if (!deepEqual(prevConfig.filters[key], filterConfig.filters[key])) {
        draft.filters[key] = filterConfig.filters[key]
      }
    })
  })
  filterConfig = filterConfigRef.current

  const { getFilterComponent, filteredRowData } = useGridTableFilter<T>({
    id: config.id,
    rowData: config.rowData,
    columnConfig: baseColumns,
    getCellValue,
    getCellValueFormatted,
    config: filterConfig,
    onConfigChange: setFilterConfig,
  })

  let sortConfig = useMemo(() => {
    const sorts = {}
    let activeSorts = {}
    baseColumns.forEach(def => {
      sorts[def.id] = {
        sortAsc: def.sortAsc,
      }
      if (def.sortDir !== undefined) {
        activeSorts = {
          [def.id]: {
            direction: def.sortDir,
            priority: 1,
          },
        }
      }
    })
    return {
      sorts,
      activeSorts,
    }
  }, [baseColumns])
  const [sortConfigRef, setSortConfig] = useStateRef<SortConfig>(sortConfig)

  const prevSortConfig = usePrevious(sortConfig).current || emptyObject
  sortConfigRef.current = produce(sortConfigRef.current, draft => {
    const allKeys = Array.from(
      new Set([...Object.keys(prevSortConfig), ...Object.keys(sortConfig)]),
    )
    allKeys.forEach(key => {
      if (!deepEqual(sortConfig[key], prevSortConfig[key])) {
        draft[key] = sortConfig[key]
      }
    })
  })
  sortConfig = sortConfigRef.current

  const { getSortComponent, sortedRowData, toggleSort } = useGridTableSorter<T>(
    {
      id: config.id,
      rowData: filteredRowData,
      columnConfig: baseColumns,
      getCellValue,
      getCellValueFormatted,
      config: sortConfig,
      onConfigChange: setSortConfig,
    },
  )

  const dragFn = useCallback((fromId, toId) => {
    const defs = []
    columnsRef.current.map(col => {
      defs.push({
        colId: col.id,
        width: col.width,
      })
    })
    let fromIndex = defs.findIndex(val => val.colId === fromId)
    let toIndex = defs.findIndex(val => val.colId === toId)
    if (toIndex > fromIndex) {
      toIndex += 1
    }
    defs.splice(toIndex, 0, defs[fromIndex])
    if (toIndex < fromIndex) {
      fromIndex += 1
    }
    defs.splice(fromIndex, 1)

    dispatch({
      type: Type.SET_COLUMN_DEFS,
      payload: {
        id: configRef.current.id,
        defs,
        version: configRef.current.version,
      },
    })
  }, [])

  const dragOptions = useMemo(
    () => ({
      makeClone: ({ node }) => {
        const name = node.querySelector('.header').innerText
        const clone = document.createElement('div')
        clone.classList.add('grid-table-drag-clone')
        clone.classList.add('draggable')
        clone.style.width = 'auto'
        clone.innerText = name
        return clone
      },
    }),
    [],
  )

  const leftDragController = useDraggableController(dragFn, dragOptions)
  const midDragController = useDraggableController(dragFn, dragOptions)
  const rightDragController = useDraggableController(dragFn, dragOptions)

  const getHandlers = useWeakMapMemo2((row, col) => {
    return {
      onClick: () => {
        const click = configRef.current.onCellClicked
        if (click) {
          click(row, col)
        }
        setSelectedCell({
          row,
          col,
          value: getCellValue(col, row),
          valueFormatted: getCellValueFormatted(col, row),
        })
      },
      onDoubleClick: () => {
        const dblClick = configRef.current.onCellDoubleClicked
        if (dblClick) {
          dblClick(row, col)
        }
      },
    }
  })

  const triggerContextMenu = useWeakMapCurriedCallback(
    (row, col, value, valueFormatted) => {
      setContextColumn({
        row,
        col,
        getCellValue,
        getCellValueFormatted,
        value,
        valueFormatted,
      })
    },
    [getCellValue, getCellValueFormatted],
  )

  const getCell = useWeakMapMemo2(
    (row, col, cellWrapper, rowIndex, colIndex) => {
      const value = getCellValue(col, row)
      let valueFormatted = getCellValueFormatted(col, row)
      if (col.react) {
        valueFormatted = col.react({
          row,
          col,
          rowIndex,
          colIndex,
          valueFormatted,
          value,
        })
      }
      const cell = (
        <GridTableRowCell
          key={col.field}
          col={col}
          row={row}
          value={value}
          valueFormatted={valueFormatted}
          onContextMenu={triggerContextMenu(row, col, value, valueFormatted)}
          {...getHandlers(row, col)}
        >
          {valueFormatted}
        </GridTableRowCell>
      )
      return cellWrapper
        ? cellWrapper(col, cell, colIndex, row, rowIndex)
        : cell
    },
  )

  const getColumnUpdater = useMemo(() => {
    const store = {}
    return (column: BaseColumnConfig<T>) => {
      if (!store[column.id]) {
        store[column.id] = (size: number) => {
          const defs = columnsRef.current.map(col => {
            const width = getColId(col) === getColId(column) ? size : col.width
            return {
              colId: getColId(col),
              width: boundValue(
                // @ts-expect-error obsolete code
                width,
                parseCSSValue(col.minWidth || 10),
                parseCSSValue(col.maxWidth || Infinity),
              ),
            }
          })

          dispatch({
            type: Type.SET_COLUMN_DEFS,
            payload: {
              id: configRef.current.id,
              defs: defs.map(def => {
                def.colId = getColId(def)
                return def
              }),
              version: configRef.current.version,
            },
          })
        }
      }
      return store[column.id]
    }
  }, [])

  const getHeaderOnClick = useMemo(() => {
    const store = {}
    return id => {
      if (!store[id]) {
        store[id] = ev => {
          ev.stopPropagation()
          toggleSort(id)
        }
      }
      return store[id]
    }
  }, [])

  const getHeaderRow = useWeakMapMemo2(
    useCallback(
      (row, _2, columns, colStart = 0, cellWrapper: Function = null) => {
        const inner: any = columns.map((def, i) => {
          const filters = filterConfig.activeFilters[def.id]
          let hasFilter = !!filters
          if (filters instanceof Array && !filters.length) {
            hasFilter = false
          }
          const hasSort = !!sortConfig.activeSorts[def.id]
          const sortComponent = getSortComponent(def.id)
          const filterComponent = getFilterComponent(def.id)

          let val = def.headerName
          if (def.headerReact) {
            val = def.headerReact({
              col: def,
              colIndex: i,
              rowIndex: -1,
            })
          } else if (def.headerName == null && def.field) {
            val = def.field.charAt(0).toUpperCase() + def.field.substring(1)
          }
          let dragController
          if (def.pinned === 'left') {
            dragController = leftDragController
          } else if (def.pinned === 'right') {
            dragController = rightDragController
          } else {
            dragController = midDragController
          }
          const cellInner = (
            <GridTableHeaderCell
              row={row}
              col={def}
              key={i}
              filterComponent={filterComponent}
              sortComponent={sortComponent}
              resizable={def.resizable == undefined ? true : def.resizable}
              hasFilterApplied={hasFilter}
              hasSortApplied={hasSort}
              onClick={getHeaderOnClick(def.id)}
              updateColumnWidth={getColumnUpdater(def)}
            >
              {val}
            </GridTableHeaderCell>
          )
          const cell =
            def.draggable !== false ? (
              <Draggable controller={dragController} id={def.id} key={def.id}>
                {cellInner}
              </Draggable>
            ) : (
              cellInner
            )
          return cellWrapper ? cellWrapper(def, cell, colStart + i, row) : cell
        })
        if (config.headerRowRenderer) {
          return config.headerRowRenderer({
            children: inner,
            columns,
            className: 'grid-table-header grid-table-row',
          })
        }
        return (
          <GridTableRow
            config={config}
            key="header"
            className="grid-table-header grid-table-row"
          >
            {inner}
          </GridTableRow>
        )
      },
      [
        columns,
        config.headerRowRenderer,
        config.rowData,
        leftDragController,
        midDragController,
        rightDragController,
        getFilterComponent,
        sortConfig,
        filterConfig,
        getColumnUpdater,
      ],
    ),
  )

  const [hoveredRow, setHoveredRow] = useBatchedStateRef<any>(null, 0)
  const [contextRow, setContextRow] = useStateRef<any>(null)
  const [contextColumn, setContextColumn] = useStateRef<any>(null)
  const [selectedCell, setSelectedCell] = useStateRef<any>(null)

  useListener(
    document.body,
    'keydown',
    useCallback(event => {
      if (
        event.keyCode !== KEY_C ||
        !(event.ctrlKey || event.metaKey) ||
        !selectedCell.current
      ) {
        return
      }
      copy(selectedCell.current.value)
      event.stopPropagation()
      event.preventDefault()
    }, []),
  )

  useListener(
    document.body,
    'click',
    useCallback(() => {
      setSelectedCell(null)
    }, []),
    CAPTURE,
  )

  const getRowInner = useWeakMapMemo2(
    (
      row: T,
      columns: ColumnConfig<T>[],
      cellWrapper,
      rowIndex: number,
      colStart: number,
    ) => {
      return columns.map((col, colIndex) => {
        return getCell(row, col, cellWrapper, rowIndex, colStart + colIndex)
      })
    },
  )

  const getRowHandlers = useWeakMapMemo2(
    useCallback(row => {
      return {
        onMouseEnter: () => setHoveredRow && setHoveredRow(row),
        onContextMenu: () => {
          setContextRow && setContextRow(row)
          setShowContextMenu(true)
        },
        onClick: () => {
          const config = configRef.current
          config.onRowClicked && (config.onRowClicked(row) as any)
        },
        onDoubleClick: ev => {
          ev.stopPropagation()
          const config = configRef.current
          config.onRowDoubleClicked && (config.onRowDoubleClicked(row) as any)
        },
      }
    }, []),
  )

  const getRowOuter = useWeakMapMemo2(
    useCallback(
      (
        row,
        columns,
        Klass,
        cellWrapper: Function = emptyFunction,
        rowIndex,
        colStart = 0,
        className,
        key,
      ) => {
        let inner = getRowInner(row, columns, cellWrapper, rowIndex, colStart)
        const rowProps = {
          row,
          key,
          rowIndex: key,
          columns,
          index: rowIndex,
          className: cx('grid-table-row', {
            [className]: !!className,
          }),
          ...getRowHandlers(row),
        }

        if (Klass) {
          // @ts-expect-error obsolete code
          inner = <Klass {...rowProps}>{inner}</Klass>
        }

        return <GridTableRow {...rowProps}>{inner}</GridTableRow>
      },
      [],
    ),
  )

  const getRow = useWeakMapMemo2(
    (row, columns, cellWrapper, rowIndex, colStart = 0) => {
      if (row.__headerRow) {
        return getHeaderRow(row, rowIndex, columns, colStart, cellWrapper)
      }
      const Klass = config.rowRenderer
      const key = configRef.current.getRowKey(row)

      const className2 =
        config.rowClass instanceof Function
          ? config.rowClass(row)
          : config.rowClass

      return getRowOuter(
        row,
        columns,
        Klass,
        cellWrapper,
        rowIndex,
        colStart,
        className2,
        key,
      )
    },
    [
      sortedRowData,
      columns,
      config.rowRenderer,
      config.rowClass,
      config.getRowKey,
      getHeaderRow,
      getCell,
    ],
  )

  const [showContextMenu, setShowContextMenu] = useState(false)
  const [contextDimensions, setContextDimensions] = useState({
    left: -9999,
    top: -9999999,
  })

  const onContextMenu = useCallback((e: MouseEvent) => {
    setShowContextMenu(true)
    setContextDimensions({ left: e.clientX, top: e.clientY })
    e.preventDefault()
  }, [])

  const sortedRowDataRef = useUpdatingRef(sortedRowData)
  const headerColumnsRef = useUpdatingRef(headerColumns)

  const exportCB = useCallback(() => {
    setShowContextMenu(false)
    exportData(api.getExportConfig())
  }, [])

  const hasRows = !!sortedRowData.length

  const leftColumns = useMemo(
    () => visibleColumns.filter(col => col.pinned === 'left'),
    [visibleColumns],
  )
  const rightColumns = useMemo(
    () => visibleColumns.filter(col => col.pinned === 'right'),
    [visibleColumns],
  )
  const unpinnedColumns = useMemo(
    () =>
      visibleColumns.filter(
        col => col.pinned !== 'left' && col.pinned !== 'right',
      ),
    [visibleColumns],
  )

  const [topViewportRef, setTopViewportRef] = useStateRef<HTMLDivElement>()
  const [midViewportRef, setMidViewportRef] = useStateRef<HTMLDivElement>()
  const [botViewportRef, setBotViewportRef] = useStateRef<HTMLDivElement>()
  const pinnedTopRowData = config.pinnedTopRowData || emptyArray
  const pinnedBottomRowData = config.pinnedBottomRowData || emptyArray
  const botRowData = useMemo(() => {
    return [...pinnedBottomRowData].filter(r => r)
  }, [pinnedBottomRowData])

  const [topMidTableRef, setTopMidTableRef] = useStateRef<HTMLDivElement>()
  const [midTableRef, setMidTableRef] = useStateRef<HTMLDivElement>()
  const [botMidTableRef, setBotMidTableRef] = useStateRef<HTMLDivElement>()
  const [topLeftTableRef, setTopLeftTableRef] = useStateRef<HTMLDivElement>()
  const [leftTableRef, setLeftTableRef] = useStateRef<HTMLDivElement>()
  const [botLeftTableRef, setBotLeftTableRef] = useStateRef<HTMLDivElement>()
  const [topRightTableRef, setTopRightTableRef] = useStateRef<HTMLDivElement>()
  const [rightTableRef, setRightTableRef] = useStateRef<HTMLDivElement>()
  const [botRightTableRef, setBotRightTableRef] = useStateRef<HTMLDivElement>()
  let topLeftTable, topMidTable, topRightTable
  let leftTable, rightTable
  let botLeftTable, botRightTable

  const layout = config.layout || {}

  const leftTableWidth = useMemo(
    () =>
      !layout.left
        ? leftColumns.reduce((s, c) => s + parseCSSValue(c.width), 0) + 'px'
        : layout.left,
    [layout.left || leftColumns],
  )
  const topHeight = useMemo(() => {
    if (layout.top) {
      return layout.top
    }
    if (config.getRowHeight) {
      const pinnedRowsHeight = reduceTotalHeight(
        config.getRowHeight,
        pinnedTopRowData,
      )
      const headers = headerColumns.map(() => getFakeHeaderRow())
      const headersHeight = reduceTotalHeight(config.getRowHeight, headers)
      return pinnedRowsHeight + headersHeight + 'px'
    }
    return (
      (pinnedTopRowData.length + headerColumns.length) *
        ((config.rowHeight as any) || 28) +
      'px'
    )
  }, [layout.top || pinnedTopRowData.length + headerColumns.length])

  const botHeight = useMemo(() => {
    if (layout.bottom) {
      return layout.bottom
    }
    if (config.getRowHeight) {
      return reduceTotalHeight(config.getRowHeight, botRowData) + 12 + 'px'
    }
    return 'calc(auto + 12px)'
  }, [layout.bottom || botRowData, config.getRowHeight || config.rowHeight])

  const midWidth = layout.mid && rightColumns.length ? layout.mid + 'px' : `1fr`

  const sharedProps = useMemo(
    () => ({
      config,
      rowHeight: config.rowHeight || 28,
      getRowHeight: config.getRowHeight,
      getRow,
      getCell,
      isLoading: config.isLoading,
      autoresize: config.autoresize,
    }),
    [getCell, getRow, config],
  )

  if (leftColumns.length) {
    if (pinnedTopRowData.length) {
      topLeftTable = (
        <VirtualTable
          {...sharedProps}
          rowData={pinnedTopRowData}
          columns={leftColumns}
          hScrollContainer={topLeftTableRef.current}
          vScrollContainer={topViewportRef.current}
        />
      )
    }
    botLeftTable = (
      <div ref={setBotLeftTableRef} className="grid-table-left-viewport">
        <VirtualTable
          {...sharedProps}
          rowData={botRowData}
          columns={leftColumns}
          hScrollContainer={botLeftTableRef.current}
          vScrollContainer={botViewportRef.current}
          extraHeight={12}
        />
      </div>
    )
    leftTable = (
      <div ref={setLeftTableRef} className="grid-table-left-viewport">
        <VirtualTable
          {...sharedProps}
          rowData={sortedRowData}
          columns={leftColumns}
          hScrollContainer={leftTableRef.current}
          vScrollContainer={midViewportRef.current}
        />
      </div>
    )
  }
  if (rightColumns.length) {
    if (pinnedTopRowData.length) {
      topRightTable = (
        <VirtualTable
          {...sharedProps}
          rowData={pinnedTopRowData}
          columns={rightColumns}
          hScrollContainer={topRightTableRef.current}
          vScrollContainer={topViewportRef.current}
        />
      )
    }
    rightTable = (
      <div ref={setRightTableRef} className="grid-table-right-viewport">
        <VirtualTable
          {...sharedProps}
          rowData={sortedRowData}
          columns={rightColumns}
          hScrollContainer={rightTableRef.current}
          vScrollContainer={midViewportRef.current}
        />
      </div>
    )
    botRightTable = (
      <div ref={setBotRightTableRef} className="grid-table-right-viewport">
        <VirtualTable
          {...sharedProps}
          rowData={botRowData}
          columns={rightColumns}
          hScrollContainer={botRightTableRef.current}
          vScrollContainer={botViewportRef.current}
          extraHeight={12}
        />
      </div>
    )
  }

  if (pinnedTopRowData.length) {
    topMidTable = (
      <VirtualTable
        {...sharedProps}
        rowData={pinnedTopRowData}
        columns={unpinnedColumns}
        hScrollContainer={topMidTableRef.current}
        vScrollContainer={topViewportRef.current}
      />
    )
  }
  const midTable = (
    <div className={'grid-table-mid-viewport'} ref={setMidTableRef}>
      <VirtualTable
        {...sharedProps}
        rowData={sortedRowData}
        columns={unpinnedColumns}
        hScrollContainer={midTableRef.current}
        vScrollContainer={midViewportRef.current}
      />
    </div>
  )
  const botMidTable = (
    <div className={'grid-table-mid-viewport'} ref={setBotMidTableRef}>
      <VirtualTable
        {...sharedProps}
        rowData={botRowData}
        columns={unpinnedColumns}
        hScrollContainer={botMidTableRef.current}
        vScrollContainer={botViewportRef.current}
        extraHeight={12}
      />
    </div>
  )

  const leftTemp = leftColumns.length ? `${leftTableWidth} ` : ''
  const midTemp = midWidth
  const rightTemp = rightColumns.length ? ' 1fr ' : ''
  const gridCss = {
    display: 'grid',
    gridTemplateColumns: leftTemp + midTemp + rightTemp,
    gridTemplateRows: 'max-content',
    minHeight: '100%',
  }

  const bot = (
    <div
      className={cx('grid-table-body', 'grid-table-bottom', {
        'grid-table-last': !!botRowData.length,
      })}
      style={{ height: botHeight, ...gridCss }}
      ref={setBotViewportRef}
    >
      {botLeftTable}
      {botMidTable}
      {botRightTable}
    </div>
  )

  const topLeftTables = useMemo(() => {
    if (!leftColumns.length) {
      return null
    }
    return (
      <div ref={setTopLeftTableRef} className="grid-table-left-viewport">
        {headerColumns.map((columns, i) => (
          <VirtualTable
            key={'headers' + i}
            {...sharedProps}
            rowData={FakeHeaderRowData}
            columns={getLeftColumns(columns)}
            hScrollContainer={topLeftTableRef.current}
            vScrollContainer={topViewportRef.current}
          />
        ))}
        {topLeftTable}
      </div>
    )
  }, [
    topLeftTable,
    headerColumns,
    topLeftTableRef.current,
    topViewportRef.current,
    layout,
    useShallowCompareDep(sharedProps),
  ])

  const topRightTables = useMemo(() => {
    if (!rightColumns.length) {
      return null
    }
    return (
      <div ref={setTopRightTableRef} className="grid-table-right-viewport">
        {headerColumns.map((columns, i) => (
          <VirtualTable
            key={i}
            {...sharedProps}
            rowData={FakeHeaderRowData}
            columns={getRightColumns(columns)}
            hScrollContainer={topRightTableRef.current}
            vScrollContainer={topViewportRef.current}
          />
        ))}
        {topRightTable}
      </div>
    )
  }, [
    topRightTable,
    headerColumns,
    topRightTableRef.current,
    topViewportRef.current,
    layout,
    useShallowCompareDep(sharedProps),
  ])

  const topMidTables = useMemo(() => {
    return (
      <div className={'grid-table-mid-viewport'} ref={setTopMidTableRef}>
        {headerColumns.map((columns, i) => {
          return (
            <VirtualTable
              key={i}
              {...sharedProps}
              rowData={FakeHeaderRowData}
              columns={getUnpinnedColumns(columns)}
              hScrollContainer={topMidTableRef.current}
              vScrollContainer={topViewportRef.current}
            />
          )
        })}
        {topMidTable}
      </div>
    )
  }, [
    topMidTable,
    headerColumns,
    topMidTableRef,
    topViewportRef.current,
    layout,
    useShallowCompareDep(sharedProps),
  ])

  const top = (
    <div
      className="grid-table-body grid-table-top"
      style={{ height: topHeight, ...gridCss }}
      ref={setTopViewportRef}
    >
      {topLeftTables}
      {topMidTables}
      {topRightTables}
    </div>
  )
  const mid = (
    <div
      className={cx('grid-table-body', 'grid-table-mid', {
        'grid-table-last': !botRowData.length,
      })}
      style={{
        minHeight: '100%',
        ...gridCss,
        gridTemplateRows: 'max-content',
      }}
      onScroll={config.onScroll}
      data-testid={'grid-table-mid-viewport'}
      ref={setMidViewportRef}
    >
      {leftTable}
      {midTable}
      {rightTable}
    </div>
  )

  const leftArr = [topLeftTableRef, leftTableRef, botLeftTableRef].map(
    d => d.current,
  )
  const leftDivs = useMemo(() => leftArr, leftArr)
  const midVArr = [topMidTableRef, midTableRef, botMidTableRef].map(
    d => d.current,
  )
  const midVDivs = useMemo(() => midVArr, midVArr)
  const rightArr = [topRightTableRef, rightTableRef, botRightTableRef].map(
    d => d.current,
  )
  const rightDivs = useMemo(() => rightArr, rightArr)

  useSynchronizeScrollPosition(leftDivs, { horizontal: true })
  useSynchronizeScrollPosition(midVDivs, { horizontal: true })
  useSynchronizeScrollPosition(rightDivs, { horizontal: true })
  useWithHScrollListener(
    topLeftTableRef.current,
    leftDragController.measureRects,
  )
  useWithHScrollListener(topMidTableRef.current, midDragController.measureRects)
  useWithHScrollListener(
    topRightTableRef.current,
    rightDragController.measureRects,
  )

  let content
  if (config.isLoading) {
    content = (
      <>
        {mid}
        <div>
          <div className="grid-table-status-box">Loading</div>
        </div>
      </>
    )
  } else if (hasRows) {
    content = <>{mid}</>
  } else if (config.noContent) {
    content = config.noContent
  } else {
    content = (
      <>
        {mid}
        <div>
          <div className="grid-table-status-box">
            <div>No Rows To Show</div>
          </div>
        </div>
      </>
    )
  }

  const onMouseLeave = useCallback(() => {
    setHoveredRow(null)
  }, [])

  const table = (
    <Context.Provider
      value={useMemo(
        () => ({
          selectedCell: selectedCell.current,
          hoveredRow: hoveredRow.current,
          contextedRow: contextRow.current,
          getRowKey: config.getRowKey,
        }),
        [
          selectedCell.current,
          hoveredRow.current,
          contextRow.current,
          config.getRowKey,
        ],
      )}
    >
      <div
        className={cx(
          'grid-table-root',
          config.className,
          'virtual',
          config.isLoading ? 'loading' : null,
        )}
        onContextMenu={onContextMenu as any}
        onMouseLeave={onMouseLeave as any}
      >
        {top}
        {content}
        {bot}
        {showContextMenu ? (
          <PortalWrapper>
            <ContextMenu
              contextRow={contextRow.current}
              contextColumn={contextColumn.current}
              getContextMenuItems={config.getContextMenuItems}
              hideExport={config.contextMenuHideExport}
              export={exportCB}
              contextDimensions={contextDimensions}
              show={showContextMenu}
              setShow={setShowContextMenu}
            />
          </PortalWrapper>
        ) : null}
      </div>
    </Context.Provider>
  )

  const isFirstRender = useIsFirstRender()
  useEffect(() => {
    if (isFirstRender) {
      return
    }
    const onFilterChanged = configRef.current.onFilterChanged
    if (onFilterChanged) {
      onFilterChanged(filterConfigRef.current)
    }
  }, [filterConfigRef.current, isFirstRender])

  useEffect(() => {
    if (isFirstRender) {
      return
    }
    const onSortChanged = configRef.current.onSortChanged
    if (onSortChanged) {
      onSortChanged(sortConfigRef.current)
    }
  }, [sortConfigRef.current, isFirstRender])

  const api = useMemo(
    () => ({
      getDisplayRows: () => sortedRowDataRef.current,
      updateColumnDefs: defs => {
        dispatch({
          type: Type.SET_COLUMN_DEFS,
          payload: {
            id: configRef.current.id,
            defs: defs.map(def => {
              def.colId = getColId(def)
              return def
            }),
            version: configRef.current.version,
          },
        })
      },
      getScrollViewport: () => midViewportRef.current,
      getSortConfig: () => sortConfigRef.current,
      getFilterConfig: () => filterConfigRef.current,
      getExportConfig: () => ({
        data: sortedRowDataRef.current,
        columns: columnsRef.current,
        headerColumns: headerColumnsRef.current,
        name: config.exportName || config.id,
      }),
      getCellValue,
      getCellValueFormatted,
      setActiveFilters(filters: FilterConfig['activeFilters']) {
        const nextFilterConfig = produce(filterConfigRef.current, draft => {
          draft.activeFilters = filters
        })
        setFilterConfig(nextFilterConfig)
      },
      setActiveSort(sort: SortConfig['activeSorts']) {
        setSortConfig(
          produce(sortConfigRef.current, draft => {
            draft.activeSorts = sort
          }),
        )
      },
    }),
    [],
  )

  return {
    table,
    api,
  }
}

type ContextMenuProps = {
  contextDimensions: any
  show: boolean
  setShow: Function
  export: Function
  getContextMenuItems?: Function
  hideExport?: boolean
  contextRow: any
  contextColumn: any
}

export const ContextMenu = React.memo((props: ContextMenuProps) => {
  const setShowRef = useUpdatingRef(props.setShow)
  const rootRef = useRef<HTMLDivElement>(null)

  const hide = useCallback(() => {
    setShowRef.current(false)
  }, [])

  const { context, renderer } = useToolTipContext(rootRef)

  const listener = useCallback(event => {
    const root = rootRef.current
    if (
      root &&
      !root.contains(event.target) &&
      !context.contains(event.target)
    ) {
      hide()
    }
  }, [])

  useLayoutEffect(() => {
    if (props.show) {
      requestAnimationFrameEnd(() => {
        document.body.addEventListener('mousedown', listener)
      })
    } else {
      document.body.removeEventListener('mousedown', listener)
    }
    return () => {
      document.body.removeEventListener('mousedown', listener)
    }
  }, [props.show])

  const onClickWrapper = useWeakMapMemo2(handler => {
    return event => {
      hide()
      handler(event)
    }
  }, [])

  const { getContextMenuItems, hideExport } = props
  const items = useMemo(() => {
    const items = []
    if (getContextMenuItems) {
      let contextItems = getContextMenuItems(
        {
          row: props.contextRow,
          ...(props.contextColumn || {}),
        },
        hide,
      )
      if (
        contextItems &&
        contextItems[0] &&
        contextItems[0].name &&
        contextItems[0].action
      ) {
        contextItems = contextItems.map(item => (
          <div key={item.name} onClick={onClickWrapper(item.action)}>
            {item.name}
          </div>
        ))
      }
      items.push(...(contextItems || []))
    }
    if (!hideExport) {
      items.push(
        <div className="export" onClick={onClickWrapper(props.export)}>
          Export
        </div>,
      )
    }
    return items
  }, [getContextMenuItems, hideExport])

  if (!items.length) {
    return null
  }

  return renderer(
    <div
      style={props.contextDimensions}
      className={cx('grid-table-context-menu', {
        hidden: !props.show,
      })}
      ref={rootRef}
    >
      {items}
    </div>,
  )
})

function isNestableColumnConfig<T>(
  def: ColumnConfig<T>,
): def is NestableColumnConfig<T> {
  return (def as NestableColumnConfig<T>).children !== undefined
}
function isBaseColumnConfig<T>(
  def: ColumnConfig<T>,
): def is BaseColumnConfig<T> {
  return (def as NestableColumnConfig<T>).children === undefined
}

const getColWidths = weakMemo(function _getColWidths<T>(
  cols: BaseColumnConfig<T>[],
  columnsMap: Record<any, BaseColumnConfig<T>>,
) {
  return cols.reduce((s, col) => {
    const id = getColId(col)
    // @ts-expect-error obsolete code
    return s + columnsMap[id].width
  }, 0)
})

const getLevels = weakMemo(
  (
    columns: ColumnConfig<any>[],
    columnsMap: Record<any, BaseColumnConfig<any>>,
  ) => {
    const rows = []
    let next = columns.slice()
    let curDepth = 1
    const maxDepth = Math.max(...next.map(col => getDepth(col)))
    while (next.length && curDepth <= maxDepth) {
      const nextLevel = []
      const currentRow = []
      for (const col of next) {
        const depth = getDepth(col)
        const filler = maxDepth - depth >= curDepth
        const baseColumns = findBaseColumns(col)
        if (filler) {
          currentRow.push(
            getFillerCol(
              col,
              getColWidths(baseColumns, columnsMap),
              baseColumns[0].pinned,
              baseColumns.length,
              depth === 1,
            ),
          )
          nextLevel.push(col)
        } else {
          currentRow.push(
            getCorrectedCol(
              col,
              getColWidths(baseColumns, columnsMap),
              baseColumns[0].pinned,
              depth,
              baseColumns.length,
            ),
          )
          if (isNestableColumnConfig<any>(col)) {
            nextLevel.push(...col.children)
          }
        }
      }
      rows.push(currentRow)
      next = nextLevel
      curDepth += 1
    }
    return rows
  },
)

const getFillerCol = weakMemo((col, width, pinned, span, isBase) => {
  return {
    __filler: true,
    headerName: '',
    width,
    pinned,
    span,
    isBase,
    resizable: false,
    draggable: false,
  }
})
const getCorrectedCol = weakMemo(
  (col: ColumnConfig<any>, width, pinned, depth, span) => {
    return {
      ...col,
      id: getColId(col),
      colId: getColId(col),
      width,
      pinned,
      isBase: depth === 1,
      span,
      // @ts-expect-error obsolete code
      resizable: depth === 1 && col.resizable,
    }
  },
)

const getDepth = weakMemo((column: ColumnConfig<any>) => {
  let depth = 0
  let next = [column]
  while (next.length) {
    depth += 1
    next = getNextLevel(next)
  }
  return depth
})

const getNextLevel = weakMemo((columns: ColumnConfig<any>[]) => {
  const nextLevel = []
  for (const col of columns) {
    if (isNestableColumnConfig<any>(col)) {
      nextLevel.push(...col.children)
    }
  }
  return nextLevel
})

const findBaseColumns = weakMemo(
  (column: ColumnConfig<any>): BaseColumnConfig<any>[] => {
    if (isNestableColumnConfig<any>(column)) {
      return flatten(column.children.map(findBaseColumns))
    } else {
      return [column]
    }
  },
)

function getColId(c: BaseColumnConfig<any>) {
  // @ts-expect-error obsolete code
  return c.colId || c.id || c.field || c.headerName || c.key
}

const reduceTotalHeight = weakMemo((getRowHeight, rows): number => {
  return (rows || []).reduce((s, row, i) => s + getRowHeight(row, i), 0)
})

const getFakeHeaderRow = weakMemo(() => {
  return { __headerRow: true }
})
const FakeHeaderRowData = [{ __headerRow: true }]

const getLeftColumns = weakMemo(columns => {
  return columns.filter(c => c.pinned === 'left' && !c.hide)
})
const getRightColumns = weakMemo(columns => {
  return columns.filter(c => c.pinned === 'right' && !c.hide)
})
const getUnpinnedColumns = weakMemo(columns => {
  return columns.filter(c => !c.pinned && !c.hide)
})

function useWithHScrollListener(div, fn) {
  const lastXRef = useRef(0)
  useListener(
    div,
    'scroll',
    useCallback(() => {
      if (lastXRef.current !== div.scrollLeft) {
        lastXRef.current = div.scrollLeft
        fn()
      }
    }, [div]),
  )
}

export type GridApi = ReturnType<typeof useGridTable>['api']
