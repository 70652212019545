import React, { useCallback, useRef } from 'react'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { batchedFunction } from 'utils/batchedFunction'
import emptyFunction from 'utils/emptyFunction'
import { emptyWindowSelection } from 'utils/emptyWindowSelection'
import { queryThenMutateDOM } from 'utils/queryThenMutateDOM'

type MoveHandler = (arg: { x: number; y: number }) => void

export const useSeperator = (
  onSeperatorMoved?: MoveHandler,
  onSeperatorMoveStart?: MoveHandler,
  onSeperatorMoveEnd?: MoveHandler,
) => {
  const { onMouseDown, onMouseUp } = useDragListener(
    onSeperatorMoveStart,
    onSeperatorMoved,
    onSeperatorMoveEnd,
  )
  return (
    <div
      className="use-seperator"
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    />
  )
}

export const useDragListener = (
  onMoveStart?: MoveHandler,
  onMoved?: MoveHandler,
  onMoveEnd?: MoveHandler,
) => {
  const onSeperatorMovedRef = useUpdatingRef(onMoved || emptyFunction)
  const onSeperatorMoveStartRef = useUpdatingRef(onMoveStart || emptyFunction)
  const onSeperatorMoveEndRef = useUpdatingRef(onMoveEnd || emptyFunction)

  const mousemoveXRef = useRef(0)
  const mousemoveYRef = useRef(0)
  const isResizingRef = useRef(false)

  const storeMousePosition = useCallback(e => {
    e.preventDefault()
    mousemoveXRef.current = e.clientX
    mousemoveYRef.current = e.clientY
  }, [])

  const mousemoveListener = useCallback(
    batchedFunction(storeMousePosition, release => {
      queryThenMutateDOM(() => {
        release()
        emptyWindowSelection()
        if (!isResizingRef.current) {
          return
        }
        onSeperatorMovedRef.current({
          x: mousemoveXRef.current + window.scrollX,
          y: mousemoveYRef.current + window.scrollY,
        })
      }, null)
    }),
    [],
  )

  const stopResizingListener = useCallback(ev => {
    isResizingRef.current = false
    onSeperatorMoveEndRef.current({
      x: ev.clientX + window.scrollX,
      y: ev.clientY + window.scrollY,
    })
    document.removeEventListener('mousemove', mousemoveListener, CAPTURE)
    document.removeEventListener('mouseup', stopResizingListener, CAPTURE)
    document.removeEventListener('contextmenu', stopResizingListener, CAPTURE)
  }, [])

  const onSeperatorMouseDown = useCallback(ev => {
    isResizingRef.current = true
    onSeperatorMoveStartRef.current({
      x: ev.clientX + window.scrollX,
      y: ev.clientY + window.scrollY,
    })
    document.addEventListener('mousemove', mousemoveListener, CAPTURE)
    document.addEventListener('mouseup', stopResizingListener, CAPTURE)
    document.addEventListener('contextmenu', stopResizingListener, CAPTURE)
  }, [])

  return { onMouseDown: onSeperatorMouseDown, onMouseUp: stopResizingListener }
}

const CAPTURE = { capture: true }
