import { useEffectNow } from 'hooks/useEffectNow'

export const useInterval = (
  intervalFn: (() => void) | ((...args: unknown[]) => unknown),
  time: number,
  deps: unknown[] = [],
  onDepsChange?: VoidFunction,
  tickImmediately = true,
) => {
  let clearIntervalFn
  let cancelled = false

  useEffectNow(() => {
    if (cancelled) {
      return
    }
    let lastRet
    const intervalId = setInterval(() => {
      lastRet = intervalFn()
    }, time)
    if (tickImmediately) {
      lastRet = intervalFn()
    }
    clearIntervalFn = () => {
      if (lastRet && lastRet instanceof Function) {
        lastRet()
      }
      clearInterval(intervalId)
    }
    return () => {
      clearIntervalFn()
      onDepsChange && onDepsChange()
    }
  }, deps)

  return {
    cancel() {
      clearIntervalFn && clearIntervalFn()
      cancelled = true
    },
  }
}
