import { FC, memo, useMemo } from 'react'
import { Dropdown, Menu } from 'semantic-ui-react'
import Link from 'components/Link'

type Props = {
  activePage: string
}

type PageConfig = [`${Uppercase<string>}${string}`, `/${string}`]

const pages = [
  ['Counterparties & Users', '/users'],
  ['Deposit Addresses', '/deposit_address'],
  ['Fireblocks Networks', '/fireblocks_networks'],
  ['Finance', '/finance'],
  ['Reporting', '/reporting'],
] as const satisfies PageConfig[]

const Settings: FC<Props> = ({ activePage }) => {
  const usersAndTradeSearch = useMemo(() => {
    return pages.map(([name, url]) => {
      const active = activePage === url
      return (
        <Menu.Item key={`nav-${url}`} as={Link} href={url} active={active}>
          {name}
        </Menu.Item>
      )
    })
  }, [activePage])
  return (
    <Dropdown item text="Settings">
      <Dropdown.Menu>{usersAndTradeSearch}</Dropdown.Menu>
    </Dropdown>
  )
}

const SettingsMemo = memo(Settings)
export { SettingsMemo as Settings }
