import cx from 'classnames'
import differenceInMinutes from 'date-fns/difference_in_minutes'
import {
  currencyFormatter as _currencyFormatter,
  dateFormatter,
  formatNumber0,
  formatNumberNoMin,
} from 'components/Galaxy/GalaxyGridHelper'
import { weakMemo } from 'hooks/useWeakMapMemoCallback'
import {
  FRESHNESS_MAX_MINS_ERROR,
  FRESHNESS_MAX_MINS_WARNING,
} from 'lib/live-risk/constants'
import { formatNumberMinMax } from 'utils/formatNumber'
import {
  dateFilter,
  numberFilter,
  setFilter,
  stringFilter,
} from './GridTableFilter'
import { dateSorter, numberSorter, stringSorter } from './useGridTableSorter'

import Styles from './Styles.module.scss'

export const GETTERS = {
  absValue: ({ value }) => Math.abs(value),
}
export const FORMATTERS = {
  number: formatNumberNoMin,
  USD: ({ value }) => {
    if (value === 0) {
      return '$0'
    }
    if (value === null || isNaN(value)) {
      return '-'
    }
    return _currencyFormatter({ value }, 2)
  },
  usdInteger: ({ value }) => {
    if (value === null || isNaN(value)) {
      return '-'
    }

    // Note that _currencyFormatter does not round
    const valueInt = Math.round(value)
    return _currencyFormatter({ value: valueInt }, 0)
  },
  usdPrice: ({ value }) => {
    if (value === null || isNaN(value)) {
      return '-'
    }
    let precision = 0
    if (value === 0) {
      precision = 0
    } else if (Math.abs(value) < 1e-4) {
      precision = 8
    } else if (Math.abs(value) < 1) {
      precision = 4
    } else if (Math.abs(value) < 1000) {
      precision = 2
    }
    return _currencyFormatter({ value }, precision)
  },
  percent: ({ value }) => {
    return value !== null && value !== undefined
      ? formatNumberMinMax(value * 100, 0, 1) + '%'
      : null
  },
  date: dateFormatter,
  booleanYesNoNA: ({ value }) => {
    if (value === null || value === undefined) return 'N/A'
    if (!value) return 'No'
    if (value) return 'Yes'
  },
  boolean: ({ value }) => {
    return value ? 'Yes' : 'No'
  },
}

export const CLASSES = {
  coloredNumber: (_1, _2, value) => {
    return cx({
      [Styles.red]: value < 0,
      [Styles.green]: value > 0,
    })
  },
  coloredNumberFactory: (
    redClass,
    greenClass,
    neutralClass,
    neutralMaxVal = 0,
  ) => {
    return (_1, _2, value) => {
      return cx({
        [redClass]: value <= -neutralMaxVal,
        [greenClass]: value >= neutralMaxVal,
        [neutralClass]: value > -neutralMaxVal && value < neutralMaxVal,
      })
    }
  },
}
export const STYLES = {
  number: { textAlign: 'right', justifySelf: 'right' },
}

export const CONFIGS = {
  usd: {
    filter: numberFilter,
    sortAsc: numberSorter,
    getSortValue: GETTERS.absValue,
    cellStyle: STYLES.number,
    valueFormatter: FORMATTERS.USD,
  },
  coloredUSD: {
    filter: numberFilter,
    sortAsc: numberSorter,
    getSortValue: GETTERS.absValue,
    cellStyle: STYLES.number,
    valueFormatter: FORMATTERS.USD,
    cellClass: CLASSES.coloredNumber,
  },
  coloredUSDFactory: (redClass, greenClass, neutralClass) => {
    return {
      filter: numberFilter,
      sortAsc: numberSorter,
      getSortValue: GETTERS.absValue,
      cellStyle: STYLES.number,
      valueFormatter: FORMATTERS.usdInteger,
      cellClass: CLASSES.coloredNumberFactory(
        redClass,
        greenClass,
        neutralClass,
        1,
      ),
    }
  },
  usdPrice: {
    filter: numberFilter,
    sortAsc: numberSorter,
    getSortValue: GETTERS.absValue,
    cellStyle: STYLES.number,
    valueFormatter: FORMATTERS.usdPrice,
  },
  balance: {
    filter: numberFilter,
    sortAsc: numberSorter,
    getSortValue: GETTERS.absValue,
    cellStyle: STYLES.number,
    valueFormatter: formatNumberNoMin,
  },
  coloredBalance: {
    filter: numberFilter,
    sortAsc: numberSorter,
    getSortValue: GETTERS.absValue,
    cellStyle: STYLES.number,
    cellClass: CLASSES.coloredNumber,
    valueFormatter: formatNumber0,
  },
  coloredBalanceFactory: (redClass, greenClass, neutralClass) => {
    return {
      filter: numberFilter,
      sortAsc: numberSorter,
      getSortValue: GETTERS.absValue,
      cellStyle: STYLES.number,
      cellClass: CLASSES.coloredNumberFactory(
        redClass,
        greenClass,
        neutralClass,
      ),
      valueFormatter: formatNumber0,
    }
  },
  date: {
    valueFormatter: FORMATTERS.date,
    filter: dateFilter,
    sortAsc: dateSorter,
  },
  dateWithStaleIndicator: {
    valueFormatter: FORMATTERS.date,
    filter: dateFilter,
    sortAsc: dateSorter,
    cellClass: ({ asOf }) => {
      const deltaMins = differenceInMinutes(Date.now(), asOf)
      if (deltaMins >= FRESHNESS_MAX_MINS_ERROR) {
        return Styles.errorHighlight
      }
      if (deltaMins > FRESHNESS_MAX_MINS_WARNING) {
        return Styles.warningHighlight
      }
      return ''
    },
  },
  stringSet: {
    filter: setFilter,
    sortAsc: stringSorter,
  },
  stringSetWithValues: values => {
    return {
      filter: {
        ...setFilter,
        allValues: values,
      },
      sortAsc: stringSorter,
    }
  },
  string: {
    filter: stringFilter,
    sortAsc: stringSorter,
  },
  number: {
    filter: numberFilter,
    sortAsc: numberSorter,
  },
  percent: {
    filter: numberFilter,
    sortAsc: numberSorter,
    valueFormatter: FORMATTERS.percent,
    getFilterValue: ({ row, col, getCellValue }) => {
      const cellVal = getCellValue(col, row) * 100
      return cellVal !== null && cellVal !== undefined ? cellVal : null
    },
  },
  booleanYesNoNA: {
    filter: stringFilter,
    sortAsc: stringSorter,
    valueFormatter: FORMATTERS.booleanYesNoNA,
  },
  boolean: {
    filter: stringFilter,
    sortAsc: stringSorter,
    valueFormatter: FORMATTERS.boolean,
  },
}

const getFieldValue = weakMemo((rowData, field) => {
  let ret = null
  ;(field || '').split('.').forEach(part => {
    ret = (ret || rowData)[part]
  })
  return ret
})

const _getCellValue = weakMemo((col, row) => {
  let val = getFieldValue(row, col.field)
  if (val === undefined) {
    val = row[col.field]
  }
  if (row.__headerRow) {
    return { formatted: val, value: val }
  }
  if (col.valueGetter) {
    val = col.valueGetter({ value: val, row, col, colDef: col, data: row })
  }
  const oVal = val
  if (col.valueFormatter) {
    val = col.valueFormatter({
      value: val,
      row,
      col,
      colDef: col,
      data: row,
    } as any)
  } else if (col.format) {
    val = col.format({ value: val, row, col, colDef: col, data: row } as any)
  }
  return { formatted: val, value: oVal === undefined ? val : oVal }
})

export const getCellValueFormatted = (col, row) =>
  _getCellValue(col, row).formatted

export const getCellValue = (col, row) => _getCellValue(col, row).value
