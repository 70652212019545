import { scientificToDecimal } from 'utils/formatNumber'

export const NUMBER_REGEX = /(?=.)^-?(\d{1,3}(,?\d{3})*?)?(\.\d+)?$/
const REG_NUM_REGEX = /^-?\d$/

export default (inputValue: any): number => {
  if (
    inputValue &&
    inputValue.length > 1 &&
    inputValue[inputValue.length - 1] === '.'
  ) {
    inputValue = inputValue.slice(0, inputValue.length - 1)
  }
  const value = scientificToDecimal((inputValue || '').toString())
  if (value.match(NUMBER_REGEX)) {
    return getUnformattedNumber(inputValue)
  }
  return REG_NUM_REGEX.test(value) ? parseFloat(value) : 0
}

export function getNumberUnroundedWithSymbols(value: string): number {
  if (value.includes('K')) {
    return parseFloat(value.split(' ')[0]) * 1e3
  } else if (value.includes('MM')) {
    return parseFloat(value.split(' ')[0]) * 1e6
  } else if (value.includes('B')) {
    return parseFloat(value.split(' ')[0]) * 1e9
  } else {
    return parseFloat(value)
  }
}

export function getUnformattedNumber(value: string | number): number {
  return (
    parseFloat((value == null ? 0 : value).toString().replace(/,/g, '')) || 0
  )
}
