export const uniqueID = (() => {
  let counter = 0
  return () => 'uuid_' + counter++
})()

export const uniqueNamespace = () => {
  const keys = {}
  return (key: string) => {
    if (keys[key]) {
      keys[key] += 1
    } else {
      keys[key] = 1
    }
    return key + '_' + keys[key]
  }
}
