import { useLayoutEffect, useReducer } from 'react'
import { usePrevious } from 'hooks/usePrevious'
import { isBrowser } from 'utils/isBrowser'

export const useIsFirstRender = (forceSecondRender = false) => {
  const [, forceRender] = useReducer(s => s + 1, 0)
  const isNotFirstRender = usePrevious(true)
  const shouldForceRender = forceSecondRender && !isNotFirstRender.current
  const prev = !isNotFirstRender.current

  if (isBrowser) {
    useLayoutEffect(() => {
      if (shouldForceRender) {
        forceRender()
      }
      return () => {
        isNotFirstRender.current = false
      }
    })
  }
  return prev
}
