import React, { useCallback, useMemo, useRef } from 'react'
import { produce } from 'immer'
import { Input } from 'semantic-ui-react'
import { FilterComponent } from 'components/GridTable/useGridTable'
import { useEffectNow } from 'hooks/useEffectNow'
import { useStateRef } from 'hooks/useStateRef'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { emptyObject } from 'utils/emptyObject'
import { FILTER_OP } from '../constants'
import { GridTableColumnFilterBase } from './GridTableColumnFilterBase'

const Component = React.memo(({ allValues, setFilter, filterArgs }: any) => {
  const allValuesSet = useMemo(
    () => Array.from(new Set(allValues)).sort(),
    [allValues],
  ) as string[]

  const [filterString, updateFilterString] = useStateRef('')

  const uncheckedRef = useUpdatingRef(
    useMemo(() => filterArgs || emptyObject, [filterArgs]),
  )

  const toggleLabel = useCallback(
    label => {
      const newFilterArgs = produce(uncheckedRef.current, draft => {
        allValuesSet.forEach(value => {
          if (label === value) {
            draft[value] = !draft[value]
          } else {
            draft[value] = draft[value] ?? false
          }
        })
      })
      setFilter(newFilterArgs)
    },
    [allValuesSet, setFilter, uncheckedRef],
  )
  const allChecked = useRef(true)

  useEffectNow(() => {
    if (!allChecked.current) {
      const newFilterArgs = produce(uncheckedRef.current, draft => {
        allValuesSet.forEach(value => {
          draft[value] = draft[value] == undefined ? true : draft[value]
        })
      })
      setFilter(newFilterArgs)
    }
  }, [allValuesSet])

  const options = useMemo(() => {
    allChecked.current = allValuesSet.every(val => !uncheckedRef.current[val])
    const s = filterString.current

    try {
      return allValuesSet
        .filter(
          label =>
            label != undefined && label.toLowerCase().includes(s.toLowerCase()),
        )
        .map(val => {
          return (
            <SetFilterOption
              checked={!uncheckedRef.current[val]}
              label={val}
              key={`filter-option-${val}`}
              toggle={toggleLabel}
            />
          )
        })
    } catch (e) {
      return []
    }
  }, [allValuesSet, uncheckedRef.current, filterString.current, toggleLabel])

  const toggleAll = useCallback(() => {
    const unchecked = {}
    if (allChecked.current) {
      allValues.forEach(val => {
        unchecked[val] = true
      })
    }
    setFilter(unchecked)
  }, [allValues, setFilter])

  const onChange = useCallback(
    e => updateFilterString(e.target.value),
    [updateFilterString],
  )
  const menu = useMemo(
    () => (
      <div>
        <Input onChange={onChange} value={filterString.current} />
        <SetFilterOption
          checked={allChecked.current}
          label={<div>Toggle All</div>}
          toggle={toggleAll}
        />
        <hr />
        {options}
      </div>
    ),
    [onChange, filterString, toggleAll, options],
  )
  return (
    <GridTableColumnFilterBase
      menu={menu}
      className={'grid-table-set-column-filter'}
    />
  )
})

function filterFn(filterArgs, value) {
  return !!filterArgs[value]
}

const SetFilterOption = ({ checked, label, toggle }) => {
  const onChange = useCallback(() => {
    toggle(label)
  }, [label, toggle])
  return (
    <div className="grid-table-set-filter">
      <div>
        <label>
          <input type="checkbox" onChange={onChange} checked={checked} />
          <span>{label == null ? '<None>' : label}</span>
        </label>
      </div>
    </div>
  )
}

const toServerModelFn =
  (inverse = false) =>
  filterArgs => {
    return {
      op: 'and',
      conditions: (() => {
        const conditions = []
        Object.keys(filterArgs).forEach(key => {
          if (filterArgs[key]) {
            conditions.push({
              val: key,
              op: inverse ? FILTER_OP.equals : FILTER_OP.notEqual,
            })
          }
        })
        return conditions
      })(),
    }
  }

Component.displayName = 'GridTableSetColumnFilter'

export const GridTableSetColumnFilter: FilterComponent = {
  Klass: Component,
  filterFn,
  needsAllValues: true,
  needsFormatted: true,
  toServerModel: toServerModelFn(),
}

export const GridTableSetInverseColumnFilter: FilterComponent = {
  ...GridTableSetColumnFilter,
  toServerModel: toServerModelFn(true),
}
