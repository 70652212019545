import moment from 'moment-timezone'

export const getTimezone = (date: Date): string => {
  const tzFullName = Intl.DateTimeFormat().resolvedOptions().timeZone
  const tz = moment(date).tz(tzFullName).format('z')
  // date.getTimezoneOffset returns reverse offset
  const offset = date.getTimezoneOffset() / -60
  const sign = offset >= 0 ? '+' : '-'

  return `${tz} (UTC${sign}${Math.abs(offset)})`
}
