import React from 'react'
import App, { AppProps } from 'next/app'
import sentry from 'utils/sentry'
import GalaxyAppBase from './components/GalaxyAppBase'
import { GalaxyAppError } from './components/GalaxyAppError'

const { captureException, Sentry } = sentry()

type AppMergedProps = AppProps & AppExtraProps

type AppExtraProps = {
  appHeader: React.ReactElement
  className?: string
}

type AppState = {
  hasError: boolean
  errorEventId: string
}

export class GalaxyApp extends App<AppExtraProps, object, AppState> {
  constructor(props: AppMergedProps) {
    super(props)
    this.state = {
      hasError: false,
      errorEventId: undefined,
    }

    this.onPageRender = this.onPageRender.bind(this)
  }

  static getDerivedStateFromProps(
    props: AppMergedProps,
    state: AppState,
  ): Partial<AppState> | null {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: state.hasError || false,
      errorEventId: state.errorEventId || undefined,
    }
  }

  static getDerivedStateFromError(error: unknown): Partial<AppState> | null {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: !!error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const errorEventId = captureException(error, { errorInfo })

    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId })
  }

  private onPageRender(): React.ReactElement {
    return <this.props.Component {...this.props.pageProps} />
  }

  private onReloadAfterException(): void {
    window.location.reload()
  }

  render() {
    if (this.state.hasError) {
      const { errorEventId } = this.state
      return (
        <GalaxyAppError
          onErrorReport={() =>
            Sentry.showReportDialog({ eventId: errorEventId })
          }
          onReload={this.onReloadAfterException}
        />
      )
    }

    return (
      <GalaxyAppBase
        className={this.props.className}
        appHeader={this.props.appHeader}
        onPageRender={this.onPageRender}
      />
    )
  }
}
