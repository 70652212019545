import { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { Image, Menu } from 'semantic-ui-react'
import useAuthUser from 'hooks/useAuthUser'
import { usePrevious } from 'hooks/usePrevious'
import { selectOktaToken } from 'redux/selectors/selectOktaToken'
import { login, logout } from 'utils/auth'
import CSSCore from 'utils/CSSCore'
import { getEmptyArray } from 'utils/emptyArray'
import galaxyLogo from '../ClientGalaxyNavigation/clientNavAssets/galaxyLogo.svg'
import { Applications } from './components/Applications'
import { Settings } from './components/Settings'
import { StrategiesDropdown } from './components/StrategiesDropdown'
import { VersionTag } from './components/VersionTag'

import Styles from './GalaxyNavigation.module.scss'

const GalaxyNavigation: FC = () => {
  const {
    strategies = getEmptyArray(),
    selectedStrategy,
    firstName,
    lastName,
  } = useAuthUser('internal')
  const user = firstName ? `${firstName} ${lastName}` : null

  const loggedIntoOkta = Boolean(useSelector(selectOktaToken))

  const activePage = useRouter().pathname.toLowerCase()
  const previousActiveItem = usePrevious(activePage).current

  applyBodyClass(activePage, previousActiveItem)

  return (
    <nav className={Styles.navbar} data-navbar>
      <Menu className={Styles.navMenu} inverted fluid>
        <Menu.Menu position="left">
          <Menu.Item className={Styles.logo}>
            <Image size="small" src={galaxyLogo} />
            <VersionTag />
          </Menu.Item>
          <Menu.Menu position="left" className={Styles.scrolling}>
            <Applications strategy={selectedStrategy} activePage={activePage} />
          </Menu.Menu>
        </Menu.Menu>
        <Menu.Menu position="right">
          <Settings activePage={activePage} />
          <StrategiesDropdown
            strategies={strategies}
            selectedStrategy={selectedStrategy}
            activePage={activePage}
          />
          {user && <Menu.Item>{user}</Menu.Item>}
          <Menu.Item name="logout" onClick={loggedIntoOkta ? logout : login}>
            Log {loggedIntoOkta ? 'out' : 'in'}
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </nav>
  )
}

function applyBodyClass(activeItem: string, previousActiveItem?: string): void {
  if (activeItem !== previousActiveItem) {
    if (previousActiveItem) {
      const prevClass = `_${
        previousActiveItem.replace(/\d/g, '').split('/')[1]
      }`
      CSSCore.removeClass(document.body, prevClass)
    }
    const nextClass = `_${activeItem.replace(/\d/g, '').split('/')[1]}`
    CSSCore.addClass(document.body, nextClass)
  }
}

const GalaxyNavigationMemo = memo(GalaxyNavigation)
export default GalaxyNavigationMemo
