import { produce } from 'immer'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/GridTables'
import { GridTablesModel } from 'redux/models'
import { registerSaver } from 'redux/store/utils'
import { emptyObject } from 'utils/emptyObject'
import { REDUX_INITIAL_SET } from '../store/localStorage'
import { RootState } from './state'

const initialState: GridTablesModel = {
  __version: {},
}

registerSaver((state: RootState) => {
  return {
    GridTables: state.GridTables,
  }
})

export const GridTablesReducer = handleActions<GridTablesModel, any>(
  {
    [REDUX_INITIAL_SET]: (state: GridTablesModel, { payload }) => {
      return produce(state, draft => {
        const persistedState = payload.GridTables
        Object.keys(persistedState || emptyObject).forEach(key => {
          if (key.startsWith('LiveRisk-')) {
            let columns = persistedState[key] as GridTablesModel['LiveRisk']
            if (!Array.isArray(columns)) {
              return
            }
            // dedupe
            columns = columns.filter(column => column.id)
            const hiddenIndex = columns.findIndex(c => {
              return c && c.id === 'Hidden'
            })
            if (hiddenIndex === -1) {
              return
            }
            const hidden = columns[hiddenIndex]
            hidden.width = 105
            columns.splice(hiddenIndex, 1)
            columns.push(hidden)
          }
        })
        if (persistedState) {
          Object.assign(draft, persistedState)
        }
      })
    },
    [Type.SET_COLUMN_DEFS]: (state: GridTablesModel, { payload }) => {
      return produce(state, draft => {
        const id = payload.id
        if (state[id]) {
          const newSortMap = {}
          payload.defs.forEach((def, index) => {
            newSortMap[def.colId] = index
            const existingIndex = state[id].findIndex(
              pdef => pdef.colId === def.colId,
            )
            if (existingIndex === -1) {
              draft[id].push(def)
            } else {
              draft[id][existingIndex].width = def.width
            }
          })
          draft[id] = draft[id]
            .filter(col => col.colId)
            .filter((col, index, arr) => {
              return arr.findIndex(colb => colb.colId === col.colId) === index
            })
            .sort((a, b) => {
              const aNotNull = newSortMap[a.colId] !== null
              const bNotNull = newSortMap[b.colId] !== null
              if (aNotNull && bNotNull) {
                return newSortMap[a.colId] - newSortMap[b.colId]
              } else if (aNotNull) {
                return -1
              } else if (bNotNull) {
                return 1
              }
              return 0
            })
        } else {
          draft[id] = payload.defs
        }
        draft.__version[id] = payload.version
      })
    },
  },
  initialState,
)
