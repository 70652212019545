import { DependencyList, MutableRefObject, useCallback, useMemo } from 'react'
import memoizee from 'memoizee'
import memoizeeWeak from 'memoizee/weak'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { emptyArray } from 'utils/emptyArray'
import { shallowEqual } from 'utils/shallowEqual'

type AnyFn = (...args: any[]) => any

export const useWeakMapMemoCallback = <F extends AnyFn>(
  getter: F,
  deps: DependencyList = emptyArray,
): F => {
  return useCallback(memoizeeWeak(getter), deps)
}

// The Object arguments are expected first and together are the *keys* for the memoization
// The primitve arguments are expected last and together are the *dependencies* of the memoization
export const useWeakMapMemo2 = <F extends AnyFn>(
  getter: F,
  deps: DependencyList = emptyArray,
): F => {
  const getterRef = useUpdatingRef(getter)

  return useMemo(() => {
    return weakMemo(getterRef)
  }, deps)
}

export const weakMemo = <F extends AnyFn>(
  getterRef: F | MutableRefObject<F>,
): F => {
  const argumentsMap = new WeakMap()
  const valueMap = new WeakMap()

  // @ts-expect-error obsolete code
  return (...args) => {
    try {
      let didDependenciesChange = true
      let innerMap = argumentsMap

      // Step 1: Split arguments into Objects and Primitives (We expect Objects first)
      let objsLen = 0
      for (const arg of args) {
        if (typeof arg === 'object' && arg !== null) {
          objsLen += 1
        } else {
          break
        }
      }
      const lenObj = getLenObj(objsLen)

      for (let i = 0; i < objsLen; i++) {
        const prevArg = i === 0 ? lenObj : args[i - 1]
        if (!innerMap.has(prevArg)) {
          const nextMap = new WeakMap()
          innerMap.set(prevArg, nextMap)
          innerMap = nextMap
        } else {
          innerMap = innerMap.get(prevArg)
        }
      }

      const keys = args.slice(0, objsLen)
      const dependencies = args.slice(objsLen)
      keys.unshift(lenObj)

      // Step 2: check if the deoendencies have changed or if this is a new entry
      const innerKey = keys[keys.length - 1]
      if (innerMap.has(innerKey)) {
        if (shallowEqual(innerMap.get(innerKey), dependencies)) {
          didDependenciesChange = false
        } else {
          innerMap.set(innerKey, dependencies)
        }
      } else {
        innerMap.set(innerKey, dependencies)
      }

      // Step 3: Call the getter if the dependencies have changed and record the value
      if (didDependenciesChange) {
        let valMap = valueMap
        keys.forEach((key, index, arr) => {
          if (index === arr.length - 1) {
            return
          }
          if (!valMap.has(key)) {
            valMap.set(key, new WeakMap())
          }
          valMap = valMap.get(key)
        })

        const value =
          getterRef instanceof Function
            ? getterRef(...args)
            : getterRef.current(...args)
        const lastKey = keys[keys.length - 1]
        valMap.set(lastKey, value)
      }

      // Retrieve the value
      let value = valueMap
      keys.forEach(obj => {
        value = value.get(obj)
      })
      return value
    } catch (e) {
      console.error(e)
    }
  }
}

const getLenObj = memoizee(len => {
  return { len }
})
