import deepEqual from 'react-fast-compare'
import { produce } from 'immer'
import memoizee from 'memoizee'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/monitor'
import { MonitorModel } from 'redux/models'
import { registerSaver } from 'redux/store/utils'
import { getNumberUnroundedWithSymbols } from 'utils/getNumberFromInput'
import { REDUX_INITIAL_SET } from '../store/localStorage'

const initialState: MonitorModel = {
  isLoading: true,
  exchangeInstrumentData: {},
  currencies: [],
  currencyData: {},
  workersByInstExch: {},
  workerData: {},
  fills: [],
  settings: {
    uncollapsedWorkerInsts: {},
    uncollapsedExchangesInfo: {},
  },
}

registerSaver(state => {
  return {
    Monitor: {
      settings: state.Monitor.settings,
    },
  }
})

export const MonitorReducer = handleActions<MonitorModel, any>(
  {
    [REDUX_INITIAL_SET]: (state: MonitorModel, { payload }) => {
      return produce(state, draft => {
        Object.assign(draft, payload.Monitor || {})
      })
    },
    [Type.SET_DATA]: (state: MonitorModel, { payload }) => {
      const nextState = { ...state }
      const d = cleanData(payload)
      Object.keys(d).forEach(key => {
        if (
          key == 'fills' ||
          !deepEqual(JSON.parse(JSON.stringify(nextState[key])), d[key])
        ) {
          nextState[key] = d[key]
        }
      })
      return nextState
    },
    [Type.TOGGLE_ROW]: (state: MonitorModel, { payload }) => {
      return produce(state, draft => {
        draft.settings[payload.field][payload.id] =
          !draft.settings[payload.field][payload.id]
      })
    },
  },
  initialState,
)

function cleanData(payload) {
  const clean: Record<PropertyKey, unknown> = {}
  Object.assign(clean, cleanCurrencies(payload.currencies.rows))
  Object.assign(clean, cleanWorkers(payload.workers.rows))
  Object.assign(clean, cleanExchanges(payload.exchanges.rows))
  clean.fills = payload.fills.map(fill =>
    getEvent(
      fill.type,
      fill.ts,
      fill.inst || fill.curName,
      fill.exch,
      fill.p,
      fill.qty || fill.q || fill.inv || fill.curBase,
      fill.liq,
      fill.oid,
      fill.fee,
      fill.pnl,
    ),
  )
  clean.isLoading = false
  return clean
}

function cleanExchanges(exchangesData) {
  const exchanges = {}
  let currentExch
  exchangesData.forEach(data => {
    if ((data[0] || '').includes('class="rowHead')) {
      if (currentExch) {
        exchanges[currentExch.exchange] = currentExch
      }
      const [status, , exchange, , , inv$, , trd$, f, ord] = data[1]
      currentExch = {
        exchange,
        status,
        currencies: [],
        inv$,
        trd$,
        f,
        ord,
      }
    } else {
      const [, , , currency, [, inv], [, inv$], trd, trd$, f, ord] = data
      currentExch.currencies.push({
        currency,
        inv: getNumberUnroundedWithSymbols(inv),
        inv$: getNumberUnroundedWithSymbols(inv$),
        trd,
        trd$,
        f,
        ord,
      })
    }
  })
  if (currentExch) {
    exchanges[currentExch.exchange] = currentExch
  }
  return { exchangeInstrumentData: exchanges }
}

function cleanCurrencies(dirtyCurrencies) {
  const currencies = []
  const currencyData = {}
  dirtyCurrencies.forEach(row => {
    const currency = row[0]
    currencies.push(currency)
    currencyData[currency] = {
      currency,
      markPrice: row[1],
      dataQ: row[2],
      delta: row[3],
      delta$: row[4],
      ccbase: row[5],
      maxPos: row[6],
      maxPos$: row[7],
      inv: row[8],
      inv$: row[9],
    }
  })
  return {
    currencies,
    currencyData,
  }
}

function cleanWorkers(dirtyWorkers) {
  const workersByInstExch = {}
  const workerData = {}

  dirtyWorkers.forEach(worker => {
    if (worker === '_separator') {
      return
    }
    const [stopped, exch, inst, name, ads, , , , , , , , maxQty] = worker
    const key = j(exch, inst, name)
    workersByInstExch[inst] = workersByInstExch[inst] || {}
    workersByInstExch[inst][exch] = workersByInstExch[inst][exch] || []
    workersByInstExch[inst][exch].push(key)

    workerData[key] = {
      stopped,
      exch,
      inst,
      name,
      status: [stopped ? 0 : 1, 1],
      marketAccess: ads[0][0] === 'ok',
      marketData: ads[1][0] === 'ok',
      signalServer: ads[2][0] === 'ok',
      maxQty,
    }
  })

  return {
    workersByInstExch,
    workerData,
  }
}

function j(...keys) {
  return keys.join(':')
}

const getEvent = memoizee(
  (type, ts, inst, exch, p, qty, liq, oid, fee, pnl) => ({
    type,
    ts,
    inst,
    exch,
    p,
    qty,
    liq,
    oid,
    fee,
    pnl,
  }),
)
