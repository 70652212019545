import React, { useCallback, useEffect, useMemo } from 'react'
import Datetime from 'react-datetime'
import memoizee from 'memoizee/weak'
import moment from 'moment'
import { Button, Dropdown } from 'semantic-ui-react'

import { usePrevious } from 'hooks/usePrevious'
import { useStateRef } from 'hooks/useStateRef'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { useUpdatingState } from 'hooks/useUpdatingState'
import { uniqueID } from 'utils/uniqueID'

import { FILTER_OP } from '../constants'
import { GridTableColumnFilterBase } from './GridTableColumnFilterBase'
import Styles from './GridTableDateColumnFilter.module.scss'

const timeFormat = 'h:mm A'

const Component = React.memo(({ setFilter, filterArgs }: any) => {
  const setFilterRef = useUpdatingRef(setFilter)
  const [conditionRef, setCondition] = useStateRef(
    filterArgs || FILTER_TYPES[2],
  )
  const [hasError, setHasError] = useStateRef(false)
  const prevFilterArgs = usePrevious(filterArgs).current
  if (prevFilterArgs !== filterArgs) {
    conditionRef.current = filterArgs
  }

  const submit = useCallback(() => {
    setFilterRef.current(conditionRef.current)
  }, [])
  const clear = useCallback(() => {
    const filter = { filter: [''], value: FILTER_OP.lessThan }
    setFilterRef.current(filter)
  }, [])
  const menu = useMemo(
    () => (
      <div className="grid-table-date-column-filter">
        <Condition
          {...conditionRef.current}
          setFilterVal={setCondition}
          setHasError={setHasError}
        />
        <div className="grid-table-filter-actions">
          <Button size="tiny" onClick={clear} negative content="Clear" />
          <Button
            size="tiny"
            onClick={submit}
            positive
            content="Submit"
            disabled={hasError.current}
          />
        </div>
      </div>
    ),
    [conditionRef.current, setCondition, clear, submit, hasError.current],
  )
  return useMemo(() => <GridTableColumnFilterBase menu={menu} />, [menu])
})

const Condition = React.memo(
  ({ value, filter, setFilterVal, setHasError }: any) => {
    if (value === FILTER_OP.inRange) {
      return (
        <RangeFilter
          val={value}
          filter={filter}
          setFilterVal={setFilterVal}
          setHasError={setHasError}
        />
      )
    }
    return (
      <NotRangeFilter
        val={value}
        filter={filter}
        setFilterVal={setFilterVal}
        setHasError={setHasError}
      />
    )
  },
)
const RangeFilter = React.memo((props: any) => {
  const { val, filter, setFilterVal, setHasError } = props
  const propsRef = useUpdatingRef(props)
  const onFilterTypeChange = useCallback((_, { value }) => {
    setFilterVal({
      filter: propsRef.current.filter,
      value,
    })
  }, [])
  const [startDate, setStartDate] = useUpdatingState(
    filter && filter[0] ? filter[0] : filter,
  )
  const [startDateError, setStartDateError] = useUpdatingState(false)
  const [endDateError, setEndDateError] = useUpdatingState(false)
  useEffect(() => {
    setHasError(startDateError || endDateError)
  }, [startDateError, endDateError])
  const onDateStartChange = useCallback(date => {
    setStartDate(date)
    if (moment.isMoment(date)) {
      const filter = propsRef.current.filter
      setFilterVal({
        filter: [date, filter && filter[1] ? filter[1] : null],
        value: propsRef.current.val,
      })
      setStartDateError(false)
    } else {
      setStartDateError(true)
    }
  }, [])
  const [endDate, setEndDate] = useUpdatingState(
    filter && filter[1] ? filter[1] : endOfToday,
  )
  const onDateEndChange = useCallback(date => {
    setEndDate(date)
    if (moment.isMoment(date)) {
      const filter = propsRef.current.filter
      setFilterVal({
        filter: [filter && filter[0] ? filter[0] : filter, date],
        value: propsRef.current.val,
      })
      setEndDateError(false)
    } else {
      setEndDateError(true)
    }
  }, [])

  return (
    <div>
      <h3>Date Filter</h3>
      <Dropdown
        options={FILTER_TYPES}
        value={val || FILTER_OP.lessThan}
        onChange={onFilterTypeChange}
      />
      <Datetime value={startDate} onChange={onDateStartChange} />
      <span>
        <i className="arrow right icon" />
      </span>
      <Datetime value={endDate} onChange={onDateEndChange} />
    </div>
  )
})

const NotRangeFilter = React.memo((props: any) => {
  const { val, filter, setFilterVal, setHasError } = props
  const propsRef = useUpdatingRef(props)
  const [currentVal, setCurrentVal] = useUpdatingState(
    filter && filter[0] ? filter[0] : filter,
  )
  const onFilterTypeChange = useCallback((_, { value }) => {
    setFilterVal({
      filter: propsRef.current.filter,
      value,
    })
  }, [])

  const setDateFilterModel = useCallback(
    date => {
      if (!moment.isMoment(date)) {
        setCurrentVal(date)
        setHasError(true)
        return
      }

      // Zero out seconds, if any
      const dateStartOfMinute = date.startOf('minute')

      setFilterVal({
        value: propsRef.current.val,
        filter: dateStartOfMinute,
      })
      setHasError(false)
    },
    [propsRef, setCurrentVal, setFilterVal, setHasError],
  )

  return (
    <div>
      <Dropdown
        options={FILTER_TYPES}
        value={val}
        onChange={onFilterTypeChange}
      />
      <Datetime
        displayTimeZone={null}
        value={currentVal}
        onChange={setDateFilterModel}
        timeFormat={timeFormat}
        className={Styles.datetimeInput}
      />
    </div>
  )
})

const startOf7DaysAgo = moment(
  new Date().setDate(new Date().getDate() - 7),
).startOf('day')
const endOfToday = moment(new Date().setDate(new Date().getDate())).endOf('day')
const FILTER_TYPES = [
  {
    text: 'In Range',
    value: FILTER_OP.inRange,
    key: uniqueID(),
    filter: [startOf7DaysAgo, endOfToday],
  },
  {
    text: 'Greater than',
    value: FILTER_OP.greaterThan,
    key: uniqueID(),
    filter: startOf7DaysAgo,
  },
  {
    text: 'Less than',
    value: FILTER_OP.lessThan,
    key: uniqueID(),
    filter: endOfToday,
  },
  {
    text: 'Equals',
    value: FILTER_OP.equals,
    key: uniqueID(),
    filter: endOfToday,
  },
  {
    text: 'Not equals',
    value: FILTER_OP.notEqual,
    key: uniqueID(),
    filter: endOfToday,
  },
]

const makeFilter = memoizee(condition => {
  let m
  if (condition.filter[0] == null) {
    m = condition.filter
  }
  switch (condition.value) {
    case FILTER_OP.inRange: {
      if (!condition.filter[0]) {
        return () => true
      }

      const m1 = condition.filter[0]
      const m2 = condition.filter[1] || endOfToday

      return value => {
        const mValue = moment(value)
        return mValue.isAfter(m1) && mValue.isBefore(m2)
      }
    }
    case FILTER_OP.greaterThan:
      return value => moment(value).isAfter(m)
    case FILTER_OP.lessThan:
      return value => {
        return moment(value).isBefore(m)
      }
    case FILTER_OP.equals:
      return value => moment(value).isSame(m, 'day')
    case FILTER_OP.notEqual:
      return value => !moment(value).isSame(m, 'day')
    default:
      return () => false
  }
})

function filterFn(filterArgs, value) {
  const fn = makeFilter(filterArgs)
  return !fn(value)
}

export const GridTableDateColumnFilter: import('../useGridTable').FilterComponent =
  {
    Klass: Component,
    filterFn,
    toServerModel: filterArgs => {
      const { value, filter } = filterArgs || {}
      let conditions = []
      if (filterArgs) {
        if (value === FILTER_OP.inRange) {
          const [startDate, endDate] = filter
          if (startDate) {
            const startDateISO = startDate.toDate().toISOString()
            const endDateISO = (endDate || endOfToday).toDate().toISOString()
            conditions = [
              {
                val: `${startDateISO},${endDateISO}`,
                op: FILTER_OP.inRange,
              },
            ]
          }
        } else {
          conditions = [
            {
              val: filter,
              op: value,
            },
          ]
        }
      }
      return { conditions, op: 'or' }
    },
  }
