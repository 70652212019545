import { produce } from 'immer'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/user'
import { IOktaUserState } from 'redux/models'
import { dispatch } from 'utils/dispatch'

const initialState: IOktaUserState = {
  data: undefined,
  isSigningOut: false,
}

export const OktaUserReducer = handleActions<IOktaUserState, any>(
  {
    [Type.OKTA_USER_CHANGED]: (state: IOktaUserState, { payload }) => {
      return produce(state, draft => {
        draft.data = payload
        dispatch({
          type: Type.AFTER_OKTA_USER_CHANGED,
          payload,
        })
      })
    },
    [Type.USER_SIGNING_OUT]: (state, { payload }) => {
      return produce(state, draft => {
        draft.isSigningOut = payload
      })
    },
  },
  initialState,
)
