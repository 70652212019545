import React from 'react'
import { useDispatch } from 'react-redux'
import { __setStoreDispatch } from 'utils/dispatch'

const GlobalDispatch: React.FC = () => {
  __setStoreDispatch(useDispatch())
  return null
}

export default GlobalDispatch
