import { FC, memo } from 'react'
import ClientGalaxyNavigation from 'components/Galaxy/ClientGalaxyNavigation'
import { TestEnvironmentNotice } from 'components/TestEnvironmentNotice'
import { Banner } from '../Banner'
import BannerManager from './BannerManager'

const getBanners = [TestEnvironmentNotice, Banner, ClientGalaxyNavigation]

const GetBannerManager: FC = () => {
  return (
    <BannerManager>
      {getBanners.map((BannerComponent, i) => (
        <BannerComponent key={i} />
      ))}
    </BannerManager>
  )
}

export default memo(GetBannerManager)
