import { produce } from 'immer'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/AgGridTables'
import { AgGridTablesModel } from 'redux/models'

import { RootState } from 'redux/reducers/state'
import { registerSaver } from 'redux/store/utils'
import { REDUX_INITIAL_SET } from '../store/localStorage'

const initialState: AgGridTablesModel = {
  __version: {},
}

registerSaver((state: RootState) => {
  return {
    AgGridTables: state.AgGridTables,
  }
})

export const AgGridTablesReducer = handleActions<AgGridTablesModel, any>(
  {
    [REDUX_INITIAL_SET]: (state: AgGridTablesModel, { payload }) => {
      return produce(state, draft => {
        const persistedState = payload.AgGridTables
        if (persistedState) {
          Object.assign(draft, persistedState)
        }
      })
    },
    [Type.SET_COLUMN_DEFS]: (state: AgGridTablesModel, { payload }) => {
      return produce(state, draft => {
        const id = payload.id
        draft[id] = payload.defs
        draft.__version[id] = payload.version
      })
    },
  },
  initialState,
)
