import { FC, ReactNode, useEffect } from 'react'
import { Button, Typography } from '@g1/components'
import Image from 'next/image'
import AuthScreen from '@design-library/AuthScreen'
import { singleLogout } from 'utils/auth'
import { getOktaUrl } from './getOktaUrl'

import Styles from './SystemError.module.css'

export type SystemErrorProps = {
  error?: Error
  title: string
  message: ReactNode
  showReachToSupport?: boolean
}

export const SystemError: FC<SystemErrorProps> = ({
  error,
  title,
  message,
  showReachToSupport,
}) => {
  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <AuthScreen
      title={title}
      message={message}
      moreContent={
        showReachToSupport && (
          <Typography className={Styles.Message} variant="body2">
            If you believe you have been blocked in error, please reach out to
            Client Support at{' '}
            <a href="mailto:galaxyone@galaxy.com">galaxyone@galaxy.com</a>
          </Typography>
        )
      }
      image={
        <Image
          src="/assets/lock-icon.svg"
          alt="Lock Icon"
          width={68.5}
          height={96}
        />
      }
      actions={
        <>
          <Button variant="outlined" href={getOktaUrl()}>
            Back To Okta
          </Button>
          <Button variant="outlined" onClick={singleLogout}>
            Sign Me Out
          </Button>
        </>
      }
    />
  )
}
