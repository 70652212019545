import { FC } from 'react'
import { Tooltip } from '@g1/components'
import { isProd } from 'utils/envUtils'
import logoSvg from './assets/G1-logo.svg'

import Styles from './styles.module.scss'

const commitId = process.env.COMMIT_ID

const tooltipContent: React.ReactNode = (
  <span className={Styles.tooltipContent}>
    <span>{process.env.NEXT_PUBLIC_VERSION}</span>
    <br />
    <span>{process.env.STAGING_NAME}</span>
    <br />
    {commitId && commitId !== 'undefined' && <span>{commitId}</span>}
  </span>
)

const Logo: FC = () => <img src={logoSvg} className={Styles.logo} />

export const HeaderLogo: FC = isProd()
  ? Logo
  : () => (
      <Tooltip title={tooltipContent}>
        <Logo />
      </Tooltip>
    )
