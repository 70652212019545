import { RootState } from 'redux/reducers/state'
import { isClientPortal } from 'utils/isClientPortal'
import { selectGalaxyUser } from './selectGalaxyUser'

export default (rootState: RootState) => {
  const user = selectGalaxyUser('internal')(rootState)
  if (!isClientPortal && (!user || !user.selectedStrategy)) {
    return
  }
  const selectedStrategy = (user || {}).selectedStrategy
  return selectedStrategy
}
