import { MutableRefObject, useCallback, useReducer, useRef } from 'react'
import { batchedFunction } from 'utils/batchedFunction'
import { queryThenMutateDOMXFramesFromNow } from 'utils/queryThenMutateDOM'

/**
 * useStateRef... because sometimes we want to mutate a state variable without triggering
 *  another cycle of rendering. In otherwords change the value this frame!
 *
 * Also it uses referential equality to skip renders
 */
export const useStateRef = <T>(
  initial?: T | (() => T),
): [MutableRefObject<T>, (value: T, force?: boolean) => void, () => T] => {
  const [, forceRender] = useReducer(s => s + 1, 0)

  const setInitial = useRef(false)
  let state = initial
  if (!setInitial.current) {
    setInitial.current = true
    if (initial instanceof Function) {
      state = initial()
    }
  }
  const ref = useRef<T>(state as T)
  const set = useCallback((value: T, force = false) => {
    if (ref.current !== value || force) {
      ref.current = value
      forceRender()
    }
  }, [])
  const get = useCallback((): T => ref.current, [])
  return [ref, set, get]
}

export const useBatchedStateRef = <T>(
  initial?: T | (() => T),
  frames = 0,
): [MutableRefObject<T>, (value: T, force?: boolean) => void, () => T] => {
  const [ref, set, get] = useStateRef(initial)
  const setter = useCallback(
    batchedFunction(
      (value: T) => {
        ref.current = value
      },
      release => {
        queryThenMutateDOMXFramesFromNow(
          null,
          () => {
            release()
            set(ref.current, true)
          },
          frames,
        )
      },
    ),
    [],
  )
  return [ref, setter, get]
}
