import memoizee from 'memoizee'
import { boundValue } from 'utils/boundValue'

const ONE_FORMATTER = Intl.NumberFormat('en-US', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
})

export const formatNumber = (
  precision: number,
  value: number | string,
  shouldAddTrailingZeroes = true,
) => {
  if (value == null) {
    return ''
  }
  value = value.toString()
  value = scientificToDecimal(value)
  const [integer, frac = ''] = value.split('.')
  if (precision === Infinity) {
    precision = frac.length
  }
  if (Math.abs(Number(value)) < 1 && precision >= 16) {
    return numberToScientific(value)
  }
  const formatted = ONE_FORMATTER.format(parseInt(integer)).split('.')[0]
  let formattedFrac = frac.slice(0, precision)
  if (shouldAddTrailingZeroes) {
    formattedFrac = formattedFrac.padEnd(precision, '0')
  }
  const fraction = formattedFrac.length > 0 ? `.${formattedFrac}` : ''
  return `${formatted}${fraction}`
}

export const toFixed = memoizee(
  (precision, value: number | string) => {
    value = value.toString()
    value = scientificToDecimal(value)
    const [int, frac] = value.split('.')
    if (precision === Infinity) {
      precision = (frac || '').length
    }
    const result =
      int + (frac && precision ? '.' + frac.slice(0, precision) : '')
    return result
  },
  { max: 100000 },
)

export const formatNumberMinMax = memoizee(
  (value: number, minPrecision = 8, maxPrecision?: number) => {
    const newValue =
      value != null && String(value).length ? scientificToDecimal(value) : value
    let precision: number
    const [, frac] = (newValue ?? '').toString().split('.')
    if (frac?.length) {
      precision = frac.replace(/[0]+$/g, '').length
    } else {
      precision = 0
    }
    const boundPrecision = boundValue(precision, minPrecision, maxPrecision)
    return formatNumber(boundPrecision, newValue)
  },
  { max: 100000, length: 3 },
)

const BILLION = 1000 ** 3
const MILLION = 1000 ** 2
const THOUSAND = 1000

export function formatNumberWithRoundingAndSymbols(
  value: number,
  precision = 2,
): string {
  value = Number(scientificToDecimal(Number(value)))
  const factor = 10 ** precision
  if (value >= BILLION) {
    return Math.ceil((value * factor) / BILLION) / factor + 'B'
  }
  if (value >= MILLION) {
    return Math.ceil((value * factor) / MILLION) / factor + 'M'
  }
  if (value >= THOUSAND) {
    return Math.ceil((value * factor) / THOUSAND) / factor + 'K'
  }

  return (Math.ceil(value * factor) / factor).toString()
}

const SCIENTIFIC_NOTATION_REGEX =
  /[+-]?(?:0|[1-9]\d*)(?:\.\d*)?(?:[eE][+-]?\d+)?/
export const scientificToDecimal = (number: string | number): string => {
  if (number === null || number === undefined) {
    return ''
  }

  number = number.toString()
  if (!SCIENTIFIC_NOTATION_REGEX.test(number)) {
    return number
  }
  const numberHasSign = number.startsWith('-') || number.startsWith('+')
  const sign = numberHasSign ? number[0] : ''
  number = numberHasSign ? number.replace(sign, '') : number

  // if the number is in scientific notation remove it
  if (number.includes('e')) {
    const zero = '0'
    const parts = String(number).toLowerCase().split('e') // split into coeff and exponent
    const e = parts.pop() // store the exponential part
    let l = Math.abs(Number(e)) // get the number of zeros
    const sign = Number(e) / l
    const coeff_array = parts[0].split('.')

    if (sign === -1) {
      coeff_array[0] = Math.abs(Number(coeff_array[0])).toString()
      number = zero + '.' + new Array(l).join(zero) + coeff_array.join('')
    } else {
      const dec = coeff_array[1]
      if (dec) l = l - dec.length
      number = coeff_array.join('') + new Array(l + 1).join(zero)
    }
  }

  return `${sign}${number}`
}

export const numberToScientific = (
  num: string | number,
  precision?: number,
): string => {
  return Number.parseFloat(num.toString()).toExponential(precision)
}

export function addCommas(value: string): string {
  if (!isValidNumber(value)) return ''
  return parseFloat(getStringWithoutCommas(value)).toLocaleString()
}

export function addCommasNoRounding(value: string): string {
  if (!isValidNumber(value)) return ''
  const [integers, decimals] = value.split('.')
  const withCommas = parseFloat(integers).toLocaleString()
  return decimals ? [withCommas, decimals].join('.') : withCommas
}

export function getStringWithoutCommas(value: string): string {
  if (!value) return value
  const [integers, decimals] = value.split('.')
  const integersWithCommas = integers.replace(/,/gi, '')
  if (decimals) return [integersWithCommas, decimals].join('.')

  return integersWithCommas
}

export function isValidNumber(value: string): boolean {
  return !Number.isNaN(parseFloat(getStringWithoutCommas(value)))
}

export function removeLeadingZeros(value: string): string {
  const result = value.replace(/^0+/, '')
  return result === '' ? '0' : result
}

export function removeTrailingZerosAfterDecimalPoint(value: string): string {
  return value.replace(/\.0+$|(\.\d+?)0+$/, '$1')
}

export const formatWithCommasAndDecimals = (value: string) => {
  if (!value) return ''

  const [integerPart, decimalPart] = value.split('.')

  const formattedInteger = addCommasNoRounding(integerPart)

  if (decimalPart !== undefined) {
    return `${formattedInteger}.${decimalPart}`
  }

  return formattedInteger
}

export function getNumberWithoutCommas(value: string): number {
  if (!value) return NaN
  const [integers, decimals] = value.split('.')
  const integersWithoutCommas = integers.replace(/,/g, '')
  const numericString = decimals
    ? `${integersWithoutCommas}.${decimals}`
    : integersWithoutCommas

  return parseFloat(numericString)
}
