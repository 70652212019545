import { scientificToDecimal } from 'utils/formatNumber'

function getPrecision(value = 0): number {
  if (!value) return 0
  const stringValue = value.toString()
  const scientificValue = scientificToDecimal(stringValue)
  const [, frac] = scientificValue.split('.')
  const precision = (frac || '').length
  return precision
}

function getFactor(decimal1 = 0, decimal2 = 0) {
  const prec1 = getPrecision(decimal1)
  const prec2 = getPrecision(decimal2)
  const maxPrecision = Math.max(prec1, prec2)
  return 10 ** maxPrecision
}

export function subtract(decimal1 = 0, decimal2 = 0) {
  const factor = getFactor(decimal1, decimal2)
  return Math.round(decimal1 * factor - decimal2 * factor) / factor
}

export function add(decimal1 = 0, decimal2 = 0) {
  const factor = getFactor(decimal1, decimal2)
  return Math.round(decimal1 * factor + decimal2 * factor) / factor
}

export function withPrecision(number, precision) {
  return Math.round(number * 10 ** precision) / 10 ** precision
}
