export enum Type {
  UPDATE_FIELD = 'live-risk-update-field',
  TOGGLE_COLUMNS_UI = 'live-risk-toggle-columns-ui',
  TOGGLE_COLUMN_HIDDEN = 'live-risk-toggle-column-hidden',
  TOGGLE_ROWS_UI = 'live-risk-toggle-rows-ui',
  SET_EXCHANGES = 'live-risk-set-exchanges',
  SET_EXPOSURES = 'live-risk-set-exposures',
  SET_DECIMAL_PLACES = 'live-risk-set-decimal-polaces',
  UPDATE_EXPOSURE = 'live-risk-update-exposure',
  CLEAR_ALL_HIDDEN_COLUMNS = 'live-risk-clear-all-hidden-columns',
  HIDE_ALL_COLUMNS = 'live-risk-hide-all-columns',
  CLEAR_ALL_HIDDEN_COLUMNS_SECTION = 'live-risk-clear-all-hidden-columns-section',
  HIDE_ALL_COLUMNS_SECTION = 'live-risk-hide-all-columns-section',
  SET_SECURITIES = 'live-risk-set-securities',
  SET_TRADE_DATA = 'live-risk-set-trade-data',
  SET_FRESHNESS_STATUS = 'live-risk-set-freshness-status',
  SET_TRADE_ORDERS = 'live-risk-set-trade-orders',
  SET_TABLE_MID_WIDTH = 'live-risk-set-table-mid-width',
  SET_EOD_PRICES = 'live-risk-set-set-eod-prices',
}

export enum CurrencyType {
  FIAT = '___FIAT___$$%%',
  CRYPTO = '___CRYPTO____$$%%',
}
