import { DefaultTheme } from 'styled-components'
import { colors } from './colors'
import { shadows } from './shadows'

const theme: DefaultTheme = {
  colors,
  shadows,
}

export default theme
