export enum Type {
  SET_OUTSTANDING_SETTLEMENTS_BY_CP = 'SET_OUTSTANDING_SETTLEMENTS_BY_CP',
  SET_CPS_WITH_FAILED_AUTO_SETTLEMENTS_OR_SWEEPS = 'SET_CPS_WITH_FAILED_AUTO_SETTLEMENTS_OR_SWEEPS',
  SET_OTC_INVENTORY = 'SET_OTC_INVENTORY',

  SET_SETTLEMENT = 'SET_SETTLEMENT',
  SET_HISTORICAL_SETTLEMENTS = 'SET_HISTORICAL_SETTLEMENTS',

  UPDATE_UNSETTLED_TRADES = 'UPDATE_UNSETTLED_TRADES',
  UPDATE_UNNALLOCATED_TRANSFERS = 'UPDATE_UNALLOCATED_TRANSFERS',

  TOGGLE_SETTING = 'TOGGLE_SETTING',
}
