import React, { FC, useLayoutEffect, useRef } from 'react'
import { Image, Menu } from 'semantic-ui-react'
import Link from 'components/Link'
import { login, logout } from 'utils/auth'
import CSSCore from 'utils/CSSCore'
import galaxyLogo from './clientNavAssets/galaxyLogo.svg'
import logoutIcon from './clientNavAssets/logoutIcon.svg'
import userIconBackground from './clientNavAssets/userIconBackground.svg'
import { getNavigationItems } from './navigationItems'

import Styles from './clientGalaxyNavigation.module.scss'
import ClientGlobalStyles from 'styles/client-theme.module.scss'

type ClientGalaxyNavigationProps = {
  activeItem: string
  user: string
  loggedIntoOkta: boolean
}

/* Gets users initials, limited to first 2 characters */
const getFirst2Initials = (user: string): string =>
  user
    .match(/\b(\w)/g)
    .join('')
    .slice(0, 2)

const ClientGalaxyNavigation: FC<ClientGalaxyNavigationProps> = ({
  activeItem,
  user,
  loggedIntoOkta,
}) => {
  const menuRef = useRef<HTMLDivElement>()
  useLayoutEffect(() => {
    CSSCore.addClass(document.body, 'client-portal')
    CSSCore.addClass(document.body, ClientGlobalStyles.root)
  }, [])

  const navBarData = getNavigationItems(activeItem)

  return (
    <div ref={menuRef} className={Styles.mainNavMenu}>
      <Menu fixed="top" inverted>
        <Menu.Menu position="left">
          <Menu.Item as={Link} href="/" header>
            <Image size="small" src={galaxyLogo} className={Styles.logo} />
          </Menu.Item>
        </Menu.Menu>

        <div className={Styles.centerItemsContainer}>
          {navBarData.map(navItem => (
            <Menu.Item
              as={Link}
              key={navItem.url}
              href={navItem.url}
              name={navItem.text}
              content={navItem.text}
              active={navItem.isActive}
            />
          ))}
        </div>

        <div className={Styles.rightMenuContainer}>
          <Menu.Menu position="right">
            {user && (
              <Menu.Item className="user" data-testid="user-profile">
                <div className={Styles.userProfile}>
                  <div>{getFirst2Initials(user)}</div>
                  <Image src={userIconBackground} />
                </div>
                {user}
              </Menu.Item>
            )}

            <Menu.Item name="logout" onClick={loggedIntoOkta ? logout : login}>
              <Image src={logoutIcon} />
              Log {loggedIntoOkta ? 'out' : 'in'}
            </Menu.Item>
          </Menu.Menu>
        </div>
      </Menu>
    </div>
  )
}

export default React.memo(ClientGalaxyNavigation)
