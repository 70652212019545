import { FC } from 'react'
import { Footer, FooterLink, GalaxyOneProvider } from '@g1/components'
import { useRouter } from 'next/router'
import VestingDisclaimerFooter from '@design-library/Footer/VestingDisclaimerFooter'
import { get } from 'Gatekeeper'

import Styles from './Footer.module.scss'

const footerLinks: FooterLink[] = [
  { display: 'About Galaxy', url: 'https://www.galaxy.com/about/' },
  { display: 'Terms of Use', url: 'https://www.galaxy.com/terms/' },
  { display: 'Disclosures', url: 'https://www.galaxy.com/disclosures/' },
  { display: 'Changelog', url: '/g1/changelog' },
]

export const G1Footer: FC = () => {
  const router = useRouter()
  const showVestingScheduleDisclaimer =
    router.pathname.startsWith('/g1/accounts') &&
    (router.query.unvested === 'true' || router.query.vested === 'true')

  const filteredLinks = footerLinks.filter(
    fl =>
      (fl.display === 'Changelog' && get('g1_show_changelog')) ||
      fl.display !== 'Changelog',
  )

  return (
    <div className={Styles.layoutFooter}>
      <GalaxyOneProvider theme="dark">
        {showVestingScheduleDisclaimer && <VestingDisclaimerFooter />}
        <div className={Styles.footer}>
          <Footer links={filteredLinks} />
        </div>
      </GalaxyOneProvider>
    </div>
  )
}
