import { Store, compose, createStore } from 'redux'
import { NotifyFunction, batchedSubscribe } from 'redux-batched-subscribe'
import { rootReducer } from 'redux/reducers'
import { RootState } from 'redux/reducers/state'
import { InitialState } from 'store'
import { isBrowser } from 'utils/isBrowser'
import { bindToReduxStore } from './localStorage'

let notifying = false
const debounceNotify = (notify: NotifyFunction) => {
  if (!notifying) {
    notifying = true
    setTimeout(() => {
      try {
        notify()
      } catch (e) {
        console.error(e)
      }
      notifying = false
    }, 0)
  }
}

export function configureStore(initialState?: InitialState): Store<RootState> {
  const composeEnhancers =
    process.env.NODE_ENV === 'development' && isBrowser
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose

  const middleware = composeEnhancers(batchedSubscribe(debounceNotify))
  const store = createStore(rootReducer, initialState, middleware)
  bindToReduxStore(store)
  return store
}
