import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { LegalTerms } from 'models/LegalTerms'
import { selectOktaToken } from 'redux/selectors/selectOktaToken'
import errorToast from 'utils/errorToast'
import { defaultRetries, defaultStaleTime, genericErrorMessage } from './const'

const fetchLegalTerms = async (oktaToken: string) => {
  const res = await fetch('/api/legalterms', {
    headers: {
      Authorization: `Bearer ${oktaToken}`,
    },
  })

  if (res.ok) {
    return res.json()
  } else {
    const { error } = await res.json()
    throw new Error(error)
  }
}

export const useGetLegalTerms = () => {
  const oktaToken = useSelector(selectOktaToken)
  return useQuery<LegalTerms>(
    ['legalterms'],
    async () => fetchLegalTerms(oktaToken),
    {
      onError: () => errorToast(genericErrorMessage),
      retry: defaultRetries,
      staleTime: defaultStaleTime,
    },
  )
}
