import React, { ReactElement, useCallback, useRef } from 'react'
import cx from 'classnames'
import { Icon } from 'semantic-ui-react'
import { Dialog } from 'components/Tooltip/Dialog'
import { Tooltip, VISIBILITY } from 'components/Tooltip/Tooltip'
import { useBoolFlag } from 'hooks/useBoolFlag'
import * as Styled from './styled'

type BaseColumnFilterProps = {
  menu: ReactElement
  className?: string
}
export const GridTableColumnFilterBase = React.memo(
  ({ menu, className }: BaseColumnFilterProps) => {
    const [showMenu, setShowMenu, setHideMenu] = useBoolFlag(false)
    const ref = useRef()
    const onClick = useCallback(e => {
      e.stopPropagation()
      setShowMenu()
    }, [])
    return (
      <Styled.FilterBaseContainer ref={ref} onClick={onClick}>
        <Tooltip
          vertical="bottom"
          horizontal="right"
          visibility={showMenu ? VISIBILITY.VISIBLE : VISIBILITY.NONE}
          customStyles={showMenu}
          tooltip={
            showMenu ? (
              <Dialog
                className={cx(className, 'grid-table-column-filter-dialog')}
                context={ref.current}
                close={setHideMenu}
              >
                {menu}
              </Dialog>
            ) : null
          }
        >
          <Icon name="list" />
        </Tooltip>
      </Styled.FilterBaseContainer>
    )
  },
)
