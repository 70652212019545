import React from 'react'
import cx from 'classnames'
import { isG1, isProd, isSandbox, isUAT } from 'utils/envUtils'
import { isClientPortal } from 'utils/isClientPortal'

import { getMessage } from './getMessage'
import Styles from './styles.module.scss'

const TestEnvironmentNotice: React.FC = () => {
  const isProdEnv = isProd()
  const isSandboxEnv = isSandbox()
  const isInternalPortal = !isClientPortal
  const isLocal = window.location.origin.includes('localhost')
  // Show banner in CP Portal QA/UAT and when running CP/Internal PROD locally
  const show =
    (isClientPortal && (!isProdEnv || isSandboxEnv)) || (isLocal && isProdEnv)

  const isG1App = isG1()

  if (!show) {
    return null
  }

  return (
    <div
      className={cx({
        [Styles.environmentNotice]: true,
        [Styles.internal]: isInternalPortal,
        [Styles.external]: isClientPortal,
        [Styles.g1Demo]: isG1App && isUAT(),
        [Styles.g1Sandbox]: isG1App && isSandbox(),
      })}
    >
      <div className={Styles.message}>{getMessage(isG1App)}</div>
    </div>
  )
}

export default React.memo(TestEnvironmentNotice)
