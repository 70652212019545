import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ChevronDownIcon,
  ClickAwayListener,
  GalaxyOneProvider,
} from '@g1/components'
import Link from 'next/link'
import { IdleSignOut } from 'components/IdleSignOut'
import { SignOutModal } from 'components/SignOutModal'
import { selectG1IsAdmin } from 'redux/selectors/selectG1IsAdmin'
import { selectDefaultEntity } from 'redux/selectors/selectGalaxyUser'
import { isCPOAccount, isG1GotcAccount } from 'utils/accounts'
import { login, singleLogout } from 'utils/auth'
import { registerMessageLoop } from 'utils/microfrontends'
import { HeaderLogo } from './components/HeaderLogo'
import { TopMenu } from './components/TopMenu'

import Styles from './Styles.module.scss'

type G1HeaderProps = {
  activeItem: string
  user: string
  loggedIntoOkta: boolean
}

const G1Header: FC<G1HeaderProps> = ({
  activeItem,
  user: firstName,
  loggedIntoOkta,
}) => {
  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false)
  const defaultEntity = useSelector(selectDefaultEntity)
  const isAdmin = useSelector(selectG1IsAdmin(defaultEntity.entityId))

  const hasOnlyCpoAccounts = defaultEntity?.accountInfo.every(isCPOAccount)

  const hasOnlyCpoOrG1GotcAccounts = defaultEntity?.accountInfo.every(
    account => isCPOAccount(account) || isG1GotcAccount(account),
  )

  useEffect(registerMessageLoop, [])

  useEffect(() => registerMessageLoop(), [])

  const onClickUserChevron = () => {
    setUserMenuOpen(!userMenuOpen)
  }

  useEffect(() => {
    const onBlur = () => setUserMenuOpen(false)
    window.addEventListener('blur', onBlur)

    return () => window.removeEventListener('blur', onBlur)
  }, [])

  return (
    <GalaxyOneProvider theme="dark">
      <div className={Styles.mainNavMenu}>
        <Link href="/g1">
          <HeaderLogo />
        </Link>

        <TopMenu activeItem={activeItem} />

        <div className={Styles.rightMenuContainer}>
          <div className={Styles.user}>
            <div className={Styles.chevron} onClick={onClickUserChevron}>
              <ChevronDownIcon />
              {userMenuOpen && (
                <ClickAwayListener onClickAway={() => setUserMenuOpen(false)}>
                  <ul className={Styles.listPopper}>
                    <li>
                      <Link href="/settings">Settings</Link>
                    </li>
                    {!hasOnlyCpoOrG1GotcAccounts && (
                      <li>
                        <Link href="/settings/addresses">Address Book</Link>
                      </li>
                    )}
                    {!hasOnlyCpoAccounts && isAdmin && (
                      <li>
                        <Link href="/settings/users">User Management</Link>
                      </li>
                    )}
                    <li onClick={singleLogout}>Sign Out</li>
                  </ul>
                </ClickAwayListener>
              )}
            </div>
            {loggedIntoOkta && firstName ? (
              <div className={Styles.firstName}>{firstName}</div>
            ) : (
              <div onClick={login}>Log in</div>
            )}
          </div>
        </div>
      </div>
      <IdleSignOut />
      <SignOutModal />
    </GalaxyOneProvider>
  )
}

export default React.memo(G1Header)
