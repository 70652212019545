import type { FilterConfig, SortConfig } from 'components/GridTable'
import { SORTDIR } from 'components/GridTable/useGridTableSorter'
import { isClientPortal } from 'utils/isClientPortal'
import { FILTER_OP } from './constants'

type Sorts = SortConfig['activeSorts']

const DefaultPageSize = 30
type URIProps = {
  endpoint: string
  pageSize?: number
  strategyId?: number
  filterConfig: FilterConfig
  sortConfig: SortConfig
}
export function getURI({
  endpoint,
  pageSize = DefaultPageSize,
  strategyId = 24,
  filterConfig,
  sortConfig,
}: URIProps) {
  const filters = convertFilterConfigToServerModel(filterConfig)
  if (!isClientPortal && strategyId !== 0) {
    filters.push({
      field: 'strategyId',
      conditions: [{ op: FILTER_OP.equals, val: strategyId }],
    })
  }
  const sortParams = convertSortConfigToQueryString(sortConfig.activeSorts)

  const filterQueryString = filters.length
    ? `&filters=${JSON.stringify(filters)}`
    : ''
  const sortQueryString = sortParams ? `&${sortParams}` : ''

  const uri = `${endpoint}?ps=${pageSize}&pg=0${filterQueryString}${sortQueryString}`
  return uri
}

type RequestDataProps = {
  pageSize?: number
  strategyId?: number
  filterConfig: FilterConfig
  sortConfig: SortConfig
}
export function getRequestData({
  pageSize = DefaultPageSize,
  strategyId = 24,
  filterConfig,
  sortConfig,
}: RequestDataProps) {
  const filters = convertFilterConfigToServerModel(filterConfig)
  if (!isClientPortal && strategyId !== 0) {
    filters.push({
      field: 'strategyId',
      conditions: [{ op: FILTER_OP.equals, val: strategyId }],
    })
  }
  const sortParams = convertSortConfigToSortParams(sortConfig.activeSorts)

  return {
    ps: pageSize,
    pg: 0,
    filters,
    ...sortParams,
  }
}

export type ServerSideFilterNode = ServerSideFilterValue | ServerSideFilter
export type ServerSideFilterValue = {
  op: string
  val: string | number
}
export type ServerSideFilter = {
  conditions: ServerSideFilterNode[]
  op?: string // 'and' | 'or'
}
type RootServerSideFilter = ServerSideFilter & { field: string }

function convertFilterConfigToServerModel(
  config: FilterConfig,
): Array<RootServerSideFilter> {
  const filters: RootServerSideFilter[] = []

  const activeFilters = config.activeFilters

  Object.keys(activeFilters).forEach(colId => {
    const serverModel = config.filters[colId].toServerModel(
      activeFilters[colId],
    )
    if (serverModel.conditions.length) {
      filters.push({
        field: colId,
        ...serverModel,
      })
    }
  })
  return filters
}

function convertSortConfigToQueryString(activeSorts: Sorts): string {
  const sorts: string[] = []
  Object.keys(activeSorts).forEach(colId => {
    const dir = activeSorts[colId].direction
    sorts.push(`st=${colId}&sd=${dir === SORTDIR.DESC ? 'desc' : 'asc'}`)
  })
  return sorts.join('&')
}

type SortField = string
type SortDirection = 'desc' | 'asc'
type SortParams = {
  st: SortField[]
  sd: SortDirection[]
}
function convertSortConfigToSortParams(activeSorts: Sorts): SortParams {
  const columnsToSort = Object.keys(activeSorts)
  if (columnsToSort.length === 0) {
    return
  }
  const st: SortField[] = []
  const sd: SortDirection[] = []
  columnsToSort.forEach(colId => {
    const dir = activeSorts[colId].direction
    st.push(colId)
    sd.push(dir === SORTDIR.DESC ? 'desc' : 'asc')
  })
  return { st, sd }
}
