import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAvailableCredit } from 'redux/selectors/selectAvailableCredit'

import Styles from './Banner.module.scss'

const BannerImpl: FC = () => {
  const [bannerDismissed, setBannerDismissed] = useState(false)
  const percentageCreditAvailable = useSelector(selectAvailableCredit)

  const shouldShowExceedLimitBanner =
    percentageCreditAvailable <= 20 && percentageCreditAvailable >= 0

  return (
    <>
      {shouldShowExceedLimitBanner && !bannerDismissed && (
        <div className={Styles.creditBanner}>
          <div>
            You have exceeded 80% of your credit limit. Please settle to
            increase available credit.
          </div>
          <div
            className={Styles.closeButton}
            onClick={() => setBannerDismissed(true)}
          >
            x
          </div>
        </div>
      )}
    </>
  )
}

export const Banner = React.memo(BannerImpl)
