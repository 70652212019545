import { FC } from 'react'
import { Button, Popup } from 'semantic-ui-react'
import { getEnv, isProd } from 'utils/envUtils'

import Styles from './styles.module.scss'

export const VersionTag: FC = () => {
  const commitId = (process.env.COMMIT_ID ?? '').replace('undefined', '')
  const content = `${process.env.STAGING_NAME} ${commitId}`
  return (
    <div>
      <Popup
        content={content}
        position="bottom center"
        disabled={isProd()}
        trigger={
          <Button
            animated="vertical"
            color="orange"
            size="mini"
            compact
            className={Styles.button}
          >
            <Button.Content visible size="mini">
              {getEnv()}
            </Button.Content>
            <Button.Content hidden>
              {process.env.NEXT_PUBLIC_VERSION}
            </Button.Content>
          </Button>
        }
      />
    </div>
  )
}
