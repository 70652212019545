import { FC, Fragment, ReactElement } from 'react'

import { MaintenanceProvider } from 'components/Banner/hooks/useMaintenance'
import Secure from 'components/Secure'
import StoreGlobalDispatch from 'Shared'

import { isG1 } from 'utils/envUtils'
import { AppContent } from '../AppContent'

type Props = {
  appHeader: ReactElement
  page: ReactElement
}

export const SecuredContent: FC<Props> = ({ appHeader, page }) => {
  const Wrapper = isG1() ? MaintenanceProvider : Fragment

  return (
    <Wrapper>
      <StoreGlobalDispatch />
      <Secure>
        <AppContent appHeader={appHeader} page={page} />
      </Secure>
    </Wrapper>
  )
}
