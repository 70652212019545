import React from 'react'
import Link from 'next/link'

const NextLink = ({ children, active = false, href, ...rest }) => {
  return (
    <Link
      href={href}
      as={href}
      className={`item ${active ? 'active' : ''}`}
      {...rest}
    >
      {children}
    </Link>
  )
}

export default NextLink
