import { useLayoutEffect } from 'react'
import { emptyObject } from 'utils/emptyObject'

export const PASSIVE = { passive: true }
export const CAPTURE = { capture: true }

export const useListener = <TEvent extends keyof WindowEventMap>(
  node: SVGElement | HTMLElement | Window | undefined | null,
  event: TEvent,
  handler: ListenerHandler<TEvent>,
  options = emptyObject,
) => {
  useLayoutEffect(() => {
    if (!node || !handler) {
      return
    }
    node.addEventListener(event, handler, options)
    return () => {
      node.removeEventListener(event, handler, options)
    }
  }, [node, event, handler, options])
}

type ListenerHandler<T extends keyof WindowEventMap> = Parameters<
  typeof addEventListener<T>
>[1]
