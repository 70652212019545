import React from 'react'
import ReactDOM from 'react-dom'

export const PortalWrapper = React.memo(
  ({ children }: React.PropsWithChildren) => {
    return ReactDOM.createPortal(children, document.body)
  },
)

PortalWrapper.displayName = 'PortalWrapper'
