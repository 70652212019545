import React, { FC } from 'react'
import { Typography, WarningIcon } from '@g1/components'
import { Message } from '../../../hooks/useMaintenanceIntervals'

import Styles from './styles.module.scss'

type Props = {
  message: Message
}

const MaintenanceBanner: FC<Props> = ({ message }) => (
  <>
    <WarningIcon />
    <Typography variant="body2" className={Styles.message}>
      <span className={Styles.title}>{message.title}</span>
      <span>{message.body}</span>
    </Typography>
  </>
)

export default MaintenanceBanner
