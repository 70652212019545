import { useSelector } from 'react-redux'
import {
  G1GalaxyUserData,
  GetGalaxyUserData,
  InternalGalaxyUserData,
} from 'redux/models'
import { selectGalaxyUser } from 'redux/selectors/selectGalaxyUser'

export function useAuthUser(app: 'internal'): InternalGalaxyUserData
export function useAuthUser(app: 'get'): GetGalaxyUserData
export function useAuthUser(app: 'g1'): G1GalaxyUserData
export function useAuthUser(app: 'get' | 'internal' | 'g1') {
  const user = useSelector(selectGalaxyUser('common')) || {}
  switch (app) {
    case 'internal':
      return user
    case 'get':
      return user
    case 'g1':
      return user
  }
}

export default useAuthUser
