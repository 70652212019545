export function getBoundingClientRect(node, withScroll = true) {
  const rect = node.getBoundingClientRect()
  const scrollX = withScroll ? window.scrollX : 0
  const scrollY = withScroll ? window.scrollY : 0
  return {
    top: rect.top + scrollY,
    right: rect.right + scrollX,
    bottom: rect.bottom + scrollY,
    left: rect.left + scrollX,
    width: rect.width,
    height: rect.height,
    x: rect.x + scrollX,
    y: rect.y + scrollY,
    _rect: rect,
  } as DOMRect & {
    _rect: DOMRect
  }
}
