import { DeepPartial } from '@reduxjs/toolkit'
import { RootState } from 'redux/reducers/state'

type Saver = (state: RootState) => DeepPartial<RootState>
const savers: Saver[] = []

export const registerSaver = (saver: Saver) => {
  savers.push(saver)
}

export const getSavers = (): Saver[] => savers.slice()
