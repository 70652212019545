import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import moment, { Moment } from 'moment-timezone'
import { get } from 'Gatekeeper'
import { useStateRef } from 'hooks/useStateRef'
import { getBeforeMaintenanceMessage, maintenanceMessage } from './messages'

export type Message = {
  title: string
  body: ReactNode
}

export type MaintenanceIntervals = {
  showMaintenanceBanner: boolean
  message: Message
  timestamp?: number
}

const maintenanceWindowDuration = get('enable_longer_maintenance_window')
  ? 60
  : 20

export function useMaintenanceIntervals(): MaintenanceIntervals {
  const [showMaintenanceBanner, updateMaintenanceBanner] = useStateRef(false)
  const timestampRef = useRef<number | undefined>()
  const [message, updateMessage] = useState<Message>(null)

  useEffect(() => {
    const activateMaintenance = (localStartTime?: Moment) => {
      updateMaintenanceBanner(true)
      const message = localStartTime
        ? getBeforeMaintenanceMessage(localStartTime.format('h:mm A'))
        : maintenanceMessage
      updateMessage(message)
    }
    const tick = () => {
      // Maintenance start time (in ET)
      const dailyMaintenanceStartTime = moment.tz(
        '5:00 pm',
        'h:mm a',
        'America/New_York',
      )

      const currentTime = moment()

      // Convert the ET start times to the user's local timezone
      const userTimezone = moment.tz.guess()
      const dailyStartLocal = dailyMaintenanceStartTime.tz(userTimezone)

      // Calculate the time difference between the current time and the start time of maintenance window
      const dailyDiff = dailyStartLocal.diff(currentTime, 'minutes')

      if (dailyDiff > 0 && dailyDiff <= 30) {
        activateMaintenance(dailyStartLocal)
      } else if (dailyDiff <= 0 && dailyDiff >= -maintenanceWindowDuration) {
        activateMaintenance()
        timestampRef.current = dailyMaintenanceStartTime.valueOf()
      } else {
        updateMaintenanceBanner(false)
        timestampRef.current = undefined
      }
    }
    const timer = setInterval(tick, 1000 * 60)
    tick()
    return () => clearTimeout(timer)
  }, [updateMaintenanceBanner, updateMessage])

  const showCurrent = showMaintenanceBanner.current

  return useMemo(
    () => ({
      showMaintenanceBanner: showCurrent,
      timestamp: timestampRef.current,
      message,
    }),
    [showCurrent, message],
  )
}
