import { CSSProperties, ReactElement, ReactNode } from 'react'
import { ColDef, Column, ColumnApi, GridApi } from 'ag-grid-community'
import { AgGridReactProps } from 'ag-grid-react'
import { round } from 'lodash'
import moment from 'moment'

import util from 'components/Galaxy/GalaxyUtils'
import {
  transferPurposeTIMSFormatter,
  transferStatusTIMSFormatter,
} from 'components/TIMSTransferBlotter/columnFormatter'
import { RiskLineItem, TableID } from 'redux/models'
import { getBigDecimalPrettyValue } from 'utils/bigDecimalHelpers'
import { formatNumber, formatNumberMinMax } from 'utils/formatNumber'

export type GalaxyGridProp<TData = any> = AgGridReactProps<TData> & {
  height?: string | number
  width?: string | number
  header?: string
  dark?: boolean
  className?: string
  style?: CSSProperties
  optionalElement?: () => ReactElement
  id?: TableID
  version?: number
  getColumnApi?: (api: ColumnApi) => void
  getGridApi?: (gridApi: GridApi) => void
  isLoading?: boolean
  isInfinite?: boolean
  controlsLoading?: boolean
}

export type BuildColumnProps = ColDef & {
  header?: string
  headerName?: string
  format?: ({ data, colDef }: any) => any
  children?: ReactNode
  valueGetter?: Function
}

type ValueFormatter = {
  valueFormatter?: ({ value }: { value: string }) => string | number | undefined
  filter: string
  type?: string
  cellStyle?: CSSProperties
  filterParams?: any
}

const GalaxyGridHelper = () => {
  return {
    deltaRowMode: true,
    defaultColDef,
    getDefaultColDef,
    BuildDefaultColDef,
    getStatusHeaderClass,
    getRowNodeId,
    formatNumber4,
    formatNumberNoMin,
    currencyFormatter,
    numberFormatter,
    dateFormatter,
    dateFormatterCustom,
    priceFormatter,
    isPinnedColumn,
    formatDateMoment: util.formatDateMoment,
  }
}

export default GalaxyGridHelper

export const defaultColDef: ColDef = {
  width: 105,
  resizable: true,
  sortable: true,
  enableCellChangeFlash: true,
  filter: 'agNumberColumnFilter',
  type: 'numericColumn',
  cellStyle: { textAlign: 'right', justifySelf: 'right' },
}

export const getDefaultColDef = (): ColDef => defaultColDef

export const BuildDefaultColDef = (args): ColDef => ({
  ...defaultColDef,
  ...args,
})

export const getStatusHeaderClass = (): string[] => [
  'ag-header-cell',
  'ag-header-cell-sortable',
  'ag-numeric-header',
  'status',
]

export const getRowNodeId = (data: RiskLineItem) =>
  `ggh-${data.strategyId}-${data.currency}`

export const currencyFormatter = (
  { value },
  precision = 2,
): string | number => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) {
    return '-'
  }

  const formattedValue = `$${formatNumber(precision, Math.abs(value))}`

  if (value < 0) {
    return `(${formattedValue})`
  }

  return formattedValue
}

export const formatNumberNoMin = ({ value }): string => {
  return !value ? '0' : formatNumber(Infinity, value)
}

export const formatNumber8Dp = ({ value }): string => {
  return !value ? '0' : formatNumber(8, value, false)
}

export const numberFormatter = ({ value }): string | number => {
  return util.formatNumber(value)
}

export const priceFormatter = ({ value }, prec = 8): string | number => {
  return util.formatToNonZeroPrecision(value, prec)
}

export const capitalizeFormatter = ({ value }): string => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

export const formatNumber4 = ({ value }, prec = 4): string | number => {
  return util.formatToNonZeroPrecision(value, prec)
}

export const formatNumberFixed = ({ value }, prec = 4) => {
  return formatNumberMinMax(value, prec, prec)
}

export const formatNumber2 = ({ value }, prec = 2): string | number => {
  return value == null ? value : formatNumber(prec, value)
}

// round up when positive, round down when negative
export const formatNumber2Round = ({ value }): string | number => {
  const absRoundedNumber = round(Math.abs(value), 2)

  return value == null
    ? value
    : formatNumber(2, absRoundedNumber * Math.sign(value))
}
export const formatNumber0 = ({ value }, prec = 0): string | number => {
  return value == null ? value : formatNumber(prec, Math.round(value))
}

export const UTCDateFormatter = ({ value }): string => {
  return value == null ? value : moment(value).utc().format('M/DD/YY')
}

export const dateFormatter = ({ value }): string => {
  return value == null ? value : util.formatDate(value, 'M/DD/YY h:mm A')
}

export const bigDecimalFormatter = ({ value }) =>
  getBigDecimalPrettyValue({ value, digits: 3, separator: ',' })

export const booleanFormatter = ({ value }): string => {
  return value ? 'Yes' : 'No'
}

export const booleanCellEditorParams = (): object => {
  return {
    values: [true, false],
    formatValue: (value: boolean) => booleanFormatter({ value }),
  }
}

export const percentageFormatter = ({ value }, decimals = 2): string => {
  // eslint-disable-next-line no-restricted-globals
  return value !== null && !isNaN(value)
    ? `${(Number(value) * 100).toFixed(decimals)}%`
    : '0%'
}

export const percentageWholeNumberFormatter = ({ value }): string => {
  return value != null ? `${(Number(value) * 100).toFixed(0)}%` : '0%'
}

export function dateFormatterUnix(dateFormat: string) {
  const sformat = dateFormat

  const customDateformat = ({ value }): string => {
    return util.formatUnixDateMoment(value, sformat)
  }
  return customDateformat
}

export const hideNegativeFormatter = ({ value }): string => {
  return value < 0 ? '' : `${value}`
}

export function dateFormatterCustom(dateFormat: string) {
  const sformat = dateFormat

  const customDateformat = ({ value }): string => {
    return util.formatDateMoment(value, sformat)
  }
  return customDateformat
}

const valueColumnformatterMap = new Map<any, ValueFormatter>()
valueColumnformatterMap.set(undefined, { filter: 'agTextColumnFilter' })
valueColumnformatterMap.set(currencyFormatter, {
  valueFormatter: currencyFormatter,
  filter: 'agNumberColumnFilter',
  type: 'numericColumn',
  cellStyle: { textAlign: 'right', justifySelf: 'right' },
})
valueColumnformatterMap.set(dateFormatter, {
  valueFormatter: dateFormatter,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['clear', 'apply'],
    refreshValuesOnOpen: true,
    // provide comparator function
    comparator(filterLocalDateAtMidnight, cellValue) {
      const dateAsString = cellValue
      if (dateAsString == null) return 0
      const cellDate = new Date(dateAsString)
      cellDate.setHours(0, 0, 0, 0)
      // Now that both parameters are Date objects, we can compare
      if (cellDate < filterLocalDateAtMidnight) {
        return -1
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1
      }
      return 0
    },
  },
})
valueColumnformatterMap.set(booleanFormatter, {
  valueFormatter: booleanFormatter,
  filter: 'agDateColumnFilter',
})
valueColumnformatterMap.set(percentageFormatter, {
  valueFormatter: percentageFormatter,
  filter: 'agDateColumnFilter',
})
valueColumnformatterMap.set(percentageWholeNumberFormatter, {
  valueFormatter: percentageWholeNumberFormatter,
  filter: 'agDateColumnFilter',
})
valueColumnformatterMap.set(hideNegativeFormatter, {
  valueFormatter: hideNegativeFormatter,
  filter: 'agSetColumnFilter',
  cellStyle: { textAlign: 'right', justifySelf: 'right' },
})
valueColumnformatterMap.set(priceFormatter, {
  valueFormatter: priceFormatter,
  filter: 'agNumberColumnFilter',
  type: 'numericColumn',
})
valueColumnformatterMap.set(numberFormatter, {
  valueFormatter: numberFormatter,
  filter: 'agNumberColumnFilter',
  type: 'numericColumn',
  cellStyle: { textAlign: 'right', justifySelf: 'right' },
})
valueColumnformatterMap.set(formatNumber4, {
  valueFormatter: formatNumber4,
  filter: 'agNumberColumnFilter',
  type: 'numericColumn',
  cellStyle: { textAlign: 'right', justifySelf: 'right' },
})
valueColumnformatterMap.set(formatNumber8Dp, {
  valueFormatter: formatNumber8Dp,
  filter: 'agNumberColumnFilter',
  type: 'numericColumn',
  cellStyle: { textAlign: 'right', justifySelf: 'right' },
})
valueColumnformatterMap.set(formatNumber2, {
  valueFormatter: formatNumber2,
  filter: 'agNumberColumnFilter',
  type: 'numericColumn',
  cellStyle: { textAlign: 'right', justifySelf: 'right' },
})
valueColumnformatterMap.set(formatNumberNoMin, {
  valueFormatter: formatNumberNoMin,
  filter: 'agNumberColumnFilter',
  type: 'numericColumn',
  cellStyle: { textAlign: 'right', justifySelf: 'right' },
})
valueColumnformatterMap.set(transferPurposeTIMSFormatter, {
  valueFormatter: transferPurposeTIMSFormatter,
  filter: 'agSetColumnFilter',
  cellStyle: { textAlign: 'center', justifySelf: 'center' },
})
valueColumnformatterMap.set(transferStatusTIMSFormatter, {
  valueFormatter: transferStatusTIMSFormatter,
  filter: 'agSetColumnFilter',
  cellStyle: { textAlign: 'center', justifySelf: 'center' },
})
valueColumnformatterMap.set(capitalizeFormatter, {
  valueFormatter: capitalizeFormatter,
  filter: 'agSetColumnFilter',
  cellStyle: { textAlign: 'center', justifySelf: 'center' },
})

// valueColumnformatterMap.set(dateFormatterCustom, { valueFormatter: dateFormatterCustom, filter: 'agDateColumnFilter', type: 'textColumn' })

export function buildColumnProps(props: BuildColumnProps & any): any {
  const { field, header, format, headerName, values, ...args } = props
  const nameHeader = headerName || header

  return {
    field,
    headerName: nameHeader || util.titleCase(field || ''),
    menuTabs: args.filter === false ? [] : ['filterMenuTab'],
    filterParams: {
      buttons: ['clear'],
      debounceMs: 1000,
      values,
    },
    ...valueColumnformatterMap.get(format),
    ...args,
  }
}

export const isPinnedColumn = (column: Column) => {
  return column && column.isPinned() === true
}

const collator = Intl.Collator('en', { sensitivity: 'accent' })
// https://www.ag-grid.com/archive/23.1.1/javascript-grid-sorting/#custom-sorting
export const stringComparator = (valueA?: string, valueB?: string) =>
  collator.compare(valueA?.trim(), valueB?.trim())
