import { isBrowser } from 'utils/isBrowser'

const requestIdleCallback =
  (isBrowser ? global.requestIdleCallback : null) ??
  ((fn: IdleRequestCallback) => {
    setTimeout(fn, 16)
    return -1
  })

export default requestIdleCallback
