import { Strategy } from './Strategy'

export enum UserRole {
  Admin = 'Admin',
  Trader = 'Trader',
  OperationUser = 'Operations_User',
  ReadOnly = 'Read_Only',
  GalaxySupport = 'Galaxy_Support',
  Lender = 'Lender',
}

export type GalaxyUserRole = {
  roleId: number
  role: UserRole
  sensitivity: number
  applicationId: number
  applicationName: string
}

export type AccountInfoAttribute = {
  accountAttributeTypeId: number
  accountAttributeTypeName: string
}

export type AccountInfo = {
  accountAttributes: AccountInfoAttribute[]
  accountId: number
  accountName: string
  accountTypeId: number
  accountTypeName: string
  hasAvailableCredit: boolean
  isG1Account: boolean
  percentageCreditAvailable: number
  roles: GalaxyUserRole[]
  sequence: number
}

export type CounterpartyDetails = {
  accountInfo: AccountInfo[]
  entityId: number
  entityName: string
  isGdpsApproved: boolean
  roles: GalaxyUserRole[] | null
}

export type G1GalaxyUserData = {
  counterPartyDetails?: CounterpartyDetails[]
  email: string
  firstName: string
  lastName: string
  status: string
  userId: number
}

export type InternalGalaxyUserData = {
  email: string
  firstName: string
  lastName: string
  id: number
  isAdmin: boolean
  isCompliance: boolean
  isLender: boolean
  isOperations: boolean
  isTrader: boolean
  login: string
  strategies?: Strategy[]
  // just in redux
  selectedStrategy?: Strategy
}

export type GetGalaxyUserData = {
  accountAttributeTypeId?: number | null
  accountAttributeTypeName?: string | null
  accountId: number
  accountTypeId: number
  accountTypeName: string
  admin: boolean
  email: string
  entityId: number
  entityName: string
  firstName: string
  lastName: string
  g1Account: boolean
  getAccount: boolean
  hasAvailableCredit: boolean
  hasCompletedGotcTutorial: boolean
  hasCompletedRequestSettlementTutorial: boolean
  hasCompletedSettlementTutorial: boolean
  isAdmin: boolean
  isG1Account: boolean
  isGdpsApproved: boolean
  isGetAccount: boolean
  isTrader: boolean
  isTwapEnabled: boolean
  percentageCreditAvailable: number
  trader: boolean
  twapEnabled: boolean
  userId: number
}

export type GalaxyUserData =
  | G1GalaxyUserData
  | GetGalaxyUserData
  | InternalGalaxyUserData

export type CommonGalaxyUserData = {
  [K in keyof G1GalaxyUserData &
    keyof GetGalaxyUserData &
    keyof InternalGalaxyUserData]:
    | G1GalaxyUserData[K]
    | GetGalaxyUserData[K]
    | InternalGalaxyUserData[K]
}

export interface GalaxyUserModel {
  data?: GalaxyUserData
  localStorageLoaded: boolean
}
