type Comparator<T> = (objA: T, objB: T) => boolean
export const shallowEqual = <T>(
  objA: T,
  objB: T,
  compareKeys?: Record<string, Comparator<T>> | Comparator<T>,
) => {
  if (!objA || !objB) {
    return objA === objB
  }
  if (objA === objB) {
    return true
  }
  const keys = Object.assign({}, objA, objB)
  if (Object.keys(keys).length !== Object.keys(objA).length) {
    return false
  }
  return !Object.keys(keys).find(key => {
    if (compareKeys) {
      if (compareKeys[key] instanceof Function) {
        return !compareKeys[key](objA[key], objB[key])
      } else if (compareKeys instanceof Function) {
        return compareKeys(objA[key], objB[key])
      }
    }
    return objA[key] !== objB[key]
  })
}
