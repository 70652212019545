import produce from 'immer'
import { handleActions } from 'redux-actions'
import { getCounterpartyEntitiesList } from 'data/counterpartyEntitiesList'
import { Type } from 'redux/actions/counterpartyEntitiesList'
import { GetCounterpartyEntitiesList } from 'redux/models'
import { REDUX_INITIAL_SET } from 'redux/store/localStorage'

const initialState: GetCounterpartyEntitiesList = {
  counterpartyList: [],
}

export const CounterpartyEntitiesReducer = handleActions<
  GetCounterpartyEntitiesList,
  any
>(
  {
    [REDUX_INITIAL_SET]: state => {
      return produce(state, () => {
        getCounterpartyEntitiesList()
      })
    },
    [Type.GET_COUNTERPARTYENTITIES_LIST]: (
      state: GetCounterpartyEntitiesList,
      { payload },
    ) => {
      return produce(state, draft => {
        draft.counterpartyList = payload
      })
    },
  },
  initialState,
)
