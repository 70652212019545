import deepEqual from 'react-fast-compare'
import { ColDef } from 'ag-grid-community'
import produce from 'immer'
import memoizee from 'memoizee/weak'
import { handleActions } from 'redux-actions'
import { GridHelper, util } from 'components/Galaxy'
import { TradeCell } from 'components/LiveRiskV2/TradeCell'
import { CurrencyType, Type } from 'redux/actions/liveRisk'
import { ExposureItem, LiveRiskModel } from 'redux/models'
import { formatNumber, scientificToDecimal } from 'utils/formatNumber'
import { getState } from 'utils/getState'
import {
  ASSET_STORE_TYPE_FIREBLOCKS_SETTLEMENT_WALLET,
  ASSET_STORE_TYPE_RISK,
  ASSET_STORE_TYPE_SETTLEMENT_WALLET,
} from 'utils/RefDataHelper'

import { REDUX_INITIAL_SET } from '../store/localStorage'
import { registerSaver } from '../store/utils'
import { RootState } from './state'

export const HIDDEN = 'Hidden'

export const initialState: LiveRiskModel = {
  version: 3,
  hideLTNotionalValueBalances: true,
  hideLTDeltaBalances: false,
  hideLTTradeDeltaBalances: false,
  notionalValue: 0.9,
  deltaValue: 1000,
  tradeDeltaValue: 1000,
  tableMidWidth: 500,
  hiddenColumns: {
    Trezor: true,
    'Kingdom Trust': true,
    Xapo: true,
    Ledger: true,
    'Bank of Communications': true,
    'BCB Payments': true,
    'Circle USDC': true,
    'Interactive Brokers': true,
    'LBX Payments': true,
    LMAX: true,
    'Signature Bank': true,
    Signet: true,
    Sumitomo: true,
    'Silvergate Bank': true,
    [CurrencyType.FIAT]: true,
  },
  freshness: {},
  hiddenByNotionalValue: {},
  exposures: [],
  exchanges: [],
  decimalPlaces: 0,
  exchangeColumns: [],
  toggleColumnsUIOpen: false,
  summary: {
    currency: 'Total (USD)',
  },
  securitiesData: {},
  tradeData: {},
  tradeOrders: {},
  tradeDataLoaded: false,
  tradeDataInterval: [81],
  showReconView: true,
  showTradesView: false,
  focusedViewExpandedRowSplit: 50,
  eodPrices: {},
}

registerSaver((state: RootState) => {
  return {
    LiveRisk: {
      version: state.LiveRisk.version,
      decimalPlaces: state.LiveRisk.decimalPlaces,
      notionalValue: state.LiveRisk.notionalValue,
      hideLTNotionalValueBalances: state.LiveRisk.hideLTNotionalValueBalances,
      hideLTDeltaBalances: state.LiveRisk.hideLTDeltaBalances,
      hideLTTradeDeltaBalances: state.LiveRisk.hideLTTradeDeltaBalances,
      hiddenColumns: state.LiveRisk.hiddenColumns,
      tradeDataInterval: state.LiveRisk.tradeDataInterval,
      showReconView: state.LiveRisk.showReconView,
      showTradesView: state.LiveRisk.showTradesView,
      tableMidWidth: state.LiveRisk.tableMidWidth,
      focusedViewExpandedRowSplit: state.LiveRisk.focusedViewExpandedRowSplit,
    },
  }
})

export const OPTIONS = [
  {
    value: 1,
    text: 'Last 1 hour',
  },
  {
    value: 4,
    text: 'Last 4 hours',
  },
  {
    value: 81,
    text: '12PM - 12AM UTC',
  },
  {
    value: 80,
    text: '12AM - 12PM UTC',
  },
]

export const LiveRiskReducer = handleActions<LiveRiskModel, any>(
  {
    [REDUX_INITIAL_SET]: (state, { payload }: { payload: RootState }) => {
      return produce(state, (draft: LiveRiskModel) => {
        const persistedState = (payload.LiveRisk || {}) as RootState['LiveRisk']
        Object.assign(draft, persistedState)
        if (persistedState.version === 0) {
          Object.assign(draft.hiddenColumns, initialState.hiddenColumns)
        }
        if (persistedState.version < 2) {
          draft.hiddenColumns[CurrencyType.FIAT] = true
          draft.hiddenColumns[CurrencyType.CRYPTO] = false
        }
        draft.hiddenColumns[CurrencyType.FIAT] =
          !draft.hiddenColumns[CurrencyType.CRYPTO]
        draft.tradeDataInterval = draft.tradeDataInterval || [81]
        if (
          OPTIONS.map(o => o.value).indexOf(draft.tradeDataInterval[0]) ===
            -1 &&
          draft.tradeDataInterval.length === 1
        ) {
          draft.tradeDataInterval = [81]
        }
        calculateSummary(draft)
      })
    },
    [Type.UPDATE_FIELD]: (state, { payload }) => {
      return produce(state, (draft: LiveRiskModel) => {
        const { field, val } = payload
        draft[field] = val
        if (
          field === 'notionalValue' ||
          field === 'hideLTNotionalValueBalances'
        ) {
          updateHiddenByNotional(draft)
        }
      })
    },
    [Type.SET_EXPOSURES]: (state, { payload }) => {
      return produce(state, (draft: LiveRiskModel) => {
        const exposures = payload.data.sort((a, b) => {
          if (a.isCrypto || a.currency.includes('/')) {
            if (b.isCrypto || b.currency.includes('/')) {
              return 0
            }
            return -1
          } else if (b.isCrypto || b.currency.includes('/')) {
            return 1
          }
          return 0
        })
        exposures.forEach(e => {
          e.id = getExposureID(e)
          convertExposureToNonScientific(e)
        })
        draft.exposures = exposures
        addTradeDataToExposures(draft)
        addPriceMoveDataToExposures(draft)
        calculateSummary(draft)
      })
    },
    [Type.SET_EXCHANGES]: (state, { payload }) => {
      return produce(state, (draft: LiveRiskModel) => {
        draft.exchanges = payload.data
        setExchangeColumns(draft)
        calculateSummary(draft)
      })
    },
    [Type.TOGGLE_COLUMNS_UI]: state => {
      return produce(state, draft => {
        draft.toggleColumnsUIOpen = !draft.toggleColumnsUIOpen
      })
    },
    [Type.TOGGLE_COLUMN_HIDDEN]: (state, { payload }) => {
      return produce(state, (draft: LiveRiskModel) => {
        const column = payload.key
        draft.hiddenColumns[column] = !draft.hiddenColumns[column]
        calculateSummary(draft)
      })
    },
    [Type.SET_DECIMAL_PLACES]: (state, { payload }) => {
      return produce(state, (draft: LiveRiskModel) => {
        draft.decimalPlaces = payload.value
        calculateSummary(draft)
      })
    },
    [Type.UPDATE_EXPOSURE]: (state, { payload }) => {
      return produce(state, (draft: LiveRiskModel) => {
        const d = payload.data
        const id = getExposureID(d)
        if (!d) {
          return
        }
        d.positions &&
          d.positions.forEach(ed => {
            if (ed.symbol) d[ed.symbol] = ed.qty
          })
        const index = draft.exposures.findIndex(e => e.id === id)
        if (index === -1) {
          return
        }
        Object.assign(draft.exposures[index], payload.data)
        calculateSummary(draft)
      })
    },
    [Type.CLEAR_ALL_HIDDEN_COLUMNS]: state => {
      return produce(state, (draft: LiveRiskModel) => {
        draft.exchangeColumns.forEach(c => {
          if (isHideableColumn(c)) {
            draft.hiddenColumns[c.headerName] = false
          }
        })
        calculateSummary(draft)
      })
    },
    [Type.HIDE_ALL_COLUMNS]: state => {
      return produce(state, (draft: LiveRiskModel) => {
        draft.exchangeColumns.forEach(c => {
          if (isHideableColumn(c)) {
            draft.hiddenColumns[c.headerName] = true
          }
        })
        calculateSummary(draft)
      })
    },
    [Type.CLEAR_ALL_HIDDEN_COLUMNS_SECTION]: (state, { payload }) => {
      return produce(state, (draft: LiveRiskModel) => {
        draft.exchangeColumns.forEach(c => {
          if (getColumnAssetType(c) === payload.section) {
            draft.hiddenColumns[c.headerName] = false
          }
        })
        calculateSummary(draft)
      })
    },
    [Type.HIDE_ALL_COLUMNS_SECTION]: (state, { payload }) => {
      return produce(state, (draft: LiveRiskModel) => {
        draft.exchangeColumns.forEach(c => {
          if (getColumnAssetType(c) === payload.section) {
            draft.hiddenColumns[c.headerName] = true
          }
        })
        calculateSummary(draft)
      })
    },
    [Type.SET_SECURITIES]: (state, { payload }) => {
      return produce(state, (draft: LiveRiskModel) => {
        const securitiesData = {}
        payload.forEach(security => {
          securitiesData[security.id] = security
        })
        draft.securitiesData = securitiesData
      })
    },
    [Type.SET_TRADE_DATA]: (state, { payload }) => {
      return produce(state, (draft: LiveRiskModel) => {
        const data = {}
        payload.forEach(d => {
          if (!deepEqual(draft[d.security], d)) {
            data[d.security] = d
          } else {
            data[d.security] = draft[d.security]
          }
        })
        draft.tradeData = data
        addTradeDataToExposures(draft)
      })
    },
    [Type.SET_TRADE_ORDERS]: (state, { payload }) => {
      const { type, currency, orders } = payload
      state.tradeOrders[currency] = state.tradeOrders[currency] || {}
      state.tradeOrders[currency][type] = orders
      return { ...state }
    },
    [Type.SET_FRESHNESS_STATUS]: (state, { payload }) => {
      return produce(state, draft => {
        draft.freshness = payload
      })
    },
    [Type.SET_TABLE_MID_WIDTH]: (state, { payload }) => {
      return produce(state, draft => {
        draft.tableMidWidth = payload.width
      })
    },
    [Type.SET_EOD_PRICES]: (state, { payload }) => {
      return produce(state, draft => {
        payload.forEach(securityInfo => {
          draft.eodPrices[securityInfo.symbol] = securityInfo
        })

        addPriceMoveDataToExposures(draft as LiveRiskModel)
      })
    },
  },
  initialState,
)

function calculateSummary(draft: LiveRiskModel) {
  calculateSummaryImpl(draft)
}

function calculateSummaryImpl(draft: LiveRiskModel) {
  const exposures = draft.exposures
  const exchanges = draft.exchanges
  const t: ExposureItem | any = {
    currency: 'Total (USD)',
    total: 0,
  }
  exchanges.forEach(pos => {
    t[pos.name] = 0
    t.total = 0
    t.delta = 0
    exposures.forEach((element: ExposureItem) => {
      if (element.price === 'np') {
        return
      }
      t.total += element.totalQty * parseFloat(element.price)

      if (Object.hasOwn(element, pos.name)) {
        if (element[pos.name] != null) {
          t[pos.name] +=
            parseFloat(element[pos.name]) * parseFloat(element.price)
        }
      }
    })
  })
  TRADE_COLUMNS.forEach(col => {
    t[col.colId] = t[col.colId] || 0
    exposures.forEach(exposure => {
      if (exposure.price === 'np') {
        return
      }
      t[col.colId] += exposure[col.colId] || 0
    })
  })
  t.DeltaUSD = t.Delta
  draft.summary = t
  updateHiddenByNotional(draft)
}
function updateHiddenByNotional(draft: LiveRiskModel) {
  const summary = draft.summary
  Object.keys(summary).forEach(key => {
    if (isNaN(summary[key])) {
      return
    }
    const isVisible =
      !draft.hideLTNotionalValueBalances ||
      Math.abs(summary[key]) >= draft.notionalValue
    if (draft.hiddenByNotionalValue[key] !== !isVisible) {
      draft.hiddenByNotionalValue[key] = !isVisible
    }
  })
  calculateHiddenColumns(draft)
}

function calculateHiddenColumns(draft: LiveRiskModel) {
  const exchanges = draft.exchangeColumns
  const summary = draft.summary
  draft.exposures.forEach((exposure: ExposureItem) => {
    exposure[HIDDEN] = exchanges.reduce((sum, c) => {
      const field = c.field
      if (isHideableColumn(c) && isColumnHidden(draft, c) && exposure[field]) {
        return sum + parseFloat(exposure[field])
      }
      return sum
    }, 0)
  })
  summary[HIDDEN] = 0
  exchanges.forEach(c => {
    const field = c.field
    if (isHideableColumn(c) && isColumnHidden(draft, c) && summary[field]) {
      summary[HIDDEN] += parseFloat(summary[field])
    }
  })
}

function setExchangeColumns(draft: LiveRiskModel) {
  const hasDeltaUSD = draft.exchanges.find(c => c.name === 'Recon Delta (USD)')
  if (!hasDeltaUSD) {
    draft.exchanges.push({
      name: 'Recon Delta (USD)',
      id: 0,
      type: 'risk',
      riskRating: 0,
      sortOrder: 1008,
    })
  }
  const columns: ColDef[] = []
  draft.exchanges.forEach(element => {
    const pinned =
      element.type === ASSET_STORE_TYPE_RISK ||
      element.type === ASSET_STORE_TYPE_SETTLEMENT_WALLET ||
      element.type === ASSET_STORE_TYPE_FIREBLOCKS_SETTLEMENT_WALLET
        ? 'right'
        : undefined
    columns.push({
      refData: {
        // @ts-expect-error Type mismatch
        element: element,
      },
      headerName: element.name === 'Delta' ? 'Recon Delta' : element.name,
      headerClass: pinned ? null : GridHelper().getStatusHeaderClass(),
      field: element.name === 'Recon Delta' ? 'Delta' : element.name,
      valueGetter:
        element.name === 'Recon Delta (USD)'
          ? ({ data }) => {
              if (!data || !data.price || data.price === 'np') {
                return null
              }
              if (data.currency === 'Total (USD)') {
                return data.Delta
              }
              if (!isNaN(data.price)) {
                return data.Delta * parseFloat(data.price)
              }
            }
          : null,
      filter: 'agNumberColumnFilter',
      cellStyle: { textAlign: 'right' },
      pinned: pinned,
      valueFormatter: sharedValueFormatter.bind(null, element),
    })
  })
  columns.push({
    headerName: HIDDEN,
    field: HIDDEN,
    filter: 'agNumberColumnFilter',
    headerClass: 'live-risk-hidden-exchanges-col-header',
    cellClass: ['live-risk-hidden-exchanges-col'],
    cellStyle: { textAlign: 'right' },
    // @ts-expect-error Type mismatch
    valueFormatter: ({ data, colDef }) => {
      let value = 0
      if (data && data[colDef.field]) {
        value = parseFloat(data[HIDDEN])
      }
      return isNaN(value) || value === 0
        ? 0
        : util.formatNumber(value, getState().LiveRisk.decimalPlaces)
    },
  })
  draft.exchangeColumns = columns
  addTradeDataToExposures(draft)
}

function isColumnHidden(draft: LiveRiskModel, c: ColDef) {
  if (isHideableColumn(c)) {
    const field = c.field
    return (
      (draft.hideLTNotionalValueBalances &&
        draft.hiddenByNotionalValue[field]) ||
      draft.hiddenColumns[field]
    )
  }
  return false
}

export function isHideableColumn(c: ColDef) {
  const type = getColumnAssetType(c)
  if (
    type == 'risk' ||
    type == 'settled' ||
    type == 'settled-fireblocks' ||
    !type
  ) {
    return false
  }
  return true
}

export function getColumnAssetType(c: ColDef): string | null {
  // @ts-expect-error element property is not a string but an object
  if (!c.refData || !c.refData.element || c.refData.element.type) {
    return null
  }
  // @ts-expect-error element property is not a string but an object
  return c.refData.element.type
}

function convertExposureToNonScientific(exposure: ExposureItem): void {
  Object.keys(exposure).forEach(k => {
    if (
      exposure[k] &&
      exposure[k] !== true &&
      !isNaN(exposure[k]) &&
      exposure[k].toString().includes('e')
    ) {
      exposure[k] = scientificToDecimal(exposure[k].toString())
    }
  })
}

const sharedValueFormatterInner = memoizee((element, data, colDef, value) => {
  if (colDef.valueGetter && value) {
    return util.toCurrency(value)
  }
  value = 0
  if (data && data[colDef.field]) {
    value = parseFloat(data[colDef.field])
  }
  if (element.name === 'GOTC Unsettled') {
    return isNaN(value) || value === 0 ? 0 : formatNumber(4, value)
  }

  return isNaN(value) || value === 0
    ? 0
    : util.formatNumber(
        parseFloat(data[colDef.field]),
        getState().LiveRisk.decimalPlaces,
      )
})

function sharedValueFormatter(element, { data, colDef, value }) {
  return sharedValueFormatterInner(element, data, colDef, value)
}

const vf = sharedValueFormatter.bind(null, {})
export const TRADE_COLUMNS: ColDef[] = [
  {
    pinned: 'right',
    headerName: 'CP Fills',
    field: 'cpFilled',
    filter: 'agNumberColumnFilter',
    valueFormatter: vf,
    cellRenderer: TradeCell,
  },
  {
    pinned: 'right',
    headerName: 'Exchange Fills',
    field: 'exchangeFilled',
    filter: 'agNumberColumnFilter',
    valueFormatter: vf,
    cellRenderer: TradeCell,
  },
  {
    pinned: 'right',
    headerName: 'Trade Delta',
    field: 'tradeDelta',
    filter: 'agNumberColumnFilter',
    valueFormatter: vf,
    cellRenderer: TradeCell,
  },
  {
    pinned: 'right',
    headerName: 'Open Orders',
    field: 'exchangeOpen',
    filter: 'agNumberColumnFilter',
    valueFormatter: vf,
    cellRenderer: TradeCell,
  },
]

function addPriceMoveDataToExposures(draft: LiveRiskModel) {
  const eodPrices = draft.eodPrices
  draft.exposures = draft.exposures.map(e => {
    const eodPriceInfo = eodPrices[e.currency]
    const price = e.price as unknown as number
    if (!eodPriceInfo || isNaN(eodPriceInfo.price) || isNaN(price)) {
      return e
    }
    const move =
      Math.round(((price - eodPriceInfo.price) / eodPriceInfo.price) * 100) /
      100
    return {
      ...e,
      move,
    }
  })
}

function addTradeDataToExposures(draft: LiveRiskModel) {
  const tradeDataBySecurity = {}
  Object.values(draft.tradeData).forEach(d => {
    tradeDataBySecurity[d.security] = d
  })
  draft.exposures = draft.exposures.map(e => {
    const td = tradeDataBySecurity[e.currency] || {}
    return {
      ...e,
      cpFilled: td.cpFilled || 0,
      exchangeFilled: td.exchangeFilled || 0,
      exchangeOpen: td.exchangeOpen || 0,
      tradeDelta: td.tradeDelta || 0,
    }
  })

  if (draft.exposures.length) {
    if (
      !draft.exchangeColumns.find(
        col => col.headerName === TRADE_COLUMNS[0].headerName,
      )
    ) {
      draft.exchangeColumns.push(...TRADE_COLUMNS)
    }
  }
}
const TRADE_VIEW_COLUMNS = TRADE_COLUMNS.map(trade => trade.headerName)
export function isTradesViewColumn(column) {
  return TRADE_VIEW_COLUMNS.includes(column.colDef.headerName)
}

export const RECON_VIEW_COLUMNS = [
  'Borrow',
  'Collateral',
  'Other',
  'CP Unsettled',
  'Internal Unsettled',
  'Position(USD)',
  'Position(Qty)',
  'Recon Delta',
  'Recon Delta (USD)',
]
export function isReconColumn(column) {
  return RECON_VIEW_COLUMNS.includes(column.colDef.headerName)
}

export const getExposureID = exposure =>
  `exposure:${exposure.strategyId}-${exposure.currency}`
