import { produce } from 'immer'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/transfers'
import { TransfersModel } from 'redux/models'
import { REDUX_INITIAL_SET } from 'redux/store/localStorage'
import { registerSaver } from 'redux/store/utils'

export enum Filter {
  DEFAULT = 1,
  ALL = 2,
  UNKNOWN = 3,
}
function createFilter(value, text: string) {
  return { key: value, value, text: text }
}
export const FILTER_OPTIONS = [
  createFilter(Filter.DEFAULT, 'Settlement'),
  createFilter(Filter.ALL, 'All'),
  createFilter(Filter.UNKNOWN, 'Unknown'),
]

const initialState: TransfersModel = {
  filter: Filter.ALL,
  version: 2,
}

registerSaver(state => {
  return { Transfers: { filter: state.Transfers.filter } }
})

export const TransfersReducer = handleActions<TransfersModel, any>(
  {
    [REDUX_INITIAL_SET]: (state, { payload }) => {
      return produce(state, draft => {
        const persistedState = payload.Transfers || {}
        if (persistedState.version && persistedState.version < 2) {
          persistedState.version = 2
          persistedState.filter = Filter.ALL
        }
        Object.assign(draft, persistedState)
      })
    },
    [Type.SET_FILTER]: (state: TransfersModel, { payload }) => {
      return produce(state, draft => {
        draft.filter = payload.value
      })
    },
  },
  initialState,
)
