import { batchedFunction } from 'utils/batchedFunction'
import emptyFunction from 'utils/emptyFunction'
import { isBrowser } from 'utils/isBrowser'
import {
  queryThenMutateDOM,
  queryThenMutateDOMXFramesFromNow,
} from './queryThenMutateDOM'

let width = 0
let height = 0

export const getWidth = () => {
  return width
}

export const getHeight = () => {
  return height
}

if (isBrowser) {
  const update = batchedFunction(emptyFunction, release => {
    queryThenMutateDOM(() => {
      width = window.innerWidth
      height = window.innerHeight
      release()
    }, null)
  })

  window.addEventListener('resize', update)
  queryThenMutateDOMXFramesFromNow(update, null, 2)
}
