import { dispatch } from '../../utils/dispatch'
import { DepositAddressFormValues } from '../models'

export enum Type {
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  TOGGLE_FORM_VALUE = 'TOGGLE_FORM_VALUE',
  RESET_ADDRESS_VALUES = 'RESET_ADDRESS_VALUES',
  RESET_FORM_VALUES = 'RESET_FORM_VALUES',
}

export const toggleAcceptedTerms = () => {
  dispatch({
    type: Type.TOGGLE_FORM_VALUE,
    payload: {
      fieldName: 'hasAcceptedTerms',
    },
  })
}

export const toggleAcceptedDirectOwnershipClaims = () => {
  dispatch({
    type: Type.TOGGLE_FORM_VALUE,
    payload: {
      fieldName: 'hasAcceptedDirectOwnershipClaims',
    },
  })
}

export const toggleSubAddressRequiredByUser = () => {
  dispatch({
    type: Type.TOGGLE_FORM_VALUE,
    payload: {
      fieldName: 'subAddressRequiredByUser',
    },
  })
}

export const setFormValue = (
  fieldName: keyof DepositAddressFormValues,
  value: any,
): void => {
  dispatch({
    type: Type.SET_FORM_VALUE,
    payload: { fieldName, value },
  })
}

export const resetAddressInputs = () => {
  dispatch({
    type: Type.RESET_ADDRESS_VALUES,
  })
}
