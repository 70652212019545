import { MouseEventHandler, RefObject, useCallback, useRef } from 'react'
import { useState } from 'hooks/useState'
import CSSCore from 'utils/CSSCore'
import { emptyWindowSelection } from 'utils/emptyWindowSelection'
import { queryThenMutateDOM } from 'utils/queryThenMutateDOM'
import type { IDraggableController } from './useDraggableController'

export const useDraggableItem = (
  controller: IDraggableController,
  id: string,
  nodeRef: RefObject<HTMLDivElement>,
) => {
  const controllerRef = useRef(controller)
  const idRef = useRef(id)

  const fakeDragging = useRef(false)
  const [dragging, setDragging] = useState(false)
  controllerRef.current.setNode(id, nodeRef)

  const cloneWrapperRef = useRef<HTMLDivElement>(null)
  const cloneRef = useRef<HTMLElement>(null)
  const startXRef = useRef(0)
  const lastXref = controller.lastXref
  const lastYref = controller.lastYref
  const scheduledUpdate = useRef(false)
  const endDragListenerRef = useRef<MouseEventHandler>()
  const onMouseDown = useCallback(
    e => {
      if (!nodeRef.current) {
        return
      }
      if (e.target.tagName === 'INPUT') {
        return
      }
      if (!nodeRef.current.contains(e.target)) {
        return
      }
      fakeDragging.current = true
      controllerRef.current.dragStart(idRef.current)
      startXRef.current = e.clientX
      if (scheduledUpdate.current) {
        return
      }
      scheduledUpdate.current = true
      queryThenMutateDOM(
        () => {
          scheduledUpdate.current = false
          if (!fakeDragging.current) {
            return
          }
          const node = nodeRef.current
          const cloneOuterWrapper = document.createElement('div')
          CSSCore.addClass(cloneOuterWrapper, 'drag-clone-outer-wrapper')
          cloneWrapperRef.current = cloneOuterWrapper
          if (controllerRef.current.makeClone) {
            cloneRef.current = controllerRef.current.makeClone({
              node,
              id: idRef.current,
            })
          } else {
            cloneRef.current = node.cloneNode(true) as HTMLElement
          }
          const height = node.offsetHeight
          const width = node.offsetWidth
          return { height, width, cloneOuterWrapper }
        },
        args => {
          if (!fakeDragging.current) {
            return
          }
          const { width, height, cloneOuterWrapper } = args
          const clone = cloneRef.current
          CSSCore.addClass(clone, 'drag-clone')
          if (!clone.style.height) {
            clone.style.height = height + 'px'
          }
          if (!clone.style.width) {
            clone.style.width = width + 'px'
          }
          const indicator = controllerRef.current.indicator
          indicator.style.height = height + 'px'
          cloneOuterWrapper.style.transform =
            'translateX(' + startXRef.current + 'px)'
          cloneOuterWrapper.appendChild(clone)
          document.body.appendChild(cloneOuterWrapper)
          document.body.appendChild(controllerRef.current.indicator)
        },
      )
      const endDragListener = () => {
        if (!fakeDragging.current && !cloneRef.current) {
          return
        }
        setDragging(false)
        fakeDragging.current = false
        controllerRef.current.dragEnd()
        if (cloneRef.current) {
          cloneRef.current.remove()
          cloneRef.current = null
          cloneWrapperRef.current.remove()
          cloneWrapperRef.current = null
        }
        queryThenMutateDOM(null, () => {
          controllerRef.current.indicator.style.height = 0 + 'px'
        })
        window.removeEventListener('mouseup', endDragListener)
        window.removeEventListener('mousemove', mousemoveListener)
        window.removeEventListener('contextmenu', endDragListener)
      }
      const mousemoveListener = (e: MouseEvent) => {
        lastXref.current = e.clientX + window.scrollX
        lastYref.current = e.clientY + window.scrollY
        if (scheduledUpdate.current) {
          return
        }
        scheduledUpdate.current = true
        queryThenMutateDOM(null, () => {
          scheduledUpdate.current = false
          const clone = cloneWrapperRef.current
          const node = nodeRef.current
          if (clone && node) {
            const closest = controllerRef.current.findClosestRect()
            controllerRef.current.setIndicator(closest.closestPos)
            if (node.contains(e.target as HTMLElement)) {
              CSSCore.removeClass(clone, 'visible')
              setDragging(false)
            } else {
              emptyWindowSelection()
              CSSCore.addClass(clone, 'visible')
              setDragging(true)
            }
            clone.style.transform = `translate(${lastXref.current}px, ${closest.closestPos.y}px) scale(0.8)`
          }
        })
      }
      window.addEventListener('mouseup', endDragListener)
      window.addEventListener('mousemove', mousemoveListener)
      window.addEventListener('contextmenu', endDragListener)
      endDragListenerRef.current = endDragListener
    },
    [lastXref, lastYref, nodeRef],
  )

  return {
    dragging,
    onMouseDown,
    onMouseUp: useCallback(ev => {
      endDragListenerRef.current && endDragListenerRef.current(ev)
    }, []),
  }
}
