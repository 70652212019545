import { FC, memo, useMemo } from 'react'
import { Menu } from 'semantic-ui-react'
import Link from 'components/Link'
import { Strategy } from 'redux/models'
import { ApplicationWithUrl } from 'redux/models/Application'
import getApplicationsUrl from 'utils/getApplicationsUrl'

type Props = {
  strategy: Strategy
  activePage: string
}

const isActive = (app: ApplicationWithUrl, activePage: string) => {
  if (!app.external) {
    return false
  }
  const isExact = activePage === app.url
  const isSettlementss =
    activePage.includes('settlements') && app.url.includes('settlements')
  return isExact || isSettlementss
}

const Applications: FC<Props> = ({ strategy, activePage }) => {
  const apps = useMemo(() => getApplicationsUrl(strategy), [strategy])
  const activeAppIndex = apps.findIndex(app => isActive(app, activePage))
  return apps.map((app, index) => (
    <Menu.Item
      key={`nav-${index}:${app.id}`}
      as={app.external ? undefined : Link}
      href={app.url}
      active={index === activeAppIndex}
    >
      {app.name}
    </Menu.Item>
  ))
}

const ApplicationsMemo = memo(Applications)
export { ApplicationsMemo as Applications }
