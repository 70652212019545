import { UseQueryResult, useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { UserConsentInfo } from 'models/LegalTerms'
import { selectDefaultEntity } from 'redux/selectors/selectGalaxyUser'
import { selectOktaToken } from 'redux/selectors/selectOktaToken'
import errorToast from 'utils/errorToast'
import {
  UserConsentQueryKey,
  defaultRetries,
  defaultStaleTime,
  genericErrorMessage,
} from './const'

const fetchUserConsent = async (accountId: number, oktaToken: string) => {
  const res = await fetch(`/api/legalterms/user-consent/${accountId}`, {
    headers: {
      Authorization: `Bearer ${oktaToken}`,
    },
  })

  if (res.ok) {
    return res.json()
  } else {
    const { error } = await res.json()
    throw new Error(error)
  }
}

type UseGetUserConsent = (params: {
  enabled?: boolean
}) => UseQueryResult<UserConsentInfo>

export const useGetUserConsent: UseGetUserConsent = ({ enabled } = {}) => {
  const oktaToken = useSelector(selectOktaToken)
  const defaultEntity = useSelector(selectDefaultEntity)
  const accountId = defaultEntity?.accountInfo[0].accountId

  return useQuery<UserConsentInfo>(
    [UserConsentQueryKey],
    async () => fetchUserConsent(accountId, oktaToken),
    {
      onError: () => errorToast(genericErrorMessage),
      retry: defaultRetries,
      staleTime: defaultStaleTime,
      enabled,
    },
  )
}
