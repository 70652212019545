import React, { ReactNode, useCallback, useRef } from 'react'
import cx from 'classnames'
import { KEY_ESCAPE } from 'keycode-js'
import { useListener } from 'hooks/useListener'
import { useUpdatingRef } from 'hooks/useUpdatingRef'

type DialogProps = {
  children?: ReactNode
  close: VoidFunction
  context?: HTMLElement
  className?: string
}

export const Dialog = React.memo(
  ({ children, close, context, className }: DialogProps) => {
    const closeRef = useUpdatingRef(close)
    const dialogRef = useRef<HTMLDivElement>(null)
    const contextRef = useUpdatingRef(context)
    useListener(
      document.body,
      'mousedown',
      useCallback(
        event => {
          const root = dialogRef.current
          const element = event.target as HTMLElement
          if (
            root?.contains(element) ||
            contextRef.current?.contains(element)
          ) {
            return
          }
          closeRef.current()
        },
        [closeRef, contextRef],
      ),
    )

    useListener(
      window,
      'keydown',
      useCallback(
        event => {
          if (event.keyCode === KEY_ESCAPE) {
            closeRef.current()
          }
        },
        [closeRef],
      ),
    )
    return (
      <div className={cx('tooltip-dialog', className)} ref={dialogRef}>
        {children}
      </div>
    )
  },
)

Dialog.displayName = 'Dialog'
