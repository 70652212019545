type NavigationItem = {
  text: string
  url: string
  isActive: boolean
}

export function getNavigationItems(active: string): NavigationItem[] {
  const isActive = (...paths: string[]) => paths.indexOf(active) > -1

  const navigationItems: NavigationItem[] = [
    {
      text: 'Trade Report',
      url: '/trades/daily',
      isActive: isActive('/trades/daily'),
    },
    {
      text: 'Settlements',
      url: '/settlements',
      isActive: isActive('/settlements'),
    },
    {
      text: 'Address Book',
      url: '/deposit_address',
      isActive: isActive('/deposit_address'),
    },
    {
      text: 'Support',
      url: '/support',
      isActive: isActive('/support'),
    },
    {
      text: 'Terms of Service',
      url: '/terms',
      isActive: isActive('/terms'),
    },
  ].filter(Boolean)

  return navigationItems
}
