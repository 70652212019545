import { getState } from './getState'
import { isBrowser } from './isBrowser'

type TokenCallback = (token: string) => void

let resolveToken: TokenCallback
const promise = new Promise<string>(res => {
  resolveToken = res
})

let token: string
function checkForToken(): void {
  if (!isBrowser) {
    return
  }

  setTimeout(() => {
    const data = getState()?.OktaUser?.data
    if (!data?.accessToken) {
      return
    }
    token = data.accessToken
    resolveToken(token)
    clearInterval(tokenInterval)
  })
}

const tokenInterval = setInterval(() => {
  checkForToken()
  if (!global?.document) {
    // disable inside webworker
    clearInterval(tokenInterval)
  }
}, 200)

export async function getOktaToken(): Promise<string> {
  return await promise
}

export async function withOktaToken(cb: TokenCallback): Promise<void> {
  const token = await getOktaToken()
  cb(token)
}

export function _getOktenToken(): string {
  return token
}
