import { useRef, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Button, Modal, Typography } from '@g1/components'
import { noop } from 'lodash'
import { get } from 'Gatekeeper'
import { singleLogout } from 'utils/auth'

import Styles from './IdeSignOut.module.scss'

const SECOND = 1000
const MINUTE = 60 * SECOND
const SIGNOUT_TIMEOUT = 60 * MINUTE
const PROMPT_TIMEOUT = 50 * MINUTE

export const IdleSignOut = () => {
  const enabledQAMode = get('g1_qa_auto_signout_timeout')
  const signoutTimeout = enabledQAMode ? SIGNOUT_TIMEOUT / 60 : SIGNOUT_TIMEOUT
  const promptTimeout =
    signoutTimeout - (enabledQAMode ? PROMPT_TIMEOUT / 60 : PROMPT_TIMEOUT)

  const [open, setOpen] = useState(false)
  const [remainingTime, setRemainingTime] = useState<number>(promptTimeout)
  const intervalRef = useRef<NodeJS.Timeout>()

  const stopInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  const onShowModal = () => {
    stopInterval()

    intervalRef.current = setInterval(
      () =>
        setRemainingTime(remainingTime =>
          remainingTime - SECOND >= 0 ? remainingTime - SECOND : 0,
        ),
      SECOND,
    )

    setOpen(true)
  }

  const onSignOut = () => {
    stopInterval()
    singleLogout()
  }

  const onReset = () => {
    setOpen(false)
    idleTimer.reset()
    stopInterval()
    setRemainingTime(promptTimeout)
  }

  const idleTimer = useIdleTimer({
    timeout: signoutTimeout,
    promptBeforeIdle: promptTimeout,
    onPrompt: onShowModal,
    onIdle: onSignOut,
  })

  const remainingMinutes = Math.floor(remainingTime / SECOND / 60)
  const remainingSeconds = Math.floor((remainingTime / SECOND) % 60)

  return (
    <Modal open={open} onClose={noop} width={460}>
      <Modal.Header>
        <span className={Styles.modalHeader}>
          Your session will expire in {remainingMinutes}:
          {remainingSeconds.toString().padStart(2, '0')} due to inactivity
        </span>
      </Modal.Header>
      <Modal.Content>
        <div className={Styles.modalContent}>
          <Typography variant="body2">
            For your security, you are automatically signed out after 60 minutes
            of inactivity.
          </Typography>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className={Styles.modalFooter}>
          <Button variant="secondary" onClick={singleLogout}>
            Sign Out
          </Button>
          <Button variant="primary" onClick={onReset}>
            Stay Signed In
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
