import React, { useCallback, useMemo } from 'react'
import { produce } from 'immer'
import { Button, Dropdown, Input } from 'semantic-ui-react'
import type { FilterComponent } from 'components/GridTable/useGridTable'
import { useIsFirstRender } from 'hooks/useIsFirstRender'
import { usePrevious } from 'hooks/usePrevious'
import { useStateRef } from 'hooks/useStateRef'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { emptyArray } from 'utils/emptyArray'
import { uniqueID } from 'utils/uniqueID'
import { FILTER_OP } from '../constants'
import { GridTableColumnFilterBase } from './GridTableColumnFilterBase'

const Component = React.memo(({ setFilter, filterArgs }: any) => {
  const setFilterRef = useUpdatingRef(setFilter)
  const [conditionsRef, setConditions] = useStateRef([FILTER_TYPES[0]])

  const isFirstRender = useIsFirstRender()
  const prevFilterArgs = usePrevious(filterArgs).current
  if (filterArgs !== prevFilterArgs) {
    if ((!prevFilterArgs || !prevFilterArgs.length) && isFirstRender) {
      conditionsRef.current = [FILTER_TYPES[0]]
    } else {
      conditionsRef.current = filterArgs
    }
  }

  const addCondition = useCallback(() => {
    setConditions([...(conditionsRef.current || emptyArray), FILTER_TYPES[0]])
  }, [])
  const clear = useCallback(() => {
    if (!conditionsRef.current || !conditionsRef.current.length) {
      return
    }
    if (
      conditionsRef.current.length !== 1 ||
      conditionsRef.current[0] !== FILTER_TYPES[0]
    ) {
      setConditions(null)
      submit()
    }
  }, [])
  const getSetFilterVal = useCallback(
    index => filter => {
      setConditions(
        produce(conditionsRef.current, draft => {
          draft.splice(index, 1, filter)
        }),
      )
    },
    [],
  )

  const conditions = conditionsRef.current
    ? conditionsRef.current.map((cond, i) => {
        return <Condition {...cond} setFilterVal={getSetFilterVal(i)} key={i} />
      })
    : []

  const submit = useCallback(() => {
    setFilterRef.current(
      conditionsRef.current && conditionsRef.current.length
        ? conditionsRef.current
        : null,
    )
  }, [])

  const menu = useMemo(
    () => (
      <div>
        <h3>String Filter</h3>
        <div
          className={
            conditions.length > 1
              ? 'grid-table-column-filter-conditions-wrapper'
              : ''
          }
        >
          {conditions.length > 1 ? (
            <div className="grid-table-column-filter-conditions-or">
              <div className="line" />
              <div className="or">OR</div>
              <div className="line" />
            </div>
          ) : null}
          {conditions.length === 0 ? (
            <div>Add Conditions to Filter</div>
          ) : (
            <div>{conditions}</div>
          )}
        </div>
        <hr />
        <div className="grid-table-filter-actions">
          <Button size="tiny" onClick={clear} negative content="Clear" />
          <Button
            size="tiny"
            onClick={addCondition}
            primary
            content="Add Condition"
          />
          <Button size="tiny" onClick={submit} positive content="Submit" />
        </div>
      </div>
    ),
    [conditions, submit, clear, addCondition],
  )
  return <GridTableColumnFilterBase menu={menu} />
})

const Condition = React.memo((props: any) => {
  const { value, filter, setFilterVal } = props
  const propsRef = useUpdatingRef(props)
  const [valRef, _setVal] = useStateRef(filter)
  const onFilterTypeChange = useCallback((_, { value }) => {
    setFilterVal({
      filter: valRef.current,
      value,
    })
  }, [])
  const setVal = useCallback((_, { value }) => {
    _setVal(value)
    setFilterVal({
      filter: value,
      value: propsRef.current.value,
    })
  }, [])
  return (
    <div>
      <Dropdown
        options={FILTER_TYPES}
        value={value}
        onChange={onFilterTypeChange}
      />
      <Input value={filter} onChange={setVal} />
    </div>
  )
})

const FILTER_TYPES = [
  {
    text: 'Contains',
    value: FILTER_OP.contains,
    key: uniqueID(),
    filter: '',
  },
  {
    text: 'Not Contains',
    value: FILTER_OP.notContain,
    key: uniqueID(),
    filter: '',
  },
  {
    text: 'Equals',
    value: FILTER_OP.equals,
    key: uniqueID(),
    filter: '',
  },
  {
    text: 'Not Equal',
    value: FILTER_OP.notEqual,
    key: uniqueID(),
    filter: '',
  },
  {
    text: 'Starts With',
    value: FILTER_OP.startsWith,
    key: uniqueID(),
    filter: '',
  },
  {
    text: 'Ends With',
    value: FILTER_OP.endsWith,
    key: uniqueID(),
    filter: '',
  },
]

function s(value) {
  return value == null ? '' : value.toString().toLowerCase()
}

function makeFilter(condition): Function {
  switch (condition.value) {
    case FILTER_OP.contains:
      return value => s(value).includes(s(condition.filter))
    case FILTER_OP.notContain:
      return value => !s(value).includes(s(condition.filter))
    case FILTER_OP.equals:
      return value => s(value) === s(condition.filter)
    case FILTER_OP.notEqual:
      return value => s(value) !== s(condition.filter)
    case FILTER_OP.startsWith:
      return value => s(value).startsWith(s(condition.filter))
    case FILTER_OP.endsWith:
      return value => s(value).endsWith(s(condition.filter))
    default:
      return () => false
  }
}

const map = new WeakMap()
function toFilterFn(condition) {
  if (!map.has(condition)) {
    map.set(condition, makeFilter(condition))
  }
  return map.get(condition)
}

function filterFn(filterArgs, value) {
  if (!filterArgs.length) {
    return false
  }
  const fns = filterArgs.map(toFilterFn)
  let anyPassed = false
  for (const filter of fns) {
    if (filter(value)) {
      anyPassed = true
      break
    }
  }
  return !anyPassed
}

export const GridTableStringColumnFilter: FilterComponent = {
  Klass: Component,
  filterFn,
  toServerModel: filterArgs => {
    return {
      conditions: filterArgs
        ? filterArgs.map(({ filter, value }) => {
            return {
              val: filter,
              op: value,
            }
          })
        : [],
      op: 'or',
    }
  },
}
