import { toast } from 'react-toastify'
import { Type } from 'redux/actions/admin'
import { getKeyedDispatchQueue } from 'utils/dispatch'
import emptyFunction from 'utils/emptyFunction'
import { create, fetchableOnceWithParams, get, post, update } from 'utils/http'

const referenceServiceBaseUrl =
  process.env.NEXT_PUBLIC_REFERENCE_SERVICE_BASE_URL ?? ''

const addressBaseUrl = process.env.NEXT_PUBLIC_SERVICE_ADDRESS_URL ?? ''

const batchedDispatch = getKeyedDispatchQueue()
export const getEntityDetailsByAccountId = fetchableOnceWithParams(
  ({ accountId }, onSuccess = emptyFunction, onError = emptyFunction) =>
    get(
      referenceServiceBaseUrl,
      `api/entities/account/${accountId}/entity`,
      onSuccess,
      onError,
    ),
)

export function getCounterparties(
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return get(
    referenceServiceBaseUrl,
    'api/counterparties',
    onSuccess,
    onError,
  ).retry()
}

export function getAccountInfo(
  accountId,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return get(
    referenceServiceBaseUrl,
    `api/accounts/${accountId}`,
    data => {
      batchedDispatch(accountId, {
        type: Type.SET_ACCOUNT_INFO,
        payload: { data, id: accountId },
      })
      onSuccess(data)
    },
    onError,
  ).retry()
}

type getCounterpartiesResponse = {
  count: number
  counterparties: {
    id: string
    name: string
    addressLineOne: string | null
    addressCity: string | null
    addressState: string | null
    addressCountry: string | null
    accounts: number[]
  }[]
}

export function fetchCounterpartiesAndAccounts(cb = emptyFunction) {
  return getCounterparties(
    (counterPartiesResp: getCounterpartiesResponse) => {
      batchedDispatch('counterparties', {
        type: Type.UPDATE_COUNTERPARTIES,
        payload: counterPartiesResp?.counterparties ?? [],
      })

      fetchAccounts(cb, () => {
        toast.error('Failed to fetch accounts.')
        cb()
      })
    },
    () => {
      toast.error('Failed to fetch counterparties.')
      cb()
    },
  )
}

export function updateContactsList(
  newContact,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  create(
    referenceServiceBaseUrl,
    `api/entities/contacts`,
    newContact,
    onSuccess,
    res => {
      if (res.data && res.data.errorMessage) {
        toast.error(res.data.errorMessage)
      } else {
        toast.error('Server Error')
      }
      onError()
    },
  )
}

type GetAccountsResponse = {
  id: number
  api: string | null
  apiAccount: string | null
  sourceSystem: string | null
  assetStore: number
  assetStoreName: string
  accountNumber: number | null
  accountName: string
  entity: number
  gotc: boolean
  g1Enabled: boolean
  commission_rate: number
  liquidityTierId: number
  liquidityTierName: string
  limitAmount: string | null
  useAveragePriceMatching: boolean
  accountAccountAttributes: any[]
}

function fetchAccounts(
  onSuccess: (res: GetAccountsResponse[]) => void = emptyFunction,
  onError = emptyFunction,
) {
  return get(
    referenceServiceBaseUrl,
    `api/accounts`,
    (accountsResponse: GetAccountsResponse[]) => {
      batchedDispatch('accounts', {
        type: Type.UPDATE_ACCOUNTS,
        payload: accountsResponse,
      })
      onSuccess(accountsResponse)
    },
    onError,
  )
}

export function updateContact(
  contactId,
  contact,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  update(
    referenceServiceBaseUrl,
    `api/entities/contacts/${contactId}`,
    contact,
    onSuccess,
    onError,
  )
}

export function getContactsByEntity(
  entityId,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return get(
    referenceServiceBaseUrl,
    `api/entities/${entityId}/contacts`,
    data => {
      batchedDispatch(entityId, {
        type: Type.SET_COUNTERPARTY_CONTACTS,
        payload: {
          data,
          id: entityId,
        },
      })
      onSuccess()
    },
    onError,
  ).retry()
}

type CheckPeerReviewEnabledResponse = {
  code: number
  message: string
  peerReviewEnabled: boolean
}

export function checkPeerReviewEnabled(
  entityId: number,
  onSuccess: (result: CheckPeerReviewEnabledResponse) => void = emptyFunction,
  onError = emptyFunction,
) {
  return new Promise<CheckPeerReviewEnabledResponse>((resolve, reject) => {
    const subscription = create(
      referenceServiceBaseUrl,
      'api/checkPeerReviewEnabledForAddresses',
      { entityId },
      (result: CheckPeerReviewEnabledResponse) => {
        onSuccess(result)
        resolve(result)
        subscription.unsubscribe()
      },
      error => {
        onError(error)
        reject(error)
        subscription.unsubscribe()
      },
    )
  })
}

export type ListSettlementAddressesByEntityIdResponse = {
  settlementAddressList: any[]
  hasNextPage: boolean
  hasPreviousPage: boolean
  count: number
  code: number
  message: string
}

export function listSettlementAddressesByEntityId(
  params: { entityId: number; skip: number; limit: number },
  onSuccess: (
    result: ListSettlementAddressesByEntityIdResponse,
  ) => void = emptyFunction,
  onError = emptyFunction,
) {
  return new Promise<ListSettlementAddressesByEntityIdResponse>(
    (resolve, reject) => {
      const subscription = create(
        addressBaseUrl,
        'api/v1/address/listSettlementAddressesByEntityId',
        params,
        (result: ListSettlementAddressesByEntityIdResponse) => {
          onSuccess(result)
          resolve(result)
          subscription.unsubscribe()
        },
        error => {
          onError(error)
          reject(error)
          subscription.unsubscribe()
        },
      )
    },
  )
}

export function validateSettlementAddressNickname(
  params: { nickname: string; accountId: number },
  onSuccess: (result: { message: string }) => void = emptyFunction,
  onError = emptyFunction,
) {
  return new Promise<{ message: string }>((resolve, reject) => {
    try {
      const subscription = create(
        addressBaseUrl,
        'validateSettlementAddressNickname',
        params,
        (result: { message: string }) => {
          onSuccess(result)
          resolve(result)
          subscription.unsubscribe()
        },
        error => {
          onError(error)
          reject(error)
          subscription.unsubscribe()
        },
      )
    } catch (error) {
      onError(error)
      reject(error)
    }
  })
}

export function validateGDAddressNickname(
  params: { nickname: string; accountId: number },
  onSuccess: (result: { message: string }) => void = emptyFunction,
  onError = emptyFunction,
) {
  return new Promise<{ message: string }>((resolve, reject) => {
    try {
      const subscription = create(
        addressBaseUrl,
        'api/v1/address/validateNicknameGdAddress',
        params,
        (result: { message: string }) => {
          onSuccess(result)
          resolve(result)
          subscription.unsubscribe()
        },
        error => {
          onError(error)
          reject(error)
          subscription.unsubscribe()
        },
      )
    } catch (error) {
      onError(error)
      reject(error)
    }
  })
}

export function validateCryptoGDAddress(
  params: {
    accountId: number
    address: string
    blockchainNetworkId: number
    securityId: number
    subAddress?: string
  },
  onSuccess: (result: {
    message: string
    code: number
  }) => void = emptyFunction,
  onError = emptyFunction,
) {
  return new Promise<{ message: string; code: number }>((resolve, reject) => {
    try {
      const subscription = create(
        addressBaseUrl,
        'api/v1/address/validateCryptoGdAddress',
        params,
        (result: { message: string; code: number }) => {
          onSuccess(result)
          resolve(result)
          subscription.unsubscribe()
        },
        error => {
          onError(error?.response?.data)
          reject(error?.response?.data)

          subscription.unsubscribe()
        },
        true,
        false,
        false,
      )
    } catch (error) {
      onError(error)
      reject(error)
    }
  })
}
export function editSettlementNote(
  data,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  post(
    referenceServiceBaseUrl,
    '/api/accounts/updateSettlementNote',
    data,
    onSuccess,
    onError,
  )
}

type UpdateAccountPayload = {
  liquidityTierId: number
  limitAmount: number
  isGotc: boolean
}

export const updateAccount = (
  id: number,
  data: Partial<UpdateAccountPayload>,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) => {
  const { limitAmount, liquidityTierId, isGotc } = data
  const payload = {
    id,
    liquidityTierId,
    limitAmount,
    isGotc,
  }

  update(
    referenceServiceBaseUrl,
    '/api/accounts',
    payload,
    updatedAccount => {
      batchedDispatch('accounts', {
        type: Type.UPDATE_ACCOUNT,
        payload: updatedAccount,
      })
      onSuccess()
    },
    onError,
  )
}
