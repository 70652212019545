/**
 * Represent a View Mode. For instance
 */
export enum ViewMode {
  /**
   * Current View in Read-only
   */
  View = 1,
  /**
   * Current View in Edit Mode
   */
  Edit = 2,
  /**
   * Current View in New Mode
   */
  Create = 3,
  /**
   * Current View in Clone mode.
   */
  Clone = 4,
}
