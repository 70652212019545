import React, { FC } from 'react'
import { GalaxyOneProvider } from '@g1/components'
import { useMaintenance } from '../hooks/useMaintenance'
import MaintenanceBanner from './components/MaintenanceBanner'

import Styles from './styles.module.scss'

const G1Banner: FC = () => {
  const { message, showMaintenanceBanner } = useMaintenance()

  if (!showMaintenanceBanner) {
    return null
  }

  return (
    <GalaxyOneProvider theme="dark">
      <div className={Styles.container}>
        <div className={Styles.banner}>
          <MaintenanceBanner message={message} />
        </div>
      </div>
    </GalaxyOneProvider>
  )
}

export default React.memo(G1Banner)
