// This module is a wrapper of our whatever our 3rd party library to handle big Int will be.
// In the case of replacing this dependency the only module that needs to be touched should be this one.
// Solid Principle Dependency Inversion
import bigDecimal from 'js-big-decimal'
import { isValidNumber, scientificToDecimal } from 'utils/formatNumber'

export function isNegative(value: string): boolean {
  const valueDecimal = scientificToDecimal(value)
  return bigDecimal.compareTo(valueDecimal, '0') === -1
}

export function getAbsoluteValue(value: string) {
  const valueDecimal = scientificToDecimal(value)
  const bigDecimalValue = new bigDecimal(valueDecimal)
  return isNegative(valueDecimal)
    ? bigDecimalValue.negate().getValue()
    : bigDecimalValue.getValue()
}

export function isZero(value: string): boolean {
  return (
    bigDecimal.compareTo(value, '0') === 0 ||
    bigDecimal.compareTo(value, '-0') === 0
  )
}

export function areEqual(value1: string, value2: string): boolean {
  const value1Decimal = scientificToDecimal(value1)
  const value2Decimal = scientificToDecimal(value2)

  // Handle '-0' !== '0'
  if (isZero(value1Decimal) && isZero(value2Decimal)) {
    return true
  }

  return bigDecimal.compareTo(value1Decimal, value2Decimal) === 0
}

export function getMin(value1: string, value2: string) {
  const value1Decimal = scientificToDecimal(value1)
  const value2Decimal = scientificToDecimal(value2)
  return bigDecimal.compareTo(value1Decimal, value2Decimal) === 1
    ? value2Decimal
    : value1Decimal
}

export function getMax(value1: string, value2: string) {
  const value1Decimal = scientificToDecimal(value1)
  const value2Decimal = scientificToDecimal(value2)
  return bigDecimal.compareTo(value1Decimal, value2Decimal) === 1
    ? value1Decimal
    : value2Decimal
}

export function getBigDecimalPrettyValue({
  value,
  digits,
  separator,
}: {
  value: string
  digits: number
  separator: string
}): string {
  if (!isValidNumber(value)) return ''
  const valueDecimal = scientificToDecimal(value)
  return bigDecimal.getPrettyValue(valueDecimal, digits, separator)
}

export function subtract(value1: string, value2: string): string {
  const value1Decimal = scientificToDecimal(value1)
  const value2Decimal = scientificToDecimal(value2)
  return bigDecimal.subtract(value1Decimal, value2Decimal)
}

export function negate(value: string): string {
  const valueDecimal = scientificToDecimal(value)
  return bigDecimal.negate(valueDecimal)
}

export function add(value1: string, value2: string): string {
  const value1Decimal = scientificToDecimal(value1)
  const value2Decimal = scientificToDecimal(value2)
  return bigDecimal.add(value1Decimal, value2Decimal)
}
