export enum Type {
  UPDATE_USERS = 'admin-update-users',
  UPDATE_COUNTERPARTIES = 'admin-update-counterparties',
  UPDATE_ACCOUNTS = 'update-accounts',
  UPDATE_ACCOUNT = 'update-account',
  UPDATE_COUNTERPARTY = 'admin-update-counterparty',
  SET_ACCOUNT_INFO = 'admin-set-account-info',
  SET_COUNTERPARTY_CONTACTS = 'admin-set-counterparty-contacts',
}

export default null
