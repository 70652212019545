import { FC } from 'react'
import { SystemError } from 'components/SystemError/SystemError'

export type ServiceErrorProps = {
  error?: Error
}

export const ServiceError: FC<ServiceErrorProps> = ({ error }) => (
  <SystemError
    error={error}
    title="Service Error"
    message={
      <>
        We&apos;re sorry, something went wrong on our end. Please try again
        later or contact Galaxy Support at{' '}
        <a href="mailto:galaxyone@galaxy.com">galaxyone@galaxy.com</a>
      </>
    }
  />
)
