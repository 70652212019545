const isEnv = (envName: string) => getEnv() === envName

export const getEnv = (): string => process.env.NEXT_PUBLIC_ENVIRONMENT
export const isDev = () =>
  isEnv('DEV') || isEnv('DEV_CLIENT') || isEnv('G1-DEV')
export const isQA = () => isEnv('QA') || isEnv('QA2') || isEnv('G1-QA')
export const isUAT = () => isEnv('UAT') || isEnv('G1-UAT')
export const isSandbox = () => isEnv('SANDBOX')
export const isProd = () =>
  isEnv('PROD') || isEnv('SANDBOX') || isEnv('G1-PROD')

export const isG1 = () =>
  isEnv('G1-DEV') || isEnv('G1-QA') || isEnv('G1-UAT') || isEnv('G1-PROD')

export const isLocal = () => window.location.hostname === 'localhost'
