import { derivativesSettlementUrl } from 'components/DerivativeSettlements/constants'
import { Type } from 'redux/actions/counterpartyEntitiesList'
import { GetCounterpartyEntitiesList } from 'redux/models'
import { dispatch } from 'utils/dispatch'
import { get } from 'utils/http'

export const getCounterpartyEntitiesList = () => {
  if (!derivativesSettlementUrl) {
    return
  }
  dispatch({
    type: Type.GET_COUNTERPARTYENTITIES_LIST,
  })

  get(
    derivativesSettlementUrl,
    {
      url: 'entities',
      body: { strategyId: 80 },
    },
    (counterparties: GetCounterpartyEntitiesList) => {
      dispatch({
        type: Type.GET_COUNTERPARTYENTITIES_LIST,
        payload: counterparties,
      })
    },
    error => {
      console.error('error', error)
    },
  )
}
