import React, { ReactNode, useCallback, useMemo } from 'react'
import cx from 'classnames'
import { ReactNodeArray } from 'prop-types'
import { useContextSelector } from 'use-context-selector'
import { useShallowCompareDep } from 'hooks/useShallowCompareDep'
import { Context } from './Context'
import { getCellClass, getCellStyle } from './util'

type Props = {
  children: ReactNodeArray | ReactNode
  col: any
  row: any
  value: any
  valueFormatted: any
} & React.HTMLAttributes<HTMLDivElement>

export const GridTableRowCell = React.memo((props: Props) => {
  const {
    row,
    col,
    children,
    className,
    style,
    value,
    valueFormatted,
    ...htmlProps
  } = props

  const realStyle = useMemo(() => {
    return {
      ...getCellStyle(row, col, value, valueFormatted),
      ...style,
    }
  }, [row, col, style])

  const isSelected = useContextSelector(
    Context,
    useCallback(
      context => {
        const { selectedCell: cell, getRowKey } = context
        return (
          cell &&
          cell.row &&
          row &&
          getRowKey(cell.row) === getRowKey(row) &&
          cell.col.id === col.id
        )
      },
      [row, col],
    ),
  )

  const realClassName = useMemo(() => {
    const cellClass = getCellClass(row, col, value)
    return cx('grid-table-cell', {
      [className]: !!className,
      [cellClass]: !!cellClass,
      selected: isSelected,
    })
  }, [row, col, value, className, isSelected])

  return useMemo(
    () => (
      <div className={realClassName} style={realStyle} {...htmlProps}>
        {children}
      </div>
    ),
    [
      children,
      realClassName,
      realStyle,
      row,
      col,
      useShallowCompareDep(htmlProps),
    ],
  )
})
