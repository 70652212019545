import React, { FC, ReactNode } from 'react'
import {
  ModalActionsProps,
  ModalContentProps,
  ModalHeaderProps,
  ModalProps,
  Modal as SemanticModal,
} from 'semantic-ui-react'

const { Header, Actions, Content } = SemanticModal

interface GalaxyModalProps extends ModalProps {
  headerProps?: ModalHeaderProps
  contentProps?: ModalContentProps
  actionsProps?: ModalActionsProps
  className?: string
}

const GalaxyModal: FC<GalaxyModalProps> = props => {
  const {
    headerProps,
    contentProps,
    actionsProps,
    children,
    actions,
    header,
    ...modalProps
  } = props

  const shouldRenderActions =
    actions && (Array.isArray(actions) ? actions.length > 0 : true)

  return (
    <SemanticModal {...modalProps}>
      <Header {...headerProps}>{header as ReactNode}</Header>
      <Content {...contentProps}>{children}</Content>
      {shouldRenderActions && (
        <Actions {...actionsProps}>{actions as ReactNode}</Actions>
      )}
    </SemanticModal>
  )
}

export default React.memo<GalaxyModalProps>(GalaxyModal)
